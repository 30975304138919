import Styles from './Show.scss';

import Template from './Show.hbs';
import SectionSlot from 'views/pages/chapters/show/sectionSlot/SectionSlot';
import HeroButton from 'views/components/heroButton/HeroButton';
import VisibilityModal from 'views/components/modals/setVisibility/SetVisibility';

export default BaseView.extend({

    // Constructor function
    initialize(options) {

        // Make this accessible
        _.bindAll(
            this,
            'onLoadSectionProgress',
            'getProgress'
        );

        // Set some default variables
        this.chapter_id = options.params[0];
        this.model = Backbone.Collection.chapters.get(this.chapter_id);

        Backbone.View.header.clearButtons()
        Backbone.View.header.setTitle(this.model.getName('show'))
        Backbone.View.header.setCrumblepath(
            this.model.getAllCrumblepathModels(),
            'show'
        )

        // Render the template
        this.setElement(Template({
            Styles,
            hasNoContent: this.model.sections.length === 0,
            hide_for_exercise: this.model.get('hide_for_exercise')
        }));

        this.getProgress()

        if (Backbone.Model.user.get('is_teacher')) {
            Backbone.View.header.addButton(
                new HeroButton({
                    firstLine: window.i18n.gettext('Set visibility'),
                    icon: 'eye',
                    callback: () => {
                        Backbone.View.Components.modal.open(VisibilityModal, {
                            title: window.i18n.gettext('Set visibility'),
                            model: this.model
                        })
                    }
                })
            );
        }
    },

    // Will be called when the section progress has been fetched
    onLoadSectionProgress() {

        // Destroy child views of sectionslot instance
        this.destroyChildViewsOfInstance(SectionSlot);

        // For each section, render a new section view
        this.model.sections.forEach((section) => {

            this.addChildView(new SectionSlot({
                // Sent with it the progress data
                progress: section.get('progress'),
                // Sent the section model as model
                model: section,
            }), '.js-sections-holder')

        })

        // Check if there is a practiceActivityId set
        if (this.model.get('sectionsProgress')
            && this.model.get('sectionsProgress').practiceActivityId
            && ACL.isAllowed(ACL.resources.ACTIVITIES, ACL.actions.VIEW, { type: 'adaptive_student' })
        ) {

            // Add click listener to this tile
            this.$('.js-practice-tile').on('click', () => {

                // Navigate to the right URL
                Backbone.history.navigate(
                    '/activities/show/' + this.model.get('sectionsProgress').practiceActivityId + '/chapter', {
                        trigger: true
                    }
                );
            });
        }
    },

    show() {
        // Abort show if chapter model is not defined.
        if (this.model === undefined) {
            return;
        }

        Backbone.View.header.setBackgroundImage(this.model)
    },

    getProgress() {
        $.post('/chapters/get_sections_progress.json', {
            chapter_ids: [this.model.get('id')]
        })
            .then(response => {

                const progressInfo = response[0]
                this.model.set({
                    sectionsProgress: {
                        practiceActivityId: progressInfo.practice_activity_id
                    }
                })
                progressInfo.progress.forEach(sectionProgress => {
                    this.model.sections.get(sectionProgress.section_id)
                        .set({
                            progress: sectionProgress.progress
                        })
                })
            })
            .catch(e => {
                console.error(e)

                // load view even if the call failed
            }).then(() => {
                this.onLoadSectionProgress()
            })
    },

    onDestroy() {
        Backbone.View.header?.removeBackgroundImage()
    }
});
