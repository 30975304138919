import Styles from './Note.scss';

import Template from './Note.hbs';
import MessageItem from 'views/components/messageItem/MessageItem'
import InlineCrumblepath from 'views/components/inlineCrumblepath/InlineCrumblepath'
import shapeList from 'util/ShapeLoader'

export default BaseView.extend({

    initialize({
        parentView,
    }) {

        this.bindAll([
            'getLink',
        ])

        const isGroupMessage = this.model.get('group_id')
        this.groupModel = Backbone.Collection.groups.get(this.model.get('group_id'))

        const sectionModel = !isGroupMessage && Backbone.Collection.sections.get(this.model.get('Activity').section_id);

        /**
         * When there are notes stored in the DB that reference a deleted
         * task or activity, do not render a MessageItem
         */
        if (!isGroupMessage && this.model.get('Activity').section_id && !sectionModel) {
            return
        } else if (!isGroupMessage && !sectionModel) {
            /**
             * if there's no group_id AND there is no linked activity,
             * log to sentry and do not render MessageItem
             */
            window.sentry.withScope(scope => {
                scope.setExtra('feedback data', this.model.attributes)
                window.sentry.captureMessage('Group feedback/note has no group id')
            })

            return
        }

        let crumblePathModel
        const activity = !isGroupMessage && this.model.get('Activity')

        if (activity) {
            crumblePathModel = Backbone.Collection.activities.get(activity.id)
        } else {
            // show inline crumble path with only the group.
            // by setting isHidden to false, it will work for
            // all layers
            crumblePathModel = new Backbone.Model()
            crumblePathModel.getAllCrumblepathModels = () => {
                return [new Backbone.Model({
                    ...this.groupModel.getAllCrumblepathModels()[0].attributes,
                    isHidden: false,
                })]
            }
        }

        this.setElement(Template({
            Styles,
        }))

        this.addChildView(
            new InlineCrumblepath({
                model: crumblePathModel,
                pageType: 'show'
            }),
            '.js-nav'
        )

        let name, avatar, direction

        if (Backbone.Model.user.get('is_student')) {

            // If the student replied to the message, it is an outgoing message, otherwise it is incoming
            if (this.model.get('reply_user_id') === Backbone.Model.user.id) {
                direction = 'out'
            } else {
                direction = 'in'
            }

            // Get name and avatar of the teacher that sent the original message
            const teacher = Backbone.Collection.teachers.get(this.model.get('sender_id'))
            if (teacher) {
                name = teacher.first_name_last_name()
                avatar = teacher.get('avatar')
            }
        }

        if (Backbone.Model.user.get('is_teacher')) {

            // If a student replied to the message, it is an incoming message
            if (this.model.get('reply_user_id')) {
                direction = 'in'

                // Get name and avatar of the student that replied
                const student = Backbone.Collection.students.get(this.model.get('reply_user_id'))
                if (student) {
                    name = student.first_name_last_name()
                    avatar = student.get('avatar')
                }

            // No student replied, it is an outgoing message
            } else {
                direction = 'out'

                // If the recipient is 0, it is a message to the whole group
                if (this.model.get('recipient_user_id') === 0) {
                    name = window.i18n.gettext('Whole class')
                    avatar = 'data:image/svg+xml;base64,' + btoa(shapeList['people'])

                // Otherwise get the name and avatar of the student the message was sent to
                } else {
                    const student = Backbone.Collection.students.get(this.model.get('recipient_user_id'))
                    if (student) {
                        name = student.first_name_last_name()
                        avatar = student.get('avatar')
                    }
                }
            }
        }

        this.addChildView(
            new MessageItem({
                model: this.model,
                recipientName: name,
                avatar,
                isNew: this.model.get('is_new'),
                created: this.model.get('reply_created') || this.model.get('created'),
                message: this.model.get('reply_message') || this.model.get('message'),
                link: isGroupMessage ? '' : this.getLink(),
                replyIcon: (direction === 'out') ? 'messageTo' : 'messageFrom',
                isGroupMessage,
                parentView,
                fromFeedback: true,
            }),
            '.js-message'
        );
    },

    getLink() {
        return '/activities/show/' + this.model.get('Activity').id + '/task_id:' + this.model.get('task_id') + '/' + this.model.get('id')
    }

});
