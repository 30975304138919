class AclRoles {

    // Start list of static properties containing all possible roles
    // within the Learnbeat application
    static STUDENT = 'student';
    static TEACHER = 'teacher';
    static SUPERVISOR = 'supervisor';

    /**
     * The method will check if the given role exists as a static
     * property of this class. This enforces us to define all the
     * possible roles within this (static) class and therefore it
     * is selfdocumented which roles exists within Learnbeat
     *
     * @static
     * @param {string} role string containing role to check
     * @returns {boolean} whether the rol exists
     * @memberof AclRoles
     */
    static exists(role) {
        return Object.values(AclRoles).includes(role);
    }
}

class AclActions {

    // Start list of static properties containing all possible roles
    // within the Learnbeat application

    // The view action should determine if an user is allowed to view the resource.
    static VIEW = 'view';

    // The add action should determine if an user is allowed to add a resource
    static ADD = 'add';

    // The join action is different from add, since its a request for adding something
    // instead of directy adding something
    static JOIN = 'join';

    /**
     * The method will check if the given action exists as a static
     * property of this class. This enforces us to define all the
     * possible action within this (static) class and therefore it
     * is selfdocumented which actions exists within Learnbeat
     *
     * @static
     * @param {string} action string containing role to check
     * @returns {boolean} whether the rol exists
     * @memberof AclActions
     */
    static exists(action) {
        return Object.values(AclActions).includes(action);
    }
}

class AclResources {

    // Start list of static properties containing all possible resources
    // within the Learnbeat application
    static HOME = 'home';
    static GROUPS = 'groups';
    static CHAPTERS = 'chapters';
    static SECTIONS = 'sections';
    static ACTIVITIES = 'activities';
    static TASKGROUPS = 'taskgroups';
    static RESPONSES = 'responses';
    static PLANNER = 'planner';
    static PROGRESS = 'progress';
    static RESULTS = 'results';
    static AUTHOR = 'author';
    static LIBRARY = 'library';
    static WORDS = 'words';
    static FEEDBACK = 'feedback';
    static ANNOTATIONS = 'annotations';
    static PORTFOLIO = 'portfolio';
    static SUPPORT = 'support';
    static SETTINGS = 'settings';
    static STUDENTS = 'students';
    static ACCESSOR = 'accessor';
    static STUDENTGRADING = 'studentgrading';
    static PRODUCTS = 'products';

    /**
     * The method will check if the given resource exists as a static
     * property of this class. This enforces us to define all the
     * possible resource within this (static) class and therefore it
     * is selfdocumented which resources exists within Learnbeat
     *
     * @static
     * @param {string} resource string containing resource to check
     * @returns {boolean} whether the rol exists
     * @memberof AclResources
     */
    static exists(resource) {
        return Object.values(AclResources).includes(resource)
    }
}

class ACL {

    // Create an holder for all the ACL rules, use Map instead of array
    // so we can use the '.has' method for easy allowance checking. This
    // map should be a private property to prevent tampering with it.
    static #rules = new Map();

    // Make roles staticly accessible within the ACL class since only ACL
    // will be exposed
    static roles = Object.freeze(AclRoles)

    // Make actions staticly accessible within the ACL class since only
    // ACL will be exposed
    static actions = Object.freeze(AclActions)

    // Make resource staticly accessible within the ACL class since only
    // ACL will be exposed
    static resources = Object.freeze(AclResources)

    /**
     * This method will add an ACL rule which we can use to check
     * access. We use functions for the actions so the allowance
     * state can be determined dynamically, so if it was false before
     * it can be true if something has changed. And it allows us to make
     * the checks really extensible. So we don't only check for roles,
     * but we can for example also allow a specific action only (or never)
     * on mobile.
     *
     * @static
     * @param {Object} {
     *  resource {string} containing the resource name,
     *  actions {object} containing an object with functions for each possible action
     * }
     * @memberof ACL
     */
    static add({resource, actions}) {

        if (!AclResources.exists(resource)) {
            throw new Error('Invalid resource given for rule:', arguments)
        }

        if (typeof actions !== 'object') {
            throw new Error('Actions is not an object for rule:', arguments)
        }

        if (!this.#rules.has(resource)) {
            this.#rules.set(resource, new Map())
        }

        const actionMap = this.#rules.get(resource)

        Object.keys(actions).forEach(
            action => AclActions.exists(action) && actionMap.set(action, actions[action])
        )
    }

    /**
     * Method to check if an specific action is allowed for a resource.
     * It will execute the function for the action on a resource as
     * added by the 'add' method. It disallows everything by default unless
     * is is allowed.
     *
     * @static
     * @param {string} resource containing the name of the resource
     * @param {string} action containing the name of the action
     * @param {undefined|object} options contains extra options for the condition
     * @returns {boolean} whether the action is allowed or not
     * @memberof ACL
     */
    static isAllowed(resource, action, options) {

        if (!this.#rules.has(resource)) {
            return false
        }

        const actionsForResource = this.#rules.get(resource)

        if (!actionsForResource || !actionsForResource.has(action)) {
            return false
        }

        const condition = actionsForResource.get(action)

        if (typeof condition !== 'function') {
            return false
        }

        if (options !== undefined && typeof options !== 'object') {
            console.error(
                'The options passed to isAllowed aren\'t an object so it won\'t be passed. Passed options:',
                options
            );
            options = undefined
        }

        // Only return true if the condition actually returns true. Anything else
        // should be considered false.
        return (condition(options) === true)
    }

    /**
     * This method will check if the user has a specific role. We should
     * use this method in favor of checking the 'is_student' or 'is_teacher'
     * flag, so if in the future we want to change the logic for that check,
     * we only have to change it here instead of in the whole application
     * code
     *
     * @static
     * @param {string} role string for the role of the AclRoles properties
     * @returns {Boolean} whether the user has that role or not
     * @memberof ACL
     */
    static checkRole(role) {
        if (!AclRoles.exists(role)) {
            return false
        }

        if (!Backbone.Model?.user?.get('role')) {
            return false
        }

        return !!(role === Backbone.Model.user.get('role'))
    }

    /**
     * Method for easily checking for multiple roles. If for example
     * af specific block of code is needed for 2 roles, we can use
     * this function to pass them as an array an check if one of those
     * is the role of the user. It calls checkRole, so the main logic
     * for checking a role is still within that method. This is just
     * a kind of helper for multiple roles checking
     *
     * @static
     * @param {array} roles array containing roles that are allowed
     * @returns {boolean} whether the user has one of the given roles
     * @memberof ACL
     */
    static checkRoles(roles) {

        // If not an array is passed, log an error in the console to let the
        // developer know they are doing something wrong, however we try to
        // fix it by making roles an array
        if (!Array.isArray(roles)) {
            console.error('Trying to check multiple roles with no array: ', roles)

            // Try to fix the developer's mistake by asuming they forgot brackets
            // and are trying a single role. Check role will validate it and return
            // false by default if it is something we don't know
            roles = [roles]
        }

        // Check for all given roles if the user has this role. If it has, we return
        // true since only one of the roles needs to be true. When no matching roles
        // found, we'll go to the return false at the end of this function to be
        // distrusting by default.
        for (const role of roles) {
            if (ACL.checkRole(role) === true) {
                return true;
            }
        }

        return false;
    }

    /**
     * Method for listing all the initiated rules.
     * !Note: this will return a dereferenced mapping so it can't be tampered with
     *
     * This should only be used for debugging purposes to see whether the correct rules
     * are applied or to see which rules are available.
     *
     * @static
     * @returns {Map} containing a dereferenced clone of the rules
     * @memberof ACL
     */
    static listRules() {

        // Do some magic to make everything dereferenced. Since the values are also maps,
        // we cant just simple do: new Map(rules), because than the individual rules can
        // still be tampered with. There we first need to dereference the individual maps
        // that exists per resource. And than create a dereferenced map from those.
        return new Map(Array.from(this.#rules, ([key, value]) => [key, new Map(value)]))
    }
}

// Spread out the resources so we don't have to prefix everything (lazy-developing)
const {
    HOME,
    GROUPS,
    CHAPTERS,
    SECTIONS,
    ACTIVITIES,
    TASKGROUPS,
    RESPONSES,
    PLANNER,
    PROGRESS,
    RESULTS,
    AUTHOR,
    LIBRARY,
    WORDS,
    FEEDBACK,
    ANNOTATIONS,
    PORTFOLIO,
    SUPPORT,
    SETTINGS,
    STUDENTS,
    ACCESSOR,
    STUDENTGRADING,
    PRODUCTS
} = AclResources

// Spread out the roles so we don't have to prefix everything (lazy-developing)
const { STUDENT, TEACHER, SUPERVISOR } = AclRoles

// Start adding list of ACL rules
// TODO: extend list with more resources and actions
ACL.add({
    resource: HOME,
    actions: {
        [ACL.actions.VIEW]() {
            return ACL.checkRole(STUDENT)
                || ACL.checkRole(TEACHER)
        }
    }
})

ACL.add({
    resource: GROUPS,
    actions: {
        [ACL.actions.VIEW]() {
            return ACL.checkRole(STUDENT)
                || ACL.checkRole(TEACHER)
                || ACL.checkRole(SUPERVISOR)
        },
        [ACL.actions.ADD]() {
            return ACL.checkRole(TEACHER)
        },
        [ACL.actions.JOIN]() {
            return ACL.checkRole(STUDENT)
        }
    }
})

ACL.add({
    resource: CHAPTERS,
    actions: {
        [ACL.actions.VIEW]() {
            return ACL.checkRole(STUDENT)
                || ACL.checkRole(TEACHER)
                || ACL.checkRole(SUPERVISOR)
        }
    }
})

ACL.add({
    resource: SECTIONS,
    actions: {
        [ACL.actions.VIEW]() {
            return ACL.checkRole(STUDENT)
                || ACL.checkRole(TEACHER)
                || ACL.checkRole(SUPERVISOR)
        }
    }
})

ACL.add({
    resource: ACTIVITIES,
    actions: {
        [ACL.actions.VIEW]({ type }) {
            switch (type) {
                case 'linear':
                    return ACL.checkRole(STUDENT)
                        || ACL.checkRole(TEACHER)
                        || ACL.checkRole(SUPERVISOR)
                case 'adaptive':
                    return ACL.checkRole(STUDENT)
                        || ACL.checkRole(TEACHER)
                case 'adaptive_student':
                    return ACL.checkRole(STUDENT)
                        || ACL.checkRole(TEACHER)
                case 'exam':
                    return ACL.checkRole(STUDENT)
                        || ACL.checkRole(TEACHER)
                        || ACL.checkRole(SUPERVISOR)
                case 'diagnostic_exam':
                    return ACL.checkRole(STUDENT)
                        || ACL.checkRole(TEACHER)
                        || ACL.checkRole(SUPERVISOR)
                case 'generated_exam':
                    return ACL.checkRole(STUDENT)
                        || ACL.checkRole(TEACHER)
                        || ACL.checkRole(SUPERVISOR)
                case 'connect':
                    return ACL.checkRole(STUDENT)
                        || ACL.checkRole(TEACHER)
                case 'presentation':
                    return ACL.checkRole(STUDENT)
                        || ACL.checkRole(TEACHER)
                case 'training':
                    return ACL.checkRole(STUDENT)
                        || ACL.checkRole(TEACHER)
                case 'competencies':
                    return ACL.checkRole(STUDENT)
                        || ACL.checkRole(TEACHER)
                case 'lti':
                    return ACL.checkRole(STUDENT)
                        || ACL.checkRole(TEACHER)
                case 'video':
                    return ACL.checkRole(STUDENT)
                        || ACL.checkRole(TEACHER)
                        || ACL.checkRole(SUPERVISOR)
            }
        }
    }
})

ACL.add({
    resource: TASKGROUPS,
    actions: {
        [ACL.actions.VIEW]() {
            return ACL.checkRole(STUDENT)
                || ACL.checkRole(TEACHER)
                || ACL.checkRole(SUPERVISOR)
        }
    }
})

ACL.add({
    resource: RESPONSES,
    actions: {
        [ACL.actions.VIEW]() {
            return ACL.checkRole(STUDENT)
                || ACL.checkRole(TEACHER)
                || ACL.checkRole(SUPERVISOR)
        },
        [ACL.actions.ADD]() {
            return ACL.checkRole(STUDENT)
        }
    }
})

ACL.add({
    resource: PLANNER,
    actions: {
        [ACL.actions.VIEW]() {
            return ACL.checkRole(TEACHER)
                || (
                    // For students, only allow the study planner if they
                    // have a group with a planner
                    ACL.checkRole(STUDENT)
                    && Backbone.Collection?.groups?.any({
                        has_active_planner: 1
                    })
                )
        }
    }
})

ACL.add({
    resource: PROGRESS,
    actions: {
        [ACL.actions.VIEW]() {
            return !ISMOBILE
                && ACL.checkRole(TEACHER)
        }
    }
})

ACL.add({
    resource: RESULTS,
    actions: {
        [ACL.actions.VIEW]({ type }) {
            if (type === 'users') {
                return ACL.checkRole(STUDENT)
                    || ACL.isAllowed(RESULTS, ACL.actions.VIEW, {type: 'groups'})
            }

            if (type === 'groups') {
                return !ISMOBILE
                    && ACL.checkRole(TEACHER)
            }
        }
    }
})

ACL.add({
    resource: AUTHOR,
    actions: {
        [ACL.actions.VIEW]() {
            return !ISMOBILE
                && ACL.checkRole(TEACHER)
        }
    }
})

ACL.add({
    resource: LIBRARY,
    actions: {
        [ACL.actions.VIEW]() {
            return !ISMOBILE
                && ACL.checkRole(TEACHER)
        }
    }
})

ACL.add({
    resource: WORDS,
    actions: {
        [ACL.actions.VIEW]() {
            return ACL.checkRole(STUDENT)
        }
    }
})

ACL.add({
    resource: FEEDBACK,
    actions: {
        [ACL.actions.VIEW]({ type }) {
            if (type === 'workOn') {
                return ACL.checkRole(STUDENT)
                    || ACL.checkRole(TEACHER)
                    || ACL.checkRole(SUPERVISOR)
            }

            if (type === 'list') {
                return ACL.checkRole(STUDENT)
                    || ACL.checkRole(TEACHER)
            }
        },
        [ACL.actions.ADD]({ type }) {
            if (type === 'new') {
                return ACL.checkRole(TEACHER)
            }

            if (type === 'reply') {
                return ACL.checkRole(STUDENT)
                    || ACL.checkRole(TEACHER)
            }
        }
    }
})

ACL.add({
    resource: ANNOTATIONS,
    actions: {
        [ACL.actions.VIEW]() {
            return ACL.checkRole(STUDENT)
                || ACL.checkRole(TEACHER)
        },
        [ACL.actions.ADD]() {
            return ACL.checkRole(STUDENT)
                || ACL.checkRole(TEACHER)
        }
    }
})

ACL.add({
    resource: PORTFOLIO,
    actions: {
        [ACL.actions.VIEW]({type}) {
            switch (type) {
                case 'studentList':
                    return !ISMOBILE
                        && ACL.checkRole(TEACHER)
                case 'projectList':
                case 'project':
                case 'group':
                    return ACL.checkRole(STUDENT)
                        || (
                            !ISMOBILE
                            && ACL.checkRole(TEACHER)
                        )
            }
        },
    }
})

ACL.add({
    resource: SUPPORT,
    actions: {
        [ACL.actions.ADD]() {
            return ACL.checkRole(STUDENT)
                || ACL.checkRole(TEACHER)
        }
    }
})

ACL.add({
    resource: SETTINGS,
    actions: {
        [ACL.actions.VIEW]() {
            return ACL.checkRole(STUDENT)
                || ACL.checkRole(TEACHER)
        }
    }
})

ACL.add({
    resource: STUDENTS,
    actions: {
        [ACL.actions.VIEW]() {
            return ACL.checkRole(SUPERVISOR)
        }
    }
})

ACL.add({
    resource: ACCESSOR,
    actions: {
        [ACL.actions.VIEW]() {
            return !!(Backbone.Model?.accessor?.get('email'))
        }
    }
})

ACL.add({
    resource: STUDENTGRADING,
    actions: {
        [ACL.actions.VIEW]() {
            return ACL.checkRole(STUDENT)
                || ACL.checkRole(TEACHER)
        }
    }
})

ACL.add({
    resource: PRODUCTS,
    actions: {
        [ACL.actions.VIEW]() {
            return ACL.checkRole(STUDENT)
        }
    }
})

// Also make the ACL class immutable. This prevents tampering with roles and
// resources.
Object.freeze(ACL)

// Expose ACL so we can use it within other files
export default ACL
