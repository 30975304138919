export default Backbone.Model.extend({

    /**
     * last_name_first_name
     *
     * This function will return the last name then first name of the teacher
     *
     * @return {string}  Stringed version of the name
     */
    last_name_first_name() {

        // Check if there is a last name
        if (this.get('sortable_last_name').length > 0) {

            // Return a stringed version of last name, first name
            return (
                this.get('sortable_last_name') + ', ' + (this.get('first_name') + ' ' + this.get('prefix')).trim()
            );

        }

        // Else there isn't a last name, just return first name.
        return this.get('first_name')

    },

    /**
         * first_name_last_name
         *
         * This function will return the firstname then last name of the teacher
         *
         * @return {string}  Stringed version of the name
         */
    first_name_last_name() {

        // Check if there is a last name set
        if (this.get('sortable_last_name').length > 0) {

            // Return a stringed version of the first name last name
            return (
                (this.get('first_name') + ' ' + this.get('prefix')).trim() + ' ' + this.get('sortable_last_name')
            );

        }

        // Else no last name known, just return first name
        return this.get('first_name');
    },

    /**
         * getCrumblepathModel
         *
         * This will return a crumblepath valid model
         *
         * @return {Backbone.Model}     crumblepath valid model
         */
    getCrumblepathModel() {

        // Return crumblepath valid model
        return new Backbone.Model({
            label: this.first_name_last_name(),
            level: 'students',
            path_id: this.get('id')
        });
    },

    /**
         * getAllCrumblepathModels
         *
         * This function will return an array of all the models for this
         * crumblepath
         *
         * @return {Array}      Array with all the models
         */
    getAllCrumblepathModels() {
        return [
            this.getGroupModel().getCrumblepathModel(),
            this.getCrumblepathModel()
        ]
    },

    /**
         * getGroupModel
         *
         * This function will return the model for the group
         *
         * @return {Backbone.Model}     Model for group
         */
    getGroupModel() {
        // Return the group bound to this teacher
        return Backbone.Collection.groups.get(this.get('group_id'));
    },

}, {
    type: 'teacher'
});
