import Styles from './Answer.scss';

import Template from './Answer.hbs';
import CheckAnswerStudentView from 'views/components/answers/student/Student'
import SendNoteSidebarView from 'views/components/notes/sidebar/SendNote'
import NoteView from 'views/components/notes/noteWorkOn/NoteWorkOn'
import Util from 'util/util'
import Button from 'views/components/button/Button'
import Dropdown from '../dropdown/Dropdown.svelte';
import NoResponseStudentsList from 'views/components/answers/noResponseStudentsList/NoResponseStudentsList'
import DownloadAllFilesTemplate from './DownloadAllFiles.hbs'

export default class Answer extends BaseView {

    /**
    * initialize
    *
    * @param {Task} taskView                       Parent task view this answer view is related to.
    * @param {number|undefined} singleAnswerUserId If set, only show answer of a single student instead of group.
    * @param {string|BaseView} correctAnswer       Correct answer of task to displayed above student answers.
    * @param {boolean|undefined} onlyOneAnswer     If true, do not render task content, only student answer view.
    * @param {function|undefined} answerCallback   Optional logic to execute when rendering answer.
    * @param {boolean} isInPresentMode             Keeps track of if it is in presentation mode or not.
    */
    initialize({
        taskView,
        singleAnswerUserId,
        correctAnswer,
        onlyOneAnswer,
        answerCallback,
        isInPresentMode
    }) {

        _.bindAll(
            this,
            'addCheckAnswerStudentView',
            'createDropdownItems',
            'onSendnote',
            'displayModelAnswer',
            'displayOriginalAnswer',
            'displayGradingTools',
            'hideGradedAnswers',
        );

        this.taskView = taskView;
        this.isInPresentMode = isInPresentMode
        this.model = this.taskView.work_on.model
        this.groupModel = this.model.getGroupModel()
        const studentModel = this.groupModel.students.get(singleAnswerUserId)
        this.isCalledFromProgress = !!singleAnswerUserId

        // Only show model answer by default if user is teacher AND the user setting for
        // showing the model answer is unset or true AND the task is not in preview mode.
        const doShowModelAnswer = Backbone.Model.user.get('is_teacher') && (
            _.isUndefined(Backbone.Model.user.getSetting('displayModelAnswerWithStudentAnswer')) ||
            !!Backbone.Model.user.getSetting('displayModelAnswerWithStudentAnswer')
        ) && !taskView.isPreview

        this.setElement(Template({
            Styles,
            doShowModelAnswer,
            isExam: this.isExam(),
            onlyOneAnswer,
            isInPresentMode,
            correctAnswer: _.isEmpty(correctAnswer) ?
                window.i18n.gettext('No model answer available') :
                correctAnswer
        }))

        // show available feedback for the class
        // if there is a student model - it is a progress view and no whole class feedback is needed
        if (
            !this.taskView.practice &&
            !this.taskView.isPreview &&
            APPLICATION !== 'author' &&
            !studentModel &&
            this.taskView.work_on.model.get('type') !== 'presentation' &&
            !isInPresentMode
        ) {
            this.displayNotes();
        }

        if (studentModel) {
            // Show response of just one student
            this.addCheckAnswerStudentView(studentModel);
        } else {

            if (!this.groupModel.students.length) {
                this.$('.js-no-students').css('display', 'block')
            } else if (isInPresentMode) {
                // In present mode, group students by if they have given an response or not. Then shuffle both
                // groups and join these together again.
                const groupedStudents = this.groupModel.students.partition((student) => {
                    return this.model.responses.some({
                        task_id: taskView.model.id,
                        user_id: student.id
                    })
                })
                _.shuffle(groupedStudents[0]).forEach(this.addCheckAnswerStudentView)
                if (groupedStudents[1].length) {
                    this.addChildView(new NoResponseStudentsList({
                        studentModels: groupedStudents[1],
                    }), '.js-student-answers')
                }
            } else {
                this.groupModel.students.each(this.addCheckAnswerStudentView)
            }

            if (!isInPresentMode) {
                this.markGradedAnswers()

                // Create and populate dropdown with toggle switches
                const dropdownItems = this.createDropdownItems();
                this.addSvelteChildView('.js-dropdown-view', Dropdown, {
                    buttonOptions: {
                        label: window.i18n.gettext('Options'),
                        icon: 'arrow-drop-down-circle',
                        theme: 'secondary',
                    },
                    dropdownItems
                })

                this.addChildView(
                    new Button({
                        label: window.i18n.gettext('Send feedback to class'),
                        icon: 'feedback-filled',
                        inline: true,
                        theme: 'secondary',
                        callback: () => this.onClickSendnoteToAll()
                    }),
                    '.js-note-to-all'
                )

                // When the task is 'file upload', allow download of all answers
                if (taskView.model.get('template_id') === 21) {
                    this.addDownloadAllFilesCard(taskView);
                }
            }
        }

        // Optional logic to execute when rendering answer.
        if (answerCallback) {
            answerCallback(this)
        }

        // If the correct answer is higher than half of the window height, remove the sticky position from it so it
        // does not cover to many or all of the student answers.
        const removeSticky = () => {
            const correctAnswerHeight = this.$('.js-answer-example').height();
            if (correctAnswerHeight > window.innerHeight / 2) {
                this.$('.js-answer-example').removeClass(Styles['is-sticky']);
            }
        }

        // Find all img elements in the correct answer view. If these exist, wait for them to load or fail to load,
        // which is relevant to determine to keep the container sticky or not, since when the images are loaded in
        // the height of the container may be larger, potentially covering most if not all of the student answers.
        // Exclude images that use inline 'data:' for source, because they don't fire the onload event
        const imagesInCorrectAnswer = this.el.querySelectorAll('.js-correct-answer-holder img:not([src^=data])')
        if (imagesInCorrectAnswer.length) {
            Promise.all(
                _.map(imagesInCorrectAnswer, imageElement => {
                    return new Promise(resolve => {
                        imageElement.onload = () => resolve({status: 'ok'})
                        imageElement.onerror = () => resolve({status: 'error'})
                    })
                })
            ).then(removeSticky)
        } else {

            // We use a defer here because somehow the measured height is 0 without it
            _.defer(() => {
                removeSticky()
            })
        }

        if (!this.isCalledFromProgress) {
            this.addHideGradedAnswersListener()

            this.setHideGradedAnswerClass()

            this.checkIfHiddenAnswersStateDivShouldBeShown()

            /**
             * Add a hidden answers state div. the div is only displayed
             * if user setting for hiding graded answers is active
             */
            this.addHiddenAnswersText()
        }
    }

    addDownloadAllFilesCard(taskView) {
        const responsesForTask = new Backbone.Collection(
            this.model.responses.filter(
                response => response
                    && response.get('task_id') === this.taskView.model.get('id')
                    && typeof response.get('json_answer') === 'object'
                    && Object.keys(response.get('json_answer')).length > 0
            )
        )

        const userCount = responsesForTask.pluck('user_id').length;

        if (userCount > 1) {
            const statsCount = responsesForTask
                .reduce(
                    (count, responseModel) => {

                        const item = responseModel.get('json_answer');

                        if (item && typeof item === 'object') {
                            const numberOfUrls = Object.values(item)
                                .filter(i => i.isExtensionFile)
                                .length;
                            const numberOfFiles = Object.keys(item).length;

                            count.files += numberOfFiles;
                            count.urls += numberOfUrls;
                        }

                        return count;
                    },
                    { files: 0, urls: 0 }
                );

            const filesString = window.i18n.sprintf(
                window.i18n.ngettext(
                    '%d file',
                    '%d files',
                    statsCount.files
                ),
                statsCount.files
            );

            const downloadAllFilesElement = $(DownloadAllFilesTemplate({
                text: window.i18n.sprintf(
                    window.i18n.ngettext(
                        '%d student has handed in %s',
                        '%d students have handed in %s',
                        userCount
                    ),
                    userCount,
                    filesString
                ),
                subText: statsCount.urls > 0
                    ? window.i18n.sprintf(
                        window.i18n.ngettext(
                            'of which %d external file (exported as urls)',
                            'of which %d external files (exported as urls)',
                            statsCount.urls
                        ),
                        statsCount.urls
                    )
                    : '',
                Styles
            }))

            this.$('.js-actions').after(downloadAllFilesElement);

            this.addChildView(
                new Button({
                    icon: 'file-download',
                    label: window.i18n.gettext('Download all files (.zip)'),
                    suffix: '.zip',
                    inline: true,
                    callback: () => window.open(
                        '/tasks/downloadAnswers/' + this.model.id + '/' + taskView.model.id,
                        '_parent'
                    )
                }),
                downloadAllFilesElement
            )
        }
    }

    /**
     * Listen to changes on the responses. In case the changed response is part of
     * this Answer view's task and the teacher has chosen to hide graded tasks,
     * filter out the graded response
     */
    addHideGradedAnswersListener() {

        /**
         * When checking answers for exam, retrieve score from responses_original.
         * Save a reference so this.calculateGradedAnswers() can use it
         */
        this.responsesForGradedAnswer = this.isExam() ? this.model.responses_original : this.model.responses

        this.calculateGradedAnswers()

        this.listenTo(this.responsesForGradedAnswer, 'change:score', (responseModel) => {

            this.calculateGradedAnswers()

            // update hidden answers status text
            this.addHiddenAnswersText()

            if (!this.shouldHideGradedAnswer()) {
                return
            }

            if (this.taskView.model.get('id') !== responseModel.get('task_id')) {
                return
            }

            // get reference to the rendered answer block of student
            const singleStudentAnswerBlock = this.el.querySelector(
                this.getSelectorForGradedAnswer(responseModel.get('user_id'), responseModel.get('task_id'))
            )

            if (!singleStudentAnswerBlock) {
                return
            }

            TweenMax.to(singleStudentAnswerBlock, {
                duration: 0.2,
                opacity: 0,
                onComplete: () => {

                    this.animateAnswersContainerHeight(responseModel.get('user_id'), singleStudentAnswerBlock)

                }
            })

        })
    }

    // Creates and populates dropdown with switches for toggling visibility of various modes.
    createDropdownItems() {

        const dropdownItems = []

        // Create toggle model answer visibility switch.
        // Only show model answer by default if user is teacher AND the user setting for
        // showing the model answer is unset or true.
        const doShowModelAnswer = Backbone.Model.user.get('is_teacher') && (
            _.isUndefined(Backbone.Model.user.getSetting('displayModelAnswerWithStudentAnswer')) ||
            !!Backbone.Model.user.getSetting('displayModelAnswerWithStudentAnswer')
        );

        // Propagate model answer visibility to the page.
        this.displayModelAnswer(doShowModelAnswer, true);
        dropdownItems.push({
            label: window.i18n.gettext('Model answer'),
            hasToggle: true,
            callback: this.displayModelAnswer,
            toggleState: doShowModelAnswer,
        })

        if (!this.isExam()) {

            // Determine if original answer should be shown without
            // sending request to backend (silently)
            const doShowOriginalAnswer = Backbone.Model.user.get('is_teacher') &&
                Backbone.Model.user.getSetting('is_original_watching')
            this.displayOriginalAnswer(doShowOriginalAnswer, true)

            dropdownItems.push({
                label: window.i18n.gettext('Original answer'),
                hasToggle: true,
                callback: this.displayOriginalAnswer,
                toggleState: doShowOriginalAnswer,
            })
        }

        // Create toggle grading tools visibility switch.
        // Only show grading tools by default if the user is teacher AND the current user
        // setting for this is unset or true.
        const doShowGradingTools = Backbone.Model.user.get('is_teacher') && (
            _.isUndefined(Backbone.Model.user.getSetting('is_grading')) ||
            Backbone.Model.user.getSetting('is_grading')
        );
        // Propagate grading tools visibility to the page without updating the user setting.
        this.displayGradingTools(doShowGradingTools, true)

        dropdownItems.push({
            label: window.i18n.gettext('Grade answers'),
            hasToggle: true,
            callback: this.displayGradingTools,
            toggleState: doShowGradingTools,
        })

        // Create "hide graded answer" switch.
        dropdownItems.push({
            label: window.i18n.gettext('Hide graded answers'),
            hasToggle: true,
            callback: this.hideGradedAnswers,
            toggleState: this.shouldHideGradedAnswer(),
        })

        return dropdownItems
    }

    /**
     * displayModelAnswer
     *
     * Show/Hide model answer.
     *
     * @param  {boolean} state  true if visible
     * @param  {boolean} silent if true, don't patch user setting to prevent unneeded traffic
     */
    displayModelAnswer(state, silent) {
        this.$('.js-answer-example').toggle(state);
        if (silent !== true) {
            // Convert boolean to 0 or 1.
            Backbone.Model.user.addSetting('displayModelAnswerWithStudentAnswer', state | 0);
        }
    }

    /**
     * displayOriginalAnswer
     *
     * Show/Hide original answer and update user setting.
     *
     * @param  {boolean} state      true if visible
     * @param  {boolean} silent     if true, don't patch user setting to prevent unneeded traffic
     */
    displayOriginalAnswer(state, silent) {
        // Check if silent is not true. Second argument can also be an instance of ToggleSwitch,
        // in which case we do actually want to update the user setting.
        if (silent !== true) {
            Backbone.Model.user.addSetting('is_original_watching', state | 0)
        }

        this.trigger('toggleOriginalAnswer', state)

        this.$('.js-non-exam-original-answer').toggle(!!state)
    }

    /**
     * displayGradingTools
     *
     * Show/Hide grading tools and update user setting.
     *
     * @param  {boolean} state      true if visible
     * @param  {boolean} silent     if true, don't patch user setting to prevent unneeded traffic
     */
    displayGradingTools(state, silent) {
        // Check if silent is not true. Second argument can also be an instance of ToggleSwitch,
        // in which case we do actually want to update the user setting.
        if (silent !== true) {
            Backbone.Model.user.addSetting('is_grading', state | 0)
        }
        this.$('.js-score-holder').toggle(!!state)

        this.$('.js-grade-answer').toggle(!!state)
    }

    /**
     *
     * @param {boolean|undefined|null} hideAnswers undefined if user setting is unset,
     * boolean if set,  null if programmatically set (view loading)
     */
    hideGradedAnswers(hideAnswers) {

        if (hideAnswers !== null) {
            Backbone.Model.user.addSetting('hideGradedAnswers', hideAnswers | 0)
        }

        this.setHideGradedAnswerClass()

        this.checkIfHiddenAnswersStateDivShouldBeShown()

        this.isCalledFromProgress ?
            this.checkIfGradedAnswersShouldBeHiddenForProgress() :
            this.markGradedAnswers()
    }

    onClickSendnoteToAll() {
        this.onSendnote(0);
        return false;
    }

    /**
     * onSendnote
     *
     * Create sidebar for sending feedback to student/group.
     *

        * @param  {number} recipient_id student or students adressed
        * @parem {Object} studentView student view
        */

    onSendnote(recipientId, studentView) {

        let previousContent = null;

        /**
         * Check if there is an unsaved draft saved in localStorage. Send previous
         * editor content to the sidebar view so tinyMce can be initalized with
         * the previous content.
         */

        if (Util.hasSupportForLocalstorage()) {
            previousContent = window.localStorage.getItem(this.getFeedbackId(recipientId));
        }

        // if the feedback is being sent to a student,
        // than we have a studentView argument and shoud pass it as a parent
        var sendNoteSidebar = new SendNoteSidebarView({
            // 0 is whole class
            recipient_user_id: recipientId,
            activity_model: this.model,
            task_view: this.taskView,
            parentView: studentView || this,
            editorContent: previousContent,
        });

        this.registerChildView(sendNoteSidebar);
        Backbone.View.sidebar.showSidebar(sendNoteSidebar, window.i18n.gettext('Send feedback'), 630);

        /**
         * Listen to sidebarClosed event. On close: extract and persist editor text, then destroy view;
         */
        this.listenToOnce(
            Backbone.View.sidebar,
            'sidebarClosed',
            _.partial(
                this.extractEditorContentAndDestroy,
                sendNoteSidebar,
                recipientId,
            )
        );
    }
    /**
     *
     * @param {BaseView} sidebarContent A view that is inserted in the sidebar.
     * @param {number} recipientId User id.
     * @param {boolean} isSaved Boolean indicating if content should be saved in localStorage.
     */
    extractEditorContentAndDestroy(sidebarContent, recipientId, isSaved) {

        if (!Util.hasSupportForLocalstorage()) {
            this.unregisterAndDestroyChildView(sidebarContent);
            return;
        }

        const feedbackId = this.getFeedbackId(recipientId);

        if (!isSaved) {
            // save in localStorage
            Backbone.View.sidebar.storeEditorContentInLocalStorage(sidebarContent, feedbackId);
        } else {
            // clear unsaved content from localStorage
            localStorage.removeItem(feedbackId);

        }
        this.unregisterAndDestroyChildView(sidebarContent);
    }
    /**
     * getFeedbackId
     *
     * Create unique id for unsaved drafts that can be used as local storage keys.
     *
     * @param {number} recipientId User id.
     * @returns {string} Unique id as string: feedback:userId:taskId
     */
    getFeedbackId(recipientId) {
        return 'feedback:' + recipientId + ':' + this.taskView.model.id;
    }

    /**
     * addCheckAnswerStudentView
     *
     * Create view with the student response, original response and grading tools.
     *
     * @param  {StudentModel} student model
     */
    addCheckAnswerStudentView(student) {
        // If the activity type is a generated exam, only render a student answer for the students that had this
        // task as a part of their version of the exam.
        if (this.model.get('type') === 'generated_exam') {
            const generatedExamStudent = this.model.getGeneratedExamByStudent(student.id)
            if (
                !generatedExamStudent ||
                generatedExamStudent.get('exam').indexOf(this.taskView.model.get('task_group_id')) === -1
            ) {
                return
            }
        }

        this.addChildView(new CheckAnswerStudentView({
            model: student,
            response: this.model.responses.findWhere({
                task_id: this.taskView.model.get('id'),
                user_id: student.get('id')
            }),
            responseOriginal: this.model.responses_original.findWhere({
                task_id: this.taskView.model.get('id'),
                user_id: student.get('id')
            }),
            taskView: this.taskView,
            parentView: this,
            isInPresentMode: this.isInPresentMode,
        }), '.js-student-answers')

    }

    /**
     * displayNotes - displays feedback given to this student on this task.
     * Also is called after updating the feedback.
     * @param  {Backbone.model} addedNote updeted feedback for the student
     */
    displayNotes(addedNote) {

        if (addedNote) {
            this.model.notes.add(addedNote);
        }

        this.destroyChildViewsOfInstance(NoteView);
        // filter feedback for whole class for this specific task
        var noteModels = this.taskView.work_on.model.notes.where({
            recipient_user_id: 0, task_id: this.taskView.model.id
        });

        _.each(noteModels, (noteModel) => {

            this.addChildView(new NoteView({
                model: noteModel,
                taskView: this.taskView,
                parentView: this
            }), '.js-class-notes')

        })
    }

    /**
     * Add data-is-graded is attribute to graded answers. If the parent container has
     * the 'answer--hide-graded class, elements with the data-is-graded class will
     * not be displayed
     */
    markGradedAnswers() {

        _.defer(() => {

            this.gradedAnswers.forEach(gradedAnswer => {
                const answerBlock = this.el.querySelector(
                    this.getSelectorForGradedAnswer(gradedAnswer.get('user_id'), gradedAnswer.get('task_id'))
                )

                if (answerBlock) {
                    answerBlock.dataset.isGraded = ''
                }
            })

        })

    }

    /**
     * Show visual feedback to teacher in case all student answers for
     * a task are graded and "hide graded answers" setting is on
     */
    checkIfHiddenAnswersStateDivShouldBeShown() {

        const hiddenAnswersStateDiv = this.el.querySelector('.js-hidden-answers-state')

        if (!hiddenAnswersStateDiv) {
            return
        }

        const shouldHideGradedAnswers = this.shouldHideGradedAnswer()

        const hiddenAnswersClass = Styles['hidden-answers-state--visible'];
        const hiddenAnswersStateIsVisible = hiddenAnswersStateDiv.classList.contains(hiddenAnswersClass)

        const showHiddenAnswersStateDiv = () => hiddenAnswersStateDiv.classList.add(hiddenAnswersClass)
        const hideHiddenAnswersStateDiv = () => hiddenAnswersStateDiv.classList.remove(hiddenAnswersClass)

        if (shouldHideGradedAnswers) {

            if (!hiddenAnswersStateIsVisible) {
                showHiddenAnswersStateDiv()
            }

        } else if (hiddenAnswersStateIsVisible) {
            hideHiddenAnswersStateDiv()
        }
    }

    shouldHideGradedAnswer() {
        return Backbone.Model.user.get('is_teacher') && Backbone.Model.user.getSetting('hideGradedAnswers')
    }

    /**
     * Add class to parent element. If child element has data-is-graded attribute, that child
     * element will not be displayed
     */
    setHideGradedAnswerClass() {

        const hasHideGradedAnswerClass = this.el.classList.contains(Styles['answer--hide-graded'])

        if (this.shouldHideGradedAnswer() && !hasHideGradedAnswerClass) {
            this.el.classList.add(Styles['answer--hide-graded'])
        } else if (!this.shouldHideGradedAnswer() && hasHideGradedAnswerClass) {
            this.el.classList.remove(Styles['answer--hide-graded'])
        }

    }

    /**
     * Get selector for student's graded answer when grading from Learning Material
     *
     * @param {string|number} userId User id
     * @param {string|number} taskId Task id
     *
     * @returns {string} the selector for the element in which a a graded student
     * answer is rendered
     */
    getSelectorForGradedAnswer(userId, taskId) {
        return '[data-student-id="' + userId + '"][data-task-id="' + taskId + '"]'
    }

    /**
     * Find and set the amount of graded answers when in task
     */
    calculateGradedAnswers() {
        if (!this.allAnswers) {
            this.allAnswers = this.responsesForGradedAnswer.filter(
                response => response.get('task_id') === this.taskView.model.get('id')
            )
        }

        // only show still gradable answers (filter both graded and unmade tasks)
        this.gradedAnswers = this.allAnswers.filter(
            response => (
                response.get('score') !== -1 ||
                !response.has('json_answer')
            )
        )

    }

    /**
     * Get the text for the hidden answers state div
     *
     * @returns {string} the hidden answers state text
     */
    getHiddenAnwersStatusText() {

        const contextualText = window.i18n.sprintf(
            window.i18n.ngettext(
                '%d answer is hidden.',
                '%d answers are hidden.',
                this.gradedAnswers.length
            ),
            this.gradedAnswers.length
        )

        const sharedText = window.i18n.gettext('Turn "hide graded answers" off to view all answers')

        return contextualText + ' ' + sharedText
    }

    addHiddenAnswersText() {
        this.$('.js-hidden-answers-state').text(this.getHiddenAnwersStatusText())
    }

    /**
     *  Animate remaining ungraded answer blocks "up" after an answer is graded.
     *
     * @param {number} studentId student id
     * @param {HTMLDivElement} recentlyGradedAnswerBlock Answer block of one student
     */
    animateAnswersContainerHeight(studentId, recentlyGradedAnswerBlock) {

        const ungradedAnswerBlocks = this.el.querySelectorAll('.work-on__student-answer:not([data-is-graded])')

        const studentIdAsString = studentId.toString()
        const answerBlockHeight = recentlyGradedAnswerBlock.getBoundingClientRect().height + 10

        /**
         * Out of all ungraded answers, find all graded answers *after* the recently graded answer.
         * These need to be animated up one block up as soon as the the recently graded answer
         * has the opacity of 0
         */
        let isAfterGradedAnswer = false

        const animatedAnswersBlocks = [ ...ungradedAnswerBlocks ].filter(answerBlock => {
            if (!isAfterGradedAnswer) {
                if (answerBlock.dataset.studentId === studentIdAsString) {
                    isAfterGradedAnswer = true
                }
                return false
            }

            answerBlock.classList.add(Styles['answer--animate'])
            answerBlock.style.transform = 'translateY(' + -1 * answerBlockHeight + 'px)'

            return true

        })

        /**
         * As soon as the remaining answers are animated up, and the
         * recently graded answer has a display: none set (and does not occupy
         * any height anymore), remove translateY styles
         */
        setTimeout(() => {
            animatedAnswersBlocks.forEach(answerBlock => {

                answerBlock.classList.remove(Styles['answer--animate'])
                answerBlock.removeAttribute('style')

            })

            // set display:none on all graded answers
            this.markGradedAnswers()

            // remove TweenMax set style attribute
            recentlyGradedAnswerBlock.removeAttribute('style')

        }, 205)

    }

    isExam() {
        return (
            this.model.get('type') === 'exam' ||
            this.model.get('type') === 'generated_exam' ||
            this.model.get('type') === 'diagnostic_exam'
        )
    }
}
