import Template from './ActivitySlot.hbs';
import SidebarUserSelectionView from 'views/pages/sections/show/userSelector/UserSelector';
import ProgressBar from 'views/components/progressBar/ProgressBar.svelte';
import ContentLabel from 'views/components/contentLabel/ContentLabel';
import ProgressBarUtil from 'views/components/progressBar/ProgressBarUtil';

export default BaseView.extend({

    events: {
        'click .js-activity-visible': 'onClickActivityVisible',
        'click .js-answers-visible': 'onClickAnswersVisible',
        'click .js-progress': 'onClickProgress',
        'click .js-progress-student': 'onClickProgressStudent',
        'click .js-goto-activity': 'onClickGoToActivity',
        'click .js-exam-progress': 'goToExamProgress',
        'click .js-exam-notifications': 'goToExamNotifications',
        'click .js-exam-points-scale': 'goToExamPointsScale',
        'click .js-exam-grades': 'goToExamGrades',
        'click .js-send-students': 'onClickSendStudents',
        'click .js-exam-grading': 'goToExamGrading',
        'click .js-deadline': 'onClickDeadline',
    },

    initialize() {

        _.bindAll(this,
            'onModelChange',
            'makePresentable'
        );

        const canToggleVisible = ['linear', 'adaptive', 'video', 'presentation', 'training'].includes(this.model.get('type'))
        const canToggleAnswers = ['linear'].includes(this.model.get('type'))

        this.setElement(Template({
            isAvailable: this.model.isAvailable(),
            productName: this.model.get('placed_from_product_name'),

            // Check if the progress is null
            progress: ((this.model.get('progress') === null) ?

                // Set to 0%
                0 :

                // Else calculate score
                Math.round(this.model.get('progress') * 100)) + '%',

            score: Math.round(this.model.get('score') * 100) + '%',
            scoreVisible: this.model.get('answers_visible') && _.isFinite(this.model.get('score')),
            finiteScore: _.isFinite(this.model.get('score')),
            answersVisible: this.model.get('answers_visible'),
            noTasks: this.model.get('progress') === null,
            tasksMade: (this.model.get('made') === null) ?
                0 : this.model.get('made'),
            ungradedAnswersText: this.model.getUngradedAnswersText(),
            type: this.model.get('type'),
            hasTypeBadge: this.model.get('type') !== 'linear' && this.model.get('type') !== 'adaptive_student',
            typeLabel: this.model.getActivityTypeLabel(),
            typeIcon: this.model.getActivityTypeIcon(),
            canToggleAnswers,
            canToggleVisible,
            hasExamGrade: !this.model.getIsExamCustomGradesDisabled() && _.isFinite(this.model.get('exam_grade')),
            examGrade: this.model.get('exam_grade'),
            examAttainablePoints: this.model.get('exam_attainable_points'),
            examAttainedPoints: this.model.get('exam_attained_points'),
            hasOpenExamSession: this.model.get('has_open_exam_session'),
            canViewProgress: ACL.isAllowed(ACL.resources.PROGRESS, ACL.actions.VIEW),
            deadline: this.model.deadline.getLabel()
        }));

        if (this.model.get('type') !== 'adaptive_student') {
            this.addChildView(new ContentLabel({
                model: this.model,
                hasNoLink: true,
                isCompact: true
            }), '.js-content-label')
        }

        // This seems like the incorrect condition, however it is correct. If you can't
        // see the progress screen, the user needs to see their progress elsewhere.
        // Therefore if progress is not allowed, show it here.
        if (!ACL.isAllowed(ACL.resources.PROGRESS, ACL.actions.VIEW)
            && ['linear', 'presentation', 'diagnostic_exam', 'adaptive'].includes(this.model.get('type'))
        ) {
            this.addSvelteChildView('.js-progress-bar', ProgressBar, {
                fragments: ProgressBarUtil.getStudentProgressFragments(
                    this.model.get('answers_visible'),
                    this.model.get('type') === 'adaptive' && this.model.get('made') !== null,
                    this.model.get('score'),
                    this.model.get('perc_checked'),
                    this.model.get('progress')
                )
            })
        }

        // Set up controls for changing visibility and answer visibility for teachers
        if (
            Backbone.Model.user.get('is_teacher') &&
            (canToggleVisible || canToggleAnswers)
        ) {
            this.listenTo(this.model, 'change', this.onModelChange);
            this.listenTo(this.model, 'change_metadata:answers_visible_on_completion', this.onModelChange)
            this.onModelChange();
        }

    },

    onClickGoToActivity() {
        if (
            // The presentation activity is not yet supported for teacher on mobile phones
            ISMOBILE &&
            Backbone.Model.user.get('is_teacher') &&
            this.model.get('type') === 'presentation'
        ) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Presentation activities are not yet supported on mobile for teachers'),
                'warning'
            );
        } else if (
            ISMOBILE &&
            (this.model.get('type') === 'exam' || this.model.get('type') === 'generated_exam' || this.model.get('type') === 'diagnostic_exam')
        ) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Exam activities are not yet supported on mobile'),
                'warning'
            );
        } else if (
            // if user is student and the activity is an exam and the answers are not visible, disallow the
            // user to go to this activity.
            (this.model.get('type') === 'exam' || this.model.get('type') === 'generated_exam') &&
            Backbone.Model.user.get('is_student') &&
            !this.model.get('answers_visible')
        ) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Exam activity is not available for review.'),
                'error'
            );
        } else if (

            /**
             * A diagnostic exam may not be opened if the
             * - answers are not visible AND
             * - there is no session for the diagnostic exam.
             */
            Backbone.Model.user.get('is_student') &&
            this.model.get('type') === 'diagnostic_exam' &&
            !this.model.get('has_open_exam_session') &&
            !this.model.get('answers_visible')
        ) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Diagnostic exam activity is not available for review.'),
                'error'
            );
        } else {

            Backbone.history.navigate('activities/show/' + this.model.get('id'), {
                trigger: true
            });
        }
    },

    /**
     * onClickActivityVisible
     *
     * When clicking the activity visibility button, show sidebar to select or unselect students
     * who are able the view the activity.
     *
     * @returns {boolean} stop further execution
     */
    onClickActivityVisible() {
        var sidebarUserSelection = new SidebarUserSelectionView({
            model: this.model,
            key: 'activity_visible',
            group_id: this.model.getGroupModel().get('id')
        });
        this.registerChildView(sidebarUserSelection);
        Backbone.View.sidebar.showSidebar(sidebarUserSelection, window.i18n.gettext('Activity visible'), 360);
        return false;
    },

    /**
     * onClickAnswersVisible
     *
     * When clicking the answer visibility button, show sidebar to select or unselect students
     * who are able the view the activity with answers visible.
     *
     * @returns {boolean} stop further execution
     */
    onClickAnswersVisible() {
        if (Backbone.Model.user.get('is_teacher')) {
            var sidebarUserSelection = new SidebarUserSelectionView({
                model: this.model,
                key: 'answers_visible',
                group_id: this.model.getGroupModel().get('id')
            });
            this.registerChildView(sidebarUserSelection);
            Backbone.View.sidebar.showSidebar(sidebarUserSelection, window.i18n.gettext('Answers visible'), 360);
            return false;
        }
    },

    /**
     * onClickProgress
     *
     * Go to the progress view of the current activity.
     */
    onClickProgress() {
        Backbone.history.navigate('/activities/progress/' + this.model.id, {
            trigger: true
        });
    },

    /**
     * onClickProgressStudent
     *
     * Shortcut for showing the sidebar listing the progress per task group inside the activity.
     */
    onClickProgressStudent() {
        Backbone.history.navigate('activities/show/' + this.model.id, {
            trigger: true
        });
    },

    onClickDeadline() {
        Backbone.history.navigate('/activities/progress/' + this.model.id, {
            trigger: true
        })
    },

    /**
     * onModelChange
     *
     * Reflect changes in the amount of students who are able to view the activity or
     * the activity with correct answers in the DOM.
     */
    onModelChange() {
        this.$('.js-activity-visible-amount').text(
            this.makePresentable(_.size(this.model.get('activity_visible')))
        )
        let answersAmount = this.makePresentable(_.size(this.model.get('answers_visible')))
        if (parseInt(this.model.getMetadata('answers_visible_on_completion')) === 1) {
            answersAmount += ', ' + window.i18n.gettext('visible on 100%')
        }
        this.$('.js-answers-amount').text(answersAmount)
    },

    /**
     * makePresentable
     *
     * Generate string that reflects if the amount of students is equal the number of
     * students in the group, zero or something in between.
     *
     * @param   {number} numberIn   student count
     * @returns {string}            representation of the student count
     */
    makePresentable(numberIn) {
        if (numberIn !== 0 && numberIn === this.model.getGroupModel().students.length) {
            return window.i18n.gettext('Whole class')
        }
        return window.i18n.sprintf(
            window.i18n.ngettext(
                '%d student',
                '%d students',
                numberIn
            ),
            numberIn
        )
    },

    goToExamProgress() {
        Backbone.Model.user.addSetting('activeActivityProgressViewMode', 'progress');
        Backbone.history.navigate('/activities/progress/' + this.model.id, {
            trigger: true
        });
    },

    goToExamNotifications() {
        Backbone.Model.user.addSetting('activeActivityProgressViewMode', 'notifications');
        Backbone.history.navigate('/activities/progress/' + this.model.id, {
            trigger: true
        });
    },

    goToExamGrading() {
        Backbone.Model.user.addSetting('activeActivityProgressViewMode', 'grading');
        Backbone.history.navigate('/activities/progress/' + this.model.id, {
            trigger: true
        });
    },

    goToExamGrades() {
        Backbone.Model.user.addSetting('activeActivityProgressViewMode', 'grades');
        Backbone.history.navigate('/activities/progress/' + this.model.id, {
            trigger: true
        });
    }
});
