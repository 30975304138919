import Styles from './SendNote.scss';

import Template from './SendNote.hbs';
import NoteModel from 'models/NoteModel';
import Wysiwyg from 'views/components/wysiwyg/Wysiwyg';
import QuickSelect from 'views/components/quickSelect/QuickSelect.svelte';
import Button from 'views/components/button/Button'
export default BaseView.extend({

    /**
     * @param {Object} options View options
     * @param {Backbone.View} options.parentView The view that instantiated this SendNote view
     *
     * @param {number} [options.recipient_user_id] user if of recipient
     * @param {Backbone.Model} [options.activity_model] activity model
     * @param {Backbone.View} [options.task_view] Task View
     * @param {string} [options.editorContent] Content to initialize feedback editor with
     * @param {boolean} [options.sendToWholeGroup] Send feedback to group separate from activity
     *
     */
    initialize({
        recipient_user_id,
        activity_model,
        task_view,
        parentView,
        editorContent,
        sendToWholeGroup,
        groupId,
    }) {

        this.bindAll([
            'onSendNote',
            'onSaveNote',
            'onSuccesReply'
        ])

        this.parentView = parentView

        this.sendToWholeGroup = sendToWholeGroup

        this.recipient = recipient_user_id

        this.recipient_name = this.recipient ?
            Backbone.Collection.students.get(this.recipient).first_name_last_name() :
            window.i18n.gettext('Whole class')

        /**
             * When sending feedback to a whole group that is not linked
             * to a specific activity/task, set activity_id and task_id
             * to 0 and provide a group_id
             */
        this.activity_id = activity_model ? activity_model.get('id') : 0
        this.task_id = task_view ? task_view.model.get('id') : 0

        if (task_view) {
            this.task_view = task_view
        }

        this.setElement(Template({
            Styles,
            recipient_name: this.recipient_name,
        }))

        this.addEditor(editorContent)

        this.sendButton = this.addChildView(new Button({
            label: window.i18n.gettext('Send'),
            inline: true,
            callback: this.model === undefined ? this.onSendNote : this.onSaveNote,
        }), '.js-send-button')

        // When creating or editing a group note, initialize the group selector
        if (sendToWholeGroup) {
            this.addGroupSelector(groupId)
        }
    },

    // Show QuickSelect with all the groups of the teacher.
    addGroupSelector(groupId) {

        const items = Backbone.Collection.groups.map(group => ({
            label: group.get('name'),
            value: group.get('id')
        }))

        this.groupSelector = this.addSvelteChildView('.js-group-selector', QuickSelect, {
            placeholder: window.i18n.gettext('Select a group'),
            items,
            defaultValue: groupId,
        })

    },

    /**
         * Attach editor and prefill with either earlier saved content
         * or unsaved draft from local storage.
         *
         * @param {string} editorContent Unsaved, previous editor content
         */
    addEditor(editorContent = null) {

        const initialEditorContent = this.model ? this.model.get('message') : editorContent

        this.editor = this.addChildView(new Wysiwyg({
            content: initialEditorContent,
            autoFocus: true,
            buttonsPreset: 'sendNote'
        }), '.js-message-input')

        /**
             * Notify sidebar that sidebarContent has wysiwyg editor with content
             * that needs to be persisted
             */

        this.hasEditor = true
        this.editorPropertyName = 'editor'
    },

    onSaveNote() {
        this.message = this.editor.getContent();

        if (!this.message) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('You did not enter a message.'),
                'info'
            )
            return
        }

        this.model.save({
            id: this.model.get('id'),
            message: this.message
        }, {
            success: this.onSuccesReply,
            patch: true
        });

        return false;
    },

    onSendNote() {

        if (Backbone.Model.user.get('is_demo')) {

            Backbone.View.layout.openStatus(
                window.i18n.gettext('Demo accounts are not allowed to send feedback'),
                'warning'
            )

            Backbone.View.sidebar.closeSidebar(null, true)
            return
        }

        if (this.groupSelector && this.groupSelector.getValue().item.value === -1) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('You did not select a group.'),
                'info'
            )
            return
        }

        this.message = this.editor.getContent()
        if (!this.message) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('You did not enter a message.'),
                'info'
            )
            return
        }

        // disable button and show spinner
        this.sendButton.disable(true)

        const noteModelOptions = {
            message: this.message,
            recipient_user_id: this.recipient,
            activity_id: this.activity_id,
            task_id: this.task_id,
            NoteReply: []
        }

        if (this.sendToWholeGroup) {
            noteModelOptions.group_id = this.groupSelector.getValue().item.value
        }

        const note = new NoteModel(noteModelOptions)
        note.save(null, {
            success: this.onSuccesReply,
            error: () => {
                this.sendButton.enable()
            }
        })

        return false
    },

    /**
         * @param {Backbone.Model} [model] newly created group note
         */
    onSuccesReply(model) {

        if (this.sendToWholeGroup) {

            model.changed.created ?
                this.parentView.displayNotes(model) :
                this.parentView.renderNotes()

        } else {
            this.parentView.displayNotes(model)
        }

        Backbone.View.sidebar.closeSidebar(null, true);

    },

});
