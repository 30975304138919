import Styles from './SupportMessage.scss';

import Template from './SupportMessage.hbs';
import Util from 'util/util';
// import UploadModel from 'models/UploadModel';
// import Button from 'views/components/button/Button'
import EmailInput from 'views/components/quickInput/emailInput/EmailInput'
import Textarea from 'views/components/textarea/Textarea.svelte'

export default BaseView.extend({

    initialize(options) {

        _.bindAll(
            this,
            // 'addUploadAttachmentButton',
            'onClickButton'
        );

        _.extend(this, options)

        // Add suggestions based on the type to help people on what to tell us
        let messagePlaceholder = ''
        let messageHint = ''
        switch (this.supportData.messageType) {
            case 'problem':
                messagePlaceholder = window.i18n.gettext('What problem do you have? When does it occur?')
                messageHint = window.i18n.gettext('Please send this message from the page where the problem occurs')
                break
            case 'content-mistake':
                messagePlaceholder = window.i18n.gettext('What mistake did you find? How can we correct it?')
                messageHint = window.i18n.gettext('Please send this message from the page where the content mistake occurs')
                break
            case 'idea':
                messagePlaceholder = window.i18n.gettext('What is you idea? Why do you want it?')
                messageHint = window.i18n.gettext('Please send this message from the page where the idea is about')
                break
        }

        let email = Backbone.Model.user.get('email') || ''
        if (Backbone.Model.user.get('is_demo')) {
            email = ''
        }

        // Create the modal element, passing the styling with it
        this.setElement(Template({
            Styles,
            messageHint
        }));

        this.emailInput = this.addChildView(new EmailInput({
            placeholder: window.i18n.gettext('Your e-mail address'),
            defaultValue: email,
            noMargin: true
        }), this.$el)

        this.messageInput = this.addSvelteChildView(
            this.el,
            Textarea,
            {
                placeholder: messagePlaceholder,
            },
        )

        // this.addUploadAttachmentButton();
    },

    // addUploadAttachmentButton() {

    //     if (this.attachmentButton) {
    //         this.attachmentButton.destroy();
    //     }

    //     this.attachmentButton = this.addChildView(
    //         new Button({
    //             icon: 'source-3',
    //             label: window.i18n.gettext('Add image or video'),
    //             inline: true,
    //             hasSpinner: true
    //         }),
    //         this.$('.js-attachment')
    //     );

    //     if (this.attachmentModel) {
    //         this.attachmentModel.destroy();
    //     }

    //     // Create a new upload model
    //     this.attachmentModel = new UploadModel({
    //         element: this.attachmentButton.$el,
    //         uploadType: 'support-attachment',
    //         accept: 'image/*, .mp4, .mov, video/*',
    //     });

    //     this.attachmentModel.on('fileIsChosen', () => this.attachmentButton.toggleSpinner(true));
    //     this.attachmentModel.on('change:status', () =>
    //         (this.attachmentModel.get('status') === 'error') && this.attachmentButton.toggleSpinner(false)
    //     );
    //     this.attachmentModel.on('change:url', () => {
    //         const original = this.attachmentModel.get('original');

    //         this.attachmentButton.rerender({
    //             prefix: original.fileName,
    //             inline: true,
    //             icon: 'trash',
    //             theme: 'icon-only',
    //             callback: this.addUploadAttachmentButton
    //         });

    //         this.attachmentButton.$('.js-prefix').on('click', () =>
    //             window.open(this.attachmentModel.get('url'), '_blank')
    //         );
    //     });
    // },

    addButtons() {
        // Create a buttons object for holding buttons for modal
        var buttons = {
            // Add the cancel button
            [window.i18n.gettext('Cancel')]: {
                callback: Backbone.View.Components.modal.close,
                theme: 'secondary',
            },

            // Add the send button
            [window.i18n.gettext('Send')]: {
                callback: this.onClickButton,
                bindButtonFunction: buttonView => this.sendButton = buttonView
            }
        }

        // Add the buttons to the modal
        Backbone.View.Components.modal.addButtons(buttons, true);
    },

    /**
     * onClickButton
     *
     * Function which will handle the logic for when the user has clicked on the send button.
     * It will gather information about the user and their system and create a message which
     * will be send to the support mailbox by the backend.
     *
     */
    onClickButton() {

        const email = this.emailInput.getInput()
        const message = this.messageInput.getValue()

        if (!message) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('You did not provide a message.'),
                'error'
            )
            return
        }

        if (!this.emailInput.validateEmail(email)) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('You did not provide a valid email address.'),
                'error'
            )
            return
        }

        // Create the object to send through email
        const objectToSend = {

            // Distill the browser using the UA Parser
            browser: window.uaparser.getBrowser(),

            // Distill the device using the UA Parse
            device: window.uaparser.getDevice(),

            // Distill the engine using the UA Parse
            engine: window.uaparser.getEngine(),

            // Distill the OS using the UA Parse
            OS: window.uaparser.getOS(),

            // Distill the CPU using the UA Parse
            CPU: window.uaparser.getCPU(),

            // Check if the user has support for localstorage
            hasLocalStorageSupport: Util.hasSupportForLocalstorage(),

            // Show contents of local storage for students (for debugging missing answers)
            localstorage: (Backbone.Model.user.get('is_student') && Util.hasSupportForLocalstorage()) ?
                JSON.stringify(window.localStorage) : 'not available',

            // Attach the message from message input
            message,

            // Attach email from the e-mail input
            email,

            // Attach the url location where the user is now active
            current_url: Backbone.history.location.href,

            // Add the user data (attributes)
            user: Backbone.Model.user.attributes,

            // Add the version of the application
            frontendVersion: VERSIONHASH,

            // Add optional context-specific additional data.
            additionalData: this.supportData.additionalData,

            // Type is selected type, or when empty get from the selected tab
            type: (

            // Check if the supportData can be found within this object
            // and if there is a messageType
                (this.supportData !== undefined && this.supportData.messageTypeLabel !== undefined) ?

                    // Use the messagetype
                    this.supportData.messageTypeLabel :

                    // When not found use the string 'Onbekend'
                    'Onbekend'
            )
        };

        // Add author name and user id if user is viewing a task group in work_on
        if (/activities\/show\/\d*\/\d*/.test(window.location.pathname)) {
            const match = window.location.pathname.match(/activities\/show\/\d*\/(\d*)/)
            const taskGroup = Backbone.Model.user.currentOpenActivity.task_groups.get(match[1])

            objectToSend.taskGroupAuthor = taskGroup?.get('user_id')
        }

        // Check if the user has successfully added an attachment. If this is
        // the case. Append the url of the attachment to the to be sended message.
        // this fixes: LEARN-683
        // if (this.attachmentModel.get('status') === 'success') {
        //     object_to_send.message =
        //             `${object_to_send.message}\n\nBijlage:\n${this.attachmentModel.get('url')}`
        // }

        // Trigger the spinner on the sendbutton
        this.sendButton.disable(true);

        // Send the data to the backend, so it can send an e-mail to support
        $.ajax({
            type: 'POST',
            url: '/users/send_feedback.json',
            data: objectToSend,
            success: () => {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext(
                        'Thank you for your response. ' +
                        'We are going to get to work as soon as possible and get back to you!'
                    ),
                    'success'
                )
                Backbone.View.Components.modal.close()
            },
            error: () => {
                this.sendButton.enable()
            }
        });

    }

});
