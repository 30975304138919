import Styles from './Notes.scss';

import Template from './Notes.hbs';
import NoteView from 'views/components/notes/Note';
import StatusCard from 'views/components/statusCard/StatusCard';
import NotesCollection from 'collections/NotesCollection';
import HeroButton from 'views/components/heroButton/HeroButton'
import SendNoteSidebarView from 'views/components/notes/sidebar/SendNote'

export default BaseView.extend({

    initialize() {

        this.bindAll([
            'onLoadNotes',
            'showAndLoaded',
            'sendNoteToGroup',
        ])

        this.setElement(Template({Styles}));

        this.notes = new NotesCollection();

        // re-render notes when a Group note is added /users/notes
        this.listenTo(this.notes, 'add remove', model => {

            // check if something is actually removed or added
            if (!Object.keys(model.changed).length) {
                return
            }

            this.renderNotes()
        })

        this.showHeroButtons = Backbone.Model.user.get('is_teacher') && !ISMOBILE

        this.emptyStateCard = this.addChildView(new StatusCard({
            icon: 'info-circle',
            cardContent: Backbone.Model.user.get('is_teacher') ?
                window.i18n.gettext('You have not sent any feedback yet.') :
                window.i18n.gettext('You have not received any feedback yet.')
        }), '.js-content')
        this.emptyStateCard.$el.hide()

        this.notes.fetch({
            success: this.onLoadNotes,
            error: this.onErrorLoadingNotes
        });

        Backbone.View.header.clearButtons()
        Backbone.View.header.setTitle(window.i18n.gettext('Feedback'))
        Backbone.View.header.setCrumblepath()

    },

    show() {
        if (this.showHeroButtons) {
            this.addHeroButtons()
        }

        this.showAndLoaded();
    },

    onLoadNotes() {
        this.showAndLoaded();
    },

    onErrorLoadingNotes() {
        Backbone.View.layout.openStatus(
            window.i18n.gettext('Something went wrong while loading notes')
        );
    },

    showAndLoadedCounter: 0,

    showAndLoaded() {
        this.showAndLoadedCounter++;

        if (this.showAndLoadedCounter !== 2) {
            return
        }

        this.renderNotes()

        Backbone.View.menubar.setNewNotes(0);

    },

    renderNotes() {
        /**
         * Remove earlier created notes when re-rendering
         */
        if (this.renderedNoteViews) {
            this.renderedNoteViews.forEach(view => view.destroy())
        }

        this.renderedNoteViews = this.notes.map(note => {
            return this.addChildView(new NoteView({
                model: note,
                parentView: this,
            }), '.js-content')
        })

        /**
         * Display empty state if necessary
         */
        if (this.notes.length && this.emptyStateCard.$el.is(':visible')) {

            this.emptyStateCard.$el.hide()

        } else if (!this.notes.length && !this.emptyStateCard.$el.is(':visible')) {

            this.emptyStateCard.$el.show()

        }

    },

    addHeroButtons() {
        Backbone.View.header.addButton(
            new HeroButton({
                firstLine: window.i18n.gettext('Send message to a group'),
                callback: this.sendNoteToGroup,
                icon: 'feedback',
            })
        )
    },

    /**
     * Send feedback to whole group without being attached to a task
     */
    sendNoteToGroup() {

        this.sendNoteSidebar = new SendNoteSidebarView({
            // value 0 = whole class
            recipient_user_id: 0,
            parentView: this,
            sendToWholeGroup: true,
        })

        Backbone.View.sidebar.showSidebar(this.sendNoteSidebar, window.i18n.gettext('Send feedback'), 630)
    },

    /**
     *
     * @param {Backbone.Model} model
     * The model of a newly created note. This function is called when a note is successfully
     * created in sendNoteSidebar. unshift is used to display newest note first
     */
    displayNotes(model) {

        this.notes.unshift(model)

    },

});
