import Template from './GoogleDrive.hbs';
import Styles from 'views/components/taskGroups/tasks/template21/Template21.scss'
import LoadExternalStaticResources from 'util/LoadExternalStaticResources';

export default class GoogleDrive extends BaseView {

    get extensionIcon() { return 'google-drive'; }
    get extensionName() { return 'Google Drive'; }
    get extensionTrackName() { return 'template21/extensions/' + this.extensionName; }

    get isGoogleAPIReady() { return !!window.gapi; }
    get isGoogleAuthReady() { return !!this.GAPI.auth2; }
    get isGooglePickerReady() { return !!this.GAPI.picker; }
    get isGoogleClientReady() { return !!this.GAPI.client; }

    get GAPI() { return (this.isGoogleAPIReady && window.gapi) || {} }
    get Auth() { return (this.isGoogleAuthReady && this.GAPI.auth2) || {} }
    get Picker() { return (this.isGooglePickerReady && this.GAPI.picker) || {} }
    get Client() { return (this.isGoogleClientReady && this.GAPI.client) || {} }

    get GoogleLocaleKey() {
        switch (LANGUAGE) {
            case 'nl_NL':
                return 'nl';
            case 'it_IT':
                return 'it';
            case 'de_DE':
                return 'de'
            case 'es_ES':
                return 'es'
            case 'en_US':
            default:
                return 'en';
        }
    }

    /**
     * initialize
     *
     * Creates an instance of GoogleDrive.
     *
     * @param {*} {
     *  parent       {object} parentview as object,
     *  authOptions  {object} object to authenticate for Google API,
     *  mimeTypes    {array}  array with allowed mime types,
     *  query        {string} string to search for
     * }
     * @memberof GoogleDrive
     */
    initialize({ parent, authOptions, mimeTypes, query}) {
        this.openPickerCounter = 0;
        this.authOptions = authOptions;
        this.mimeTypes = mimeTypes;
        this.query = query;
        this.parent = parent;

        _.bindAll(this,
            'shareFile',
            'createPicker',
            'disableCounter',
            'onLoadGoogleAPI',
            'onClickDriveButton'
        );

        // Scope the onPickFile method with the parent object
        this.onPickFile = this.onPickFile.bind(parent);

        this.setElement(Template({
            Styles,
            extensionIcon: this.extensionIcon,
            extensionName: this.extensionName,
        }));

        this.LESR = LoadExternalStaticResources();

        this.LESR.addResource({
            identifier: 'GoogleSDK',
            url: '//apis.google.com/js/api.js',
            type: 'javascript'
        }, () => {
            (!this.isGoogleAuthReady || !this.isGooglePickerReady)
                ? this.onLoadGoogleAPI()
                : this.addDriveButton()
        });
    }

    /**
     * loadExternalDrivePickerAPI
     *
     * This method will load the external Google API's by injecting a script-tag
     * to Learnbeat.
     *
     * @memberof GoogleDrive
     */
    loadExternalDrivePickerAPI() {
        const scriptTag = document.createElement('script');
        scriptTag.src = '//apis.google.com/js/api.js';
        scriptTag.onload = this.onLoadGoogleAPI;
        document.body.append(scriptTag);
    }

    /**
     * onLoadDrivePicker
     *
     * This method will be called when the Google API is loaded
     *
     * @memberof GoogleDrive
     */
    onLoadGoogleAPI() {
        this.GAPI.load('auth2');
        this.GAPI.load('picker');
        this.GAPI.load('client');

        this.addDriveButton();
    }

    /**
     * addDriveButton
     *
     * Method to make the drive button visible and attach the correct
     * click listener
     *
     * @memberof GoogleDrive
     */
    addDriveButton() {
        this.$el
            // Prevent the user from clicking multiple times at the button and
            // opening multiple oauth popups
            .one('click', this.onClickDriveButton)
            .css({ display: 'flex' });
    }

    /**
     * onClickDriveButton
     *
     * This method will be called when the users clicks on the
     * select from drive button.
     *
     * @returns {boolean} false to stop further execution
     * @memberof GoogleDrive
     */
    onClickDriveButton() {

        if (Backbone.Model.user.get('is_teacher')) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('As teacher it isn\'t possible to upload a file.'),
                'info'
            );

            this.addDriveButton();

            return false;
        }
        if (this.parent.lockedForScoring) {
            this.parent.task_view.openScoreFirstMessage();
            return false;
        }

        window.statsTracker.trackEvent(
            this.extensionTrackName,
            'Click Drive button'
        );

        // If Google tools aren't ready yet, prevent further execution
        if (!this.isGoogleAPIReady || !this.isGoogleAuthReady || !this.isGooglePickerReady) {
            console.error('GAPI is not available yet', {
                APIReady: this.isGoogleAPIReady,
                AuthReady: this.isGoogleAuthReady,
                PickerReady: this.isGooglePickerReady
            });
            return false;
        }

        this.Auth.authorize({
            ...this.authOptions
        }, ({ error, access_token }) => {
            if (error) {
                window.statsTracker.trackEvent(
                    this.extensionTrackName,
                    'Auth error',
                    error
                );

                // Now the user completed the OAuth flow, reattach click listener to
                // the button. So the user can click on it again
                this.addDriveButton();

                return false;
            }

            this.createPicker(access_token);
        });
    }

    /**
     * disableCounter
     *
     * This method will set the counter to inifity,
     * therefor it will never match the check in the
     * createPicker method. And thereby disables the
     * 3rd party cookie check.
     *
     * @memberof GoogleDrive
     */
    disableCounter() {
        this.openPickerCounter = Infinity;
    }

    /**
     * createPicker
     *
     * Method to create and open the Drive picker.
     *
     * @param {string} oauthToken token to authenticate to Google
     * @memberof GoogleDrive
     */
    createPicker(oauthToken) {

        this.oauthToken = oauthToken;

        // Now the user completed the OAuth flow, reattach click listener to
        // the button. So the user can click on it again
        this.addDriveButton();

        if (++this.openPickerCounter === 3) {
            this.openPickerCounter = 0;

            // For now we're adding a Matomo tracker just to count how much
            // this occurs. If there are a lot of students that reach this
            // point or if support gets a lot of e-mails we should add a
            // status message here that links the user to a support documentation
            // about blocking 3rd party cookies and popups which is probably
            // why the user clicks three times (since it doesn't work)
            window.statsTracker.trackEvent(
                this.extensionTrackName,
                'Frustrated (3x click)'
            );
        }

        const { google } = window;
        const { appId, developerkey } = this.authOptions;

        // Create a Google picker view, using the DOCS id. More ids can be found here:
        // https://developers.google.com/picker/docs/reference#view-id
        const view = new google.picker.DocsView();
        view.setMode(google.picker.DocsViewMode.LIST);
        this.mimeTypes && view.setMimeTypes(this.mimeTypes);
        this.query && view.setQuery(this.query);

        // Documentation about pickerbuilder can be found here:
        // https://developers.google.com/picker/docs/reference#picker-builder
        const Picker = new google.picker.PickerBuilder()
            // TODO: Filter files with query for 'owner' and 'write' permissions
            // See:
            //  https://developers.google.com/picker/docs/reference#view
            //  https://developers.google.com/drive/api/v3/ref-search-terms#file_properties
            // For now:
            //  Show Only my own files, else the worker can crash by giving permission
            //  this will be kept for a next revision of the Drive project
            .enableFeature(google.picker.Feature.MINE_ONLY)
            .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
            .hideTitleBar()
            .setLocale(this.GoogleLocaleKey)
            .addView(view)
            .setOAuthToken(oauthToken)
            .setAppId(appId)
            .setDeveloperKey(developerkey)
            .setCallback((data) => this.onPickFile(data, this))
            .build();

        Picker.setVisible(true);
    }

    /**
     * onPickFile
     *
     * Callback for when the user picks a file
     *
     * NOTE: This method is scoped with the Template21 view object
     *
     * @param {object} data object created by picker api of Google
     * @param {object} extension this extension class
     * @memberof GoogleDrive
     */
    onPickFile({
        action,
        docs
    }, {
        Client,
        oauthToken,
        extensionName,
        disableCounter,
        shareFile
    }) {
        const { google } = window;
        const permission = {
            role: 'writer',
            type: 'anyone'
        };

        if (action === google.picker.Action.PICKED) {

            window.statsTracker.trackEvent(
                this.extensionTrackName,
                'Added file(s)',
                docs.length
            );

            // Disable the 3rd party message since the user succeeded in picking a
            // file we can asume it works for the user (for this session)
            // Maybe next time the user is using a different browser so we won't save
            // it within the user settings
            disableCounter();

            if (docs && docs instanceof Array) {
                const filePromises = docs.map(({
                    id,
                    url,
                    name,
                    mimeType: fileType
                }) => new Promise((resolve, reject) => {

                    const options = {
                        id,
                        url,
                        fileType,
                        extensionName,
                        isExtensionFile: true,
                        date: new Date().toISOString()
                    }

                    const alreadyAdded = this.getFileblockByIdentifier(id);
                    let fileBlock = false;

                    if (!alreadyAdded) {
                        fileBlock = this.renderFileBlock(options, name);
                        fileBlock.addSpinner();
                    }

                    shareFile(Client, {
                        path: `/drive/v3/files/${id}/permissions`,
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${oauthToken}`
                        },
                        body: permission
                    }, (isFailed, response) => {
                        if (isFailed) {
                            reject({ fileBlock, options, response });
                        } else {
                            resolve({ fileBlock, options });
                        }
                    });
                }));

                Promise.allSettled(filePromises).then((results) => {

                    this.responseObject = Object.assign({}, this.responseObject);
                    let alreadyAddedFiles = 0;

                    results.forEach(({ status, value, reason }) => {

                        // Fix for: LEARN-1078
                        // Whenever a request has been rejected/failed. The info of
                        // the file will be stored within the reason property. When
                        // a promise is resolved it will be stored in the value
                        // property. So use value, and when not set use reason
                        // for a valid destruction.
                        const { fileBlock, options } = value || reason;

                        if (fileBlock === false) {

                            // If fileBlock is false, the answer was already added
                            alreadyAddedFiles++;

                            // Stop further execution
                            return;
                        }

                        fileBlock.removeSpinner();

                        if (status === 'rejected') {
                            options.sharingFailed = true;
                            fileBlock.showSharingFailedMessage();
                        }

                        this.responseObject[fileBlock.name] = options;
                    });

                    if (results.length === alreadyAddedFiles && results.length !== 0) {
                        Backbone.View.layout.openStatus(
                            window.i18n.gettext('This file is already added'),
                            'info'
                        );

                        // Prevent saving response. Because nothing new
                        return;
                    }

                    if (alreadyAddedFiles > 0) {
                        Backbone.View.layout.openStatus(
                            window.i18n.gettext('This file is already added'),
                            'info'
                        );
                    }

                    this.task_view.saveResponse(this.responseObject);
                });
            }
        }
    }

    /**
     * Method for executing sharing a file. We do this in
     * a wrapper so we can retry it again in certain conditions
     *
     * @param {Object} Client client object as provided by Google
     * @param {Object} requestData Object containg request data
     * @param {Function} done Callback to call after finishing
     * @memberof GoogleDrive
     */
    shareFile(Client, requestData, done) {
        Client.request(requestData).execute((response) => {
            if (response && response.error) {

                // If there is a 403 error and we haven't tried sharing in domain
                // yet, we should retry to share the file within the domain. This
                // will allow schools to hand in files which have a share outside
                // domain restriction set.
                if (response.error.code === 403
                    && requestData.body.type !== 'domain'
                ) {
                    // Modify the 'type' to 'domain'. More info:
                    // https://developers.google.com/drive/api/v3/reference/permissions#resource-representations
                    requestData.body.type = 'domain';
                    this.shareFile(Client, requestData, done);
                } else {
                    done(true, response);
                }
            } else {
                done();
            }
        });
    }
}