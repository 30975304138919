import Styles from './SendStudentSidebar.scss';

import Template from './SendStudentSidebar.hbs';
import Button from 'views/components/button/Button'
import Chooser from 'views/components/chooser/Chooser'

export default class SendStudentSidebar extends BaseView {

    initialize({
        callback,
        buttonLabel,
    }) {

        this.setElement(Template({
            Styles
        }));

        const studentList = this.model.getGroupModel().students.map(model => {
            return {
                model,
                isChecked: true,
            }
        })
        this.addChildView(
            this.chooser = new Chooser({
                list: studentList,
                isMultiSelect: true,
                showSelectAllInput: true,
                selectAllText: window.i18n.gettext('Whole class'),
                useContentLabel: true,
                style: 'sidebar'
            }),
            '.js-student-list'
        )

        this.addChildView(
            new Button({
                label: buttonLabel,
                callback: () => {
                    this.onClickSendStudents(callback)
                },
            }), '.js-send-btn'
        );
    }

    /**
     * onClickSendStudents
     *
     * This function handles sending the selected students to the activity.
     * @param {Function|undefined} callback optional function to call after activity_visible has been updated.
     */
    onClickSendStudents(callback) {

        const selectedStudentsIdsArray = this.chooser.getSelection().map(student => student.model.id)

        if (!selectedStudentsIdsArray.length) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Select at least 1 student.')
            )
            return
        }

        // Merge the selected students array with the activity visible array in the activity model
        // and remove duplicates
        const activityVisibleArray = _.uniq(
            this.model.get('activity_visible').concat(selectedStudentsIdsArray)
        )

        // Do post call to backend, setting the activity to visible
        // by passing the array of selectedStudentsIdsArray
        this.model.savePatchWithKeyToServer('activity_visible', activityVisibleArray, () => {
            Backbone.Model.user.sendSocketEvent(
                selectedStudentsIdsArray,
                'go-to-url',
                Backbone.history.fragment
            )

            if (callback) {
                callback(this.chooser.getSelection())
            }
        })

        Backbone.View.sidebar.closeSidebar()
    }

}
