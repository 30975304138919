var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "is-blue";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n            <div>\n                <div class=\"tile__content__icon tile__content__icon\">"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias1,"exercises",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":7,"column":69},"end":{"line":7,"column":92}}})) != null ? stack1 : "")
    + "</div>\n                <div class=\"tile__content__title\">"
    + container.escapeExpression(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias1,"Practice by yourself",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":8,"column":50},"end":{"line":8,"column":79}}}))
    + "</div>\n            </div>\n\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "\n            <div class=\"js-content-label\"></div>\n\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n            <div class=\"tile__content__type\">\n                "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"typeIcon") : depth0),{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":20,"column":36}}})) != null ? stack1 : "")
    + "\n                "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"typeLabel") : depth0), depth0))
    + "\n            </div>\n\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"canViewProgress") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":39,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isStudent.js")).call(alias1,{"name":"isStudent","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":12},"end":{"line":85,"column":26}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isTeacher.js")).call(alias1,{"name":"isTeacher","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":87,"column":12},"end":{"line":103,"column":26}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"noTasks") : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":12},"end":{"line":110,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isInList.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"presentation","training",{"name":"isInList","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":12},"end":{"line":114,"column":25}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isInList.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"exam","diagnostic_exam","generated_exam",{"name":"isInList","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":12},"end":{"line":126,"column":25}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deadline") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":12},"end":{"line":132,"column":19}}})) != null ? stack1 : "")
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isNotInList.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),"adaptive_student","exam","diagnostic_exam","generated_exam",{"name":"isNotInList","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":16},"end":{"line":38,"column":32}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"noTasks") : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":20},"end":{"line":37,"column":31}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./activityPartials/ActivityProgressBar.hbs"),depth0,{"name":"activityPartials/ActivityProgressBar","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isEqual.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"competencies",{"name":"isEqual","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":16},"end":{"line":45,"column":28}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isEqual.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"lti",{"name":"isEqual","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":16},"end":{"line":49,"column":28}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isEqual.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"adaptive_student",{"name":"isEqual","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":51,"column":16},"end":{"line":83,"column":28}}})) != null ? stack1 : "")
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./activityPartials/CompetenciesScore.hbs"),depth0,{"name":"activityPartials/CompetenciesScore","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./activityPartials/LtiScore.hbs"),depth0,{"name":"activityPartials/LtiScore","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    return "\n\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"noTasks") : depth0),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":20},"end":{"line":81,"column":31}}})) != null ? stack1 : "")
    + "\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isInList.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),"exam","diagnostic_exam","generated_exam",{"name":"isInList","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":24},"end":{"line":79,"column":37}}})) != null ? stack1 : "")
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = container.invokePartial(require("./activityPartials/ExamGrade.hbs"),depth0,{"name":"activityPartials/ExamGrade","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"canToggleVisible") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":16},"end":{"line":97,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isInList.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"adaptive","adaptive_student",{"name":"isInList","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":16},"end":{"line":101,"column":29}}})) != null ? stack1 : "")
    + "\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n                    <div class=\"js-activity-visible tile__navigation__btn\">\n                        "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias1,"eye",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":92,"column":24},"end":{"line":92,"column":41}}})) != null ? stack1 : "")
    + "\n                        <span>"
    + container.escapeExpression(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias1,"Visible",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":93,"column":30},"end":{"line":93,"column":46}}}))
    + "</span>\n                        <strong class=\"js-activity-visible-amount\"></strong>\n                    </div>\n\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./activityPartials/ProgressBtn.hbs"),depth0,{"name":"activityPartials/ProgressBtn","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"canToggleAnswers") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":16},"end":{"line":109,"column":23}}})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./activityPartials/AnswersVisibleBtn.hbs"),depth0,{"name":"activityPartials/AnswersVisibleBtn","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./activityPartials/ProgressBtn.hbs"),depth0,{"name":"activityPartials/ProgressBtn","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./activityPartials/ProgressBtn.hbs"),depth0,{"name":"activityPartials/ProgressBtn","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isTeacher.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isTeacher","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(38, data, 0),"data":data,"loc":{"start":{"line":117,"column":16},"end":{"line":125,"column":30}}})) != null ? stack1 : "");
},"36":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = container.invokePartial(require("./activityPartials/TeacherExamToolbar.hbs"),depth0,{"name":"activityPartials/TeacherExamToolbar","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = container.invokePartial(require("./activityPartials/AnswersVisibleBtn.hbs"),depth0,{"name":"activityPartials/AnswersVisibleBtn","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = container.invokePartial(require("./activityPartials/Deadline.hbs"),depth0,{"name":"activityPartials/Deadline","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = container.invokePartial(require("./activityPartials/LockedActivity.hbs"),depth0,{"name":"activityPartials/LockedActivity","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tile "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isEqual.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"adaptive_student",{"name":"isEqual","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":17},"end":{"line":1,"column":72}}})) != null ? stack1 : "")
    + " js-activity-tile\">\n    <button class=\"tile__content js-goto-activity\">\n\n"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isEqual.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"adaptive_student",{"name":"isEqual","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":15,"column":20}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasTypeBadge") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "    </button>\n\n    <div class=\"tile__navigation\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAvailable") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(42, data, 0),"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":138,"column":15}}})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});