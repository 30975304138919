import Template from './SectionSlot.hbs';
import ProgressBar from 'views/components/progressBar/ProgressBar.svelte';
import ContentLabel from 'views/components/contentLabel/ContentLabel';

export default BaseView.extend({

    initialize(options) {
        // Default the progress to 0
        var progress = options.progress || 0;
        var hasProgress = _.isFinite(options.progress)

        // Set the element for this view
        this.setElement(Template({
            id: this.model.id,
            progress: Math.round(progress * 100) + '%',
            hasProgress,
            canViewProgress: ACL.isAllowed(ACL.resources.PROGRESS, ACL.actions.VIEW)
        }));

        this.addChildView(new ContentLabel({
            model: this.model,
            hasNoLink: true
        }), '.js-content-label')

        // This seems like the incorrect condition, however it is correct. If you can't
        // see the progress screen, the user needs to see their progress elsewhere.
        // Therefore if progress is not allowed, show it here.
        if (!ACL.isAllowed(ACL.resources.PROGRESS, ACL.actions.VIEW)) {
            this.addSvelteChildView('.js-progress-bar', ProgressBar, {
                fragments: {
                    blue: progress
                }
            })
        }

    },

});
