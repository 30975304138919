import Template from './Layout.hbs';
import Menubar from 'views/components/menu/menubar/Menubar';
import PageSpinner from 'views/components/pageSpinner/PageSpinner';
import SidebarView from 'views/components/sidebar/Sidebar';
import SupportModalCategoryPicker from 'views/components/modals/support/selectCategory/SelectCategory'
import SupportModalMessageForm from 'views/components/modals/support/supportMessage/SupportMessage'
import Modal from 'views/components/modals/Modal';
import Confirm from 'views/components/modals/confirm/Confirm';
import Alert from 'views/components/modals/alert/Alert';
import GroupChooser from 'views/components/modals/groupChooser/GroupChooser'
import Theory from 'views/components/fullscreen/theory/Theory';
import ActionbuttonView from 'views/components/actionbutton/Actionbutton';
import TourToolTip from 'views/components/tourToolTip/TourToolTip';
import ReEnterPasswordModal from 'views/components/modals/reEnterPassword/ReEnterPassword';
import StatusMessage from 'views/components/statusMessage/StatusMessage'
import Keyboard from 'views/components/keyboard/Keyboard';
import VideoTour from 'views/components/videoTour/VideoTour';
import 'jquery-ui-touch-punch';
import Header from 'views/components/header/Header';

export default class Layout extends BaseView {

    initialize(rootNode) {

        _.bindAll(
            this,
            'openStatus',
            '_showView',
            'openDemoVideoTour',
            'openDemoVideoMessage'
        );

        this.setElement(rootNode);

        // Destroy all childviews of layoutview
        this.destroyChildViews();

        // TODO: Remove this line, if all childviews are registered correctly they
        // will be destroy with the destroyChildViewsOfInstance method.
        this.$el.empty();
        this.$el.append(Template({}));

        // sidebar
        this.sidebar = new SidebarView();
        this.registerChildView(this.sidebar);
        this.$el.append(this.sidebar.$el);
        Backbone.View.sidebar = this.sidebar;

        // Start components creation
        Backbone.View.Components = {};

        // actionbutton
        this.actionbutton = new ActionbuttonView();
        this.registerChildView(this.actionbutton);
        this.$el.append(this.actionbutton.$el);
        Backbone.View.Components.actionbutton = this.actionbutton;

        // Include fullscreen modal component
        Backbone.View.Components.fullscreen = new Modal({isFullscreen: true})
        this.registerChildView(Backbone.View.Components.fullscreen)
        this.$el.append(Backbone.View.Components.fullscreen.$el)

        // Include the modal component
        Backbone.View.Components.modal = new Modal({});
        this.registerChildView(Backbone.View.Components.modal);
        this.$el.append(Backbone.View.Components.modal.$el);

        // Include on screen keyboard
        Backbone.View.Components.keyboard = new Keyboard({});
        this.registerChildView(Backbone.View.Components.keyboard);
        this.$el.append(Backbone.View.Components.keyboard.$el);

        // Include the page spinner component
        Backbone.View.Components.pageSpinner = new PageSpinner();
        this.registerChildView(Backbone.View.Components.pageSpinner);
        this.$el.append(Backbone.View.Components.pageSpinner.$el);

        // container
        this.container = this.el.querySelector('.js-application-container')

        Backbone.View.layout = this;

        if (ISREVIEWAPP || window.backendRAInfo) {
            import(
                /* webpackChunkName: "reviewapp-info" */
                'views/components/reviewAppInfo/ReviewAppInfo'
            ).then(({default: ReviewAppInfo}) => {
                this.addChildView(
                    new ReviewAppInfo(),
                    this.$el
                );
            });
        }
    }

    onUserIsLoggedIn() {

        this.createHeader()
        this.createMenubar();

        // In some cases, users see a modal or message after (first) login
        if (Backbone.Model.user.get('is_demo')) {
            this.openDemoVideoMessage()
        } else if (Backbone.Model.user.get('is_teacher')) {
            if (Backbone.Model.user.get('hasPaidProducts')) {
                this.openStartOfSchoolYearModal()
            } else {
                this.openPilotModal()
            }
        }

    }

    // Show a modal with onboarding information for new teachers. The modal shows only once.
    openStartOfSchoolYearModal() {
        if (Backbone.Model.user.getSetting('hasSeenStartOfTheYearModal') || LANGUAGE !== 'nl_NL') {
            return
        }

        import(
            /* webpackChunkName: "popup-startofyear" */
            'views/pages/popups/StartOfSchoolYear/StartOfSchoolYearModal.js'
        ).then(StartOfSchoolYearModal => {
            Backbone.View.Components.modal.open(StartOfSchoolYearModal.default, {
                title: 'Welkom bij Learnbeat!',
            })

            // set user setting to ensure teacher only sees modal once
            Backbone.Model.user.addSetting('hasSeenStartOfTheYearModal', 1)
        })
    }

    // Show a modal to request a pilot for teachers of schools with no products.
    openPilotModal() {
        import(
            /* webpackChunkName: "signup-pilot" */
            'views/pages/products/modals/pilotModal/PilotModal'
        ).then(PilotModal => {
            Backbone.View.Components.modal.open(PilotModal.default, {
                title: window.i18n.gettext('Welcome to Learnbeat!'),
            })
        })
    }

    openDemoVideoMessage() {
        if (ISMOBILE) {
            return
        }

        Backbone.View.layout.openStatus(
            window.i18n.gettext('Watch the introduction videos'),
            'videoTour',
            {
                noHide: true,
                mustReappear: true,
                elementCallback: this.openDemoVideoTour,
                buttonCallback: () => {}
            }
        )
    }

    onUserIsLoggedOut() {

        // Also trigger an event so individual views can react to it
        this.trigger('userIsLoggedOut');

        // Hide and destroy main navigation.
        this.destroyMenubar();
        this.destroyHeader()

        // Remove login modal.
        this.closeReEnterPasswordModal()

        this.closeStatus();
    }

    createMenubar() {
        this.destroyMenubar();
        this.menubar = this.addChildView(new Menubar(), this.$el, 'prepend');
        Backbone.View.menubar = this.menubar;
    }

    createHeader() {
        this.destroyHeader()
        Backbone.View.header = this.addChildView(new Header(), this.$el, 'prepend', true)
    }

    destroyMenubar() {
        if (this.menubar) {
            this.unregisterAndDestroyChildView(this.menubar);
            this.menubar = undefined;
            Backbone.View.menubar.destroy();
            Backbone.View.menubar = undefined;
        }
    }

    destroyHeader() {
        if (!Backbone.View.header) {
            return
        }

        Backbone.View.header.destroy()
        Backbone.View.header = undefined
    }

    openTheory(theory_collection_id, theory_branch_id, source_id) {
        Backbone.View.Components.fullscreen.open(Theory, {
            theory_collection_id,
            theory_branch_id,
            source_id,
        });
    }

    /**
     * addTourTooltip
     *
     * This functions open a tour tooltip that points towards a certain element in the DOM.
     *
     * @param  {Object} options - options passed in the constructor.
     * @return {type} - Returns the constructed view.
     */
    addTourTooltip(options) {
        if (ISMOBILE) {
            return
        }

        const newToolTip = new TourToolTip(options);
        this.registerChildView(newToolTip);

        return newToolTip;
    }

    /**
     * destroyAllTourTooltips
     *
     * Destroy and unregister all Tour Tooltips.
     */
    destroyAllTourTooltips() {
        this.destroyChildViewsOfInstance(TourToolTip);
    }

    render() {
        this.setView();
    }

    /**
     *
     * @param {Object|undefined} additionalData
     * Optional context-specific data to be added to the support attachment
     * @param {String|undefined} category
     * Name of category to skip to ('problem', 'content-mistake', 'idea')
     */
    showSupport(additionalData, category) {
        if (category) {
            // Get modal options for a specific category from the support category picker modal prototype.
            const modalOptions = SupportModalCategoryPicker.getSupportModalMessageFormOptions(category)

            // Attach the optional data.
            modalOptions.supportData.additionalData = additionalData

            Backbone.View.Components.modal.open(
                SupportModalMessageForm,
                modalOptions
            )
        } else {
            Backbone.View.Components.modal.open(SupportModalCategoryPicker, {
                title: window.i18n.gettext('Support'),
                clearButtons: true
            })
        }
    }

    // Get the current status message (if present)
    getStatusMessage() {
        const views = this.getChildViewsOfInstance(StatusMessage)

        if (views.length > 0) {
            return views[0]
        }
    }

    // When there is a status message view, close it
    closeStatus() {
        this.getStatusMessage()?.closeMessage();
    }

    // Open a new status message
    openStatus(message, status = 'error', options = {}) {

        const currentStatusMessage = this.getStatusMessage()

        // Check if there already is a status message
        if (currentStatusMessage) {

            // If it has the same message content, do not create a new status message
            if (currentStatusMessage.message === message) {
                return
            }

            // If the status message is set to reappear, do so after closing the upcoming message
            if (currentStatusMessage.extraOptions.mustReappear) {
                this.reappearStatusMessage = currentStatusMessage

                this.listenToOnce(this, 'statusMessageClosed', () => {
                    if (!this.reappearStatusMessage) {
                        return
                    }

                    this.openStatus(
                        this.reappearStatusMessage.message,
                        this.reappearStatusMessage.status,
                        this.reappearStatusMessage.extraOptions
                    )

                    delete this.reappearStatusMessage
                })
            }

            // Remove the status message
            this.destroyChildViewsOfInstance(StatusMessage);
        }

        this.addChildView(new StatusMessage({
            status,
            message,
            extraOptions: options
        }), this.$el)
    }

    /**
     * openNotAvailableForDemoMessage
     *
     * Show a generic message saying this is not possible in a demo.
     *
     */
    openNotAvailableForDemoMessage() {
        this.openStatus(window.i18n.gettext('This functionality is not available to demo users.'), 'info');
    }

    /**
     * openConfirmStatus
     *
     * Create a confirm dialog modal that can either be dismissed
     * or accepted.
     *
     * @param  {string} message         confirm dialog message
     * @param  {Function} callbackYes   callback when user accepts
     * @param  {Function} callbackNo    callback when user dismisses
     * @param  {string} yesButtonLabel  optional custom accept button label
     * @param  {string} noButtonLabel   optional custom cancel button label
     * @param  {string} title   optional title
     */
    openConfirmStatus(message, callbackYes, callbackNo, yesButtonLabel, noButtonLabel, title) {
        if (this.confirmMessage !== undefined) {
            this.unregisterAndDestroyChildView(this.confirmMessage);
        }

        this.confirmMessage = this.addChildView(new Confirm({
            message,
            callbackYes,
            callbackNo,
            yesButtonLabel,
            noButtonLabel,
            title
        }), this.$el);
    }

    /**
     * openAlert
     *
     * Create an alert dialog modal with a custom title and message.
     * Usefull for when the user is required to be informed of
     * something by interupting the current flow.
     *
     * @param  {string} title           alert dialog title
     * @param  {string} message         alert dialog message
     * @param  {Function} callbackYes   callback when user accepts
     * @param  {string} yesButtonLabel  optional custom alert button label
     */
    openAlert(title, message, callbackYes, yesButtonLabel) {
        if (this.alertMessage !== undefined) {
            this.unregisterAndDestroyChildView(this.alertMessage);
        }

        this.alertMessage = this.addChildView(new Alert({
            title,
            message,
            callbackYes,
            yesButtonLabel
        }), this.$el);
    }

    /**
     * Open modal to pick a group to view the requested page for.
     *
     * @param {String} action       target page action (part after the second /)
     */
    openGroupChooser(action) {
        Backbone.View.Components.modal.open(GroupChooser, {
            title: Backbone.Model.user.get('is_student') ?
                window.i18n.gettext('Select your course') :
                window.i18n.gettext('Select your group'),
            action
        })
    }

    // Open a modal to login again when your session has expired
    openReEnterPasswordModal() {
        if (this.reEnterPasswordModal) {
            return
        }

        this.reEnterPasswordModal = this.addChildView(new ReEnterPasswordModal(), this.$el)
    }

    closeReEnterPasswordModal() {
        if (!this.reEnterPasswordModal) {
            return
        }

        this.reEnterPasswordModal.canBeClosed = true
        this.reEnterPasswordModal.close()
        delete this.reEnterPasswordModal
    }

    setView(viewConstructor, viewPath = [], viewParams = []) {

        var oldView = this.view;

        // Close components that might be open from the old view
        Backbone.View.Components.keyboard.close()
        Backbone.View.Components.actionbutton.hide()
        Backbone.View.Components.fullscreen.close()
        Backbone.View.Components.modal.close()
        Backbone.View.sidebar.closeSidebar()

        // If the old view is a Svelte view, destroy it before creating a new one
        if (oldView && typeof oldView.$destroy === 'function') {
            this._destroyView(oldView)
            oldView = undefined
        }

        if (viewPath.length === 2 && viewPath[0] === 'users' && viewPath[1] === 'login') {
            document.title = `${LANGUAGE === 'nl_NL' ? 'Inloggen' : 'Log in' } ${window.app_version.label}`
        } else {
            document.title = window.app_version.label
        }

        // Detect via duck-typing if this is a Svelte view.
        const isSvelteView = typeof viewConstructor.prototype?.$destroy === 'function'

        // Attempt to construct view if there is a view prototype present.
        if (viewConstructor.prototype) {
            // Svelte views properties have a different format and require the target element to be defined
            // before creating the view as opposed to our own Backbone-based views which are appended to the DOM
            // after the view's creation.
            var view = new viewConstructor(
                isSvelteView ?
                    {
                        target: this.container,
                        props: {
                            path: viewPath,
                            params: viewParams,
                        }
                    } :
                    {
                        path: viewPath,
                        params: viewParams,
                    }
            )
            // If view is located in a seperate chunk, resolve the Promise to extract the view and retry.
        } else {

            Backbone.View.Components.pageSpinner.show();

            viewConstructor().then((module) => {
                this.setView(module.default, viewPath, viewParams);
            }).catch((error) => {
                console.error(error)
                if (navigator.onLine) {
                    window.sentry.withScope(scope => {
                        scope.setExtra('viewParams', viewParams)
                        window.sentry.captureException(error)
                    })
                } else {
                    window.sentry.withScope(scope => {
                        scope.setExtra('viewParams', viewParams)
                        window.sentry.setExtra('error', error)
                        window.sentry.captureMessage('Failed to load chunk while offline')
                    })
                }

                if (oldView) {
                    this._destroyView(oldView);
                }

                Backbone.View.Components.pageSpinner.show(() => {

                    // Retry loading the chunk if user is onLine. If not, show warning message.
                    if (navigator.onLine) {
                        this.setView(viewConstructor, viewPath, viewParams);
                    } else {
                        Backbone.View.layout.openStatus(
                            window.i18n.gettext('You are offline. Check your internet connection.')
                        );
                    }

                });
            });

            return

        }

        Backbone.View.Components.pageSpinner.hide()

        this.view = view

        if (oldView) {
            this._destroyView(oldView)
        }
        this._showView()

    }

    _showView() {
        this.registerChildView(this.view);

        // Detect via duck-typing if this isn't a Svelte view.
        if (typeof this.view.$destroy !== 'function') {
            this.view.appendTo(this.container)
            this.view.show()
        }
    }

    _destroyView(oldView) {
        // Detect via duck-typing if this is a Svelte view.
        if (typeof oldView.$destroy === 'function') {
            this.unregisterChildView(oldView)
            oldView.$destroy()
        } else {
            oldView.hide((_oldView) => {
                _oldView.detach()
                this.unregisterChildView(_oldView)
                _oldView.destroy()
            })
        }
    }

    openDemoVideoTour() {
        Backbone.View.Components.fullscreen.open(VideoTour, {
            title: window.i18n.gettext('Welcome to Learnbeat!'),
            buttons: [
                {
                    label: window.i18n.gettext('Go to demo'),
                    theme: 'secondary',
                    callback: Backbone.View.Components.fullscreen.close
                },
                {
                    label: window.i18n.gettext('Make an appointment'),
                    callback: () => {
                        window.open(
                            'https://calendly.com/startenmetlearnbeat/1-op-1-met-team-learnbeat',
                            '_blank'
                        )
                    }
                }
            ]
        });
    }

}
