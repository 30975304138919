import Styles from './Template37.scss';

import Template from './Template37.hbs';
import ModelAnswerTemplate from './ModelAnswer.hbs'
import ModelAnswerStyling from 'views/components/taskGroups/tasks/answerExplanation/AnswerExplanation.scss'

export default BaseView.extend( {

    initialize({
        task_view
    }) {

        _.bindAll(
            this,
            'saveAnswer',
            'showAnswer',
            'hideAnswer',
            'getCorrectAnswer',
            'lockAnswer',
            'unlockAnswer',
            'mergeTaskInfoAndResponse',
            'renderTask',
            'getStudentAnswer'
        );

        this.taskView = task_view

        this.renderTask()

    },

    /**
     * This method will render the template of the task. It will be
     * overwritten with an empty method in /views/components/taskGroups/tasks/Task.js
     * if only the answer view is necessary
     */
    renderTask() {

        this.setElement(Template({Styles}))

        // In author, show the model answer if present, and show the task otherwise
        if (APPLICATION === 'author') {
            import(
                /* webpackChunkName: "drawingcanvas" */
                'views/components/drawingCanvas/DrawingCanvasViewer'
            ).then(({ default: DrawingCanvasViewer }) => {
                this.addChildView(new DrawingCanvasViewer({
                    canvasJSON: this.mergeTaskInfoAndResponse(this.model.get('task_info_json').modelAnswer)
                }), this.$el)
            })
            return
        }

        // Canvas can be updated a lot during editing. Debounce the saveAnswer call by 0.8 seconds to reduce the
        // load on saving the response.
        this.saveAnswer = _.debounce(this.saveAnswer, 800)

        this.drawingCanvasEditorPromise = import(
            /* webpackChunkName: "drawingcanvas" */
            'views/components/drawingCanvas/DrawingCanvasEditor'
        ).then(({default: DrawingCanvasEditor}) => {
            this.drawingCanvasEditor = this.addChildView(new DrawingCanvasEditor({
                canvasJSON: this.mergeTaskInfoAndResponse(this.taskView.response.get('json_answer')),
                saveCallback: this.saveAnswer
            }), this.$el)
        })

    },

    saveAnswer(canvasJSON) {
        this.taskView.saveResponse(canvasJSON)
    },

    showAnswer() {
        const modelAnswer = this.model.get('task_info_json').modelAnswer
        if (
            modelAnswer && (
                this.hasCanvasContent(modelAnswer) ||
                /^\w+?\/\w+/.test(modelAnswer)
            )
        ) {
            this.$el.append(ModelAnswerTemplate({
                Styles: ModelAnswerStyling
            }))

            if (/^\w+?\/\w+/.test(modelAnswer)) {
                this.$('.js-model-answer').append(`<img src="/edu_files/open/${modelAnswer}">`)
            } else if (this.hasCanvasContent(modelAnswer)) {
                import(
                    /* webpackChunkName: "drawingcanvas" */
                    'views/components/drawingCanvas/DrawingCanvasViewer'
                ).then(({default: DrawingCanvasViewer}) => {
                    this.modelAnswerView = this.addChildView(new DrawingCanvasViewer({
                        canvasJSON: this.mergeTaskInfoAndResponse(modelAnswer),
                        hasZoom: true
                    }), '.js-model-answer')
                })
            }
        }
    },

    showAuthorAnswer() {},

    hideAnswer() {
        this.$('.js-model-answer-container').remove()
        if (this.modelAnswerView) {
            this.modelAnswerView.destroy()
            delete this.modelAnswerView
        }
    },

    getCorrectAnswer() {
        const modelAnswer = this.model.get('task_info_json').modelAnswer
        if (/^\w+?\/\w+/.test(modelAnswer)) {
            return `<img src="/edu_files/open/${modelAnswer}">`
        }
        if (this.hasCanvasContent(modelAnswer)) {
            return import(
                /* webpackChunkName: "drawingcanvas" */
                'views/components/drawingCanvas/DrawingCanvasViewer'
            ).then(({default: DrawingCanvasViewer}) => {
                return new DrawingCanvasViewer({
                    canvasJSON: this.mergeTaskInfoAndResponse(modelAnswer),
                    hasZoom: true
                })
            })
        }
        return ''
    },

    getStudentAnswer(responseModel) {
        const answerJSON = responseModel.get('json_answer')
        if (this.hasCanvasContent(answerJSON)) {
            return import(
                /* webpackChunkName: "drawingcanvas" */
                'views/components/drawingCanvas/DrawingCanvasViewer'
            ).then(({default: DrawingCanvasViewer}) => {
                return new DrawingCanvasViewer({
                    canvasJSON: this.mergeTaskInfoAndResponse(answerJSON),
                    hasZoom: true
                })
            })
        }
        return ''
    },

    lockAnswer() {
        if (this.drawingCanvasEditor) {
            this.drawingCanvasEditor.$el.hide()
            if (this.lockedTemplateDrawingCanvas) {
                return
            }
            import(
                /* webpackChunkName: "drawingcanvas" */
                'views/components/drawingCanvas/DrawingCanvasViewer'
            ).then(({default: DrawingCanvasViewer}) => {
                this.lockedTemplateDrawingCanvas = this.addChildView(new DrawingCanvasViewer({
                    canvasJSON: this.mergeTaskInfoAndResponse(this.drawingCanvasEditor.canvas.toJSON())
                }), this.$el, 'prepend')
            })
        } else if (this.drawingCanvasEditorPromise) {
            this.drawingCanvasEditorPromise.then(this.lockAnswer)
        }
    },

    unlockAnswer() {
        if (this.drawingCanvasEditor) {
            this.drawingCanvasEditor.$el.show()
            if (this.lockedTemplateDrawingCanvas) {
                this.lockedTemplateDrawingCanvas.destroy()
                delete this.lockedTemplateDrawingCanvas
            }
        }
    },

    hasCanvasContent(canvasJSON) {
        return canvasJSON && canvasJSON?.objects?.length > 0
    },

    mergeTaskInfoAndResponse(answerJSON) {
        const canvasJSON = {
            objects: [],
            background: null
        }
        // If the model answer is an object with a list of canvas objects, ensure that these objects
        // are excluded from being saved with the student's answer.
        const taskInfoJson = this.model.get('task_info_json')
        if (this.hasCanvasContent(taskInfoJson)) {
            taskInfoJson.objects.forEach((taskDrawingObject) => {
                taskDrawingObject.excludeFromExport = true
            })
            canvasJSON.objects.push(...taskInfoJson.objects)
        }
        if (taskInfoJson.background) {
            canvasJSON.background = taskInfoJson.background
        }
        if (taskInfoJson.width) {
            canvasJSON.width = taskInfoJson.width
        }
        if (taskInfoJson.height) {
            canvasJSON.height = taskInfoJson.height
        }

        if (this.hasCanvasContent(answerJSON)) {
            canvasJSON.objects.push(...answerJSON.objects)
        }

        return canvasJSON
    }

})
