import ResponsesCollection from 'collections/ResponsesCollection';
import SettingsCollection from 'collections/SettingsCollection';
import GroupsCollection from 'collections/GroupsCollection';
import ChaptersCollection from 'collections/ChaptersCollection';
import SectionsCollection from 'collections/SectionsCollection';
import ActivitiesCollection from 'collections/ActivitiesCollection';
import StudentsCollection from 'collections/StudentsCollection';
import TeachersCollection from 'collections/TeacherCollection';
import SharedContentCollection from 'collections/SharedContentCollection';
import LocalStorageCollection from 'collections/LocalStorageCollection';
import CoursesCollection from 'collections/CoursesCollection';
import ActivityChooser from 'views/components/modals/listChoosers/ActivityChooser';
import Util from 'util/util';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';

export default Backbone.Model.extend({

    url: '/users/update.json',

    initialize() {

        _.bindAll(this,
            'onLoadUserInfo',
            'logIn',
            'logOut',
            'onLoggedOut',
            'onErrorLoadingUserInfo',
            'gatherLocalStoredResponses',
            'onLoadLogin',
            'loadUserInfo',
            'onSocketEvent',
            'updateStatus',
            'saveLocalStoredData',
            'getGroupSetting',
            'showExamsActive',
        );

        this.listenTo(
            this,
            'change:openGradingSessions',
            (userModel, value) => this.openGradingSessionsStatusMessage(value)
        );

        this.listenTo(
            this,
            'change:exam_product',
            // When exam product is changed, check if it wasn't changed from undefined to
            // a value to prevent and endless loop of redirects. If not the case, do a
            // location reload to reset the interface to the appropriate state
            (usermodel) => {
                if (usermodel.previous('exam_product') !== undefined
                    // Also check if the new value isn't undefined. If it is, it's probably
                    // because the user clicks on the logout button. Therefore we don't want
                    // to recreate the menubar. Else it will show up on the login screen
                    && usermodel.get('exam_product') !== undefined
                ) {
                    // Recreate the menubar to rerender it
                    Backbone.View.layout.createMenubar();
                }
            }
        );

        this.settings = new SettingsCollection();

        // Object storing the unread items sorted by categories during the current session.
        this.unreadCount = {
            'new-exam-report': {}
        };

        this.loadUserInfo();

    },

    getSettingModel(keyword) {
        return this.settings.findWhere({
            keyword
        });
    },

    getSetting(keyword) {
        // Get the keyword model with the internal getSettingModel function
        var keywordModel = this.getSettingModel(keyword);

        // When keywordModel is undefined, return undefined else the value of the keywordModel
        return (keywordModel === undefined) ? undefined : keywordModel.get('value');
    },

    getGroupSetting(groupId, keyword) {
        const keywordModel = this.settings.findWhere({
            group_id: groupId,
            keyword
        });

        return (keywordModel === undefined) ? undefined : keywordModel.get('value');
    },

    addSetting(keyword, value, isEncrypted) {
        var currentSetting = this.getSetting(keyword);
        if (currentSetting !== value) {
            return this.settings.addSetting(keyword, value, isEncrypted);
        }
        return currentSetting;
    },

    first_name_last_name() {
        if (this.get('sortable_last_name').length > 0) {
            return `${(
                `${this.get('first_name')} ${this.get('prefix')}`
            ).trim()} ${
                this.get('sortable_last_name')}`;
        }
        return this.get('first_name');

    },

    /**
     * Returns the name of the user in a form that is suitable for a teacher
     *
     * @return {string} f.e. F. van Rest instead of Frank van Rest
     */
    teacher_name() {
        return `${this.get('first_name').substring(0, 1)
        }. ${this.get('prefix')} ${this.get('sortable_last_name')}`;
    },

    last_name_first_name() {
        return `${this.get('sortable_last_name')}, ${this.get('first_name')} ${this.get('prefix')}`;
    },

    /**
     * sendSocketEvent
     *
     * Request the backend to send a socket event to the given user
     *
     * @param  {Array} userIds      Array of user id's of the users who should receive the event
     * @param  {string} type        event type descriptor
     * @param  {Object} data        payload
     */
    sendSocketEvent(userIds, type, data) {
        $.post({
            url: '/users/send_socket_event',
            data: {
                user_ids: userIds,
                type,
                data
            },
        })
    },

    // Tell the backend to log out the current user
    logOut() {
        if (!window.navigator.onLine) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('You are offline. Check your internet connection.'),
                'warning'
            );
            return;
        }

        $.post('/users/ajax_logout.json', this.onLoggedOut);
    },

    /**
     * Callback for the logOut POST-request
     * Clears data from Backbone.Model.user and navigates user to login screen
     */
    onLoggedOut() {

        // Update layout such as menu bar, crumblepath, etc
        Backbone.View.layout.onUserIsLoggedOut();

        this.disconnectSocket();

        // clear all model attributes
        this.clear();

        // Goto login page
        Backbone.history.navigate('users/login', { trigger: true });
    },

    logIn(username, password, joinGroupId, submitButtonView) {

        // If there is already a request open
        if (this.xhr) {

            // Don't do anything
            return;
        }

        var loginObject = {
            User: {
                username,
                password
            }
        };

        // When the user is in SU flow and knows his group id
        // Send it to automatically create a group join request
        if (joinGroupId !== undefined) {
            loginObject.joinGroupId = joinGroupId;
        }

        // Bind variable to post request
        this.xhr = $.post(
            `${location.origin}/users/ajax_login.json`,
            loginObject,
            /**
             * Bind submitButtonView to onLoadLogin so the
             * spinner can be stopped after the response is done.
             */
            this.onLoadLogin.bind(this, submitButtonView)
        ).fail(function() {
            this.xhr = undefined;

            // Listen to external ajax errors: stop spinner if error occurs.

            if (submitButtonView){
                submitButtonView.enable()
            }
        }.bind(this));

        this.set('username', username);

    },

    onLoadLogin(submitButtonView, response) {

        if (response.status === 'error') {

            let statusMessage
            switch (response.err_code) {
                case 28429:

                    // Error 28429 means the backend had too many incorrect attempts in a short period of time
                    // We need to wait for 3 seconds and do the request again
                    setTimeout(() => {

                        // The request is complete, prevent blocking new requests
                        this.xhr = undefined

                        // Submit the login form again
                        submitButtonView.callback()
                    }, 3000)
                    return

                case 28444:
                    statusMessage = window.i18n.gettext('You first have to validate your email. Check your mailbox.')
                    break

                case 28450:
                    statusMessage = window.i18n.gettext('You are not allowed to use this account on this page.')
                    break

                case 28409:
                    statusMessage = window.i18n.gettext('You\'re already logged in as an other user.')
                    break

                default:
                    statusMessage = window.i18n.gettext('You entered wrong credentials.')
                    break
            }

            // Show the error and stop the spinner, so the user can try again. We use a short timeout here,
            // because the loading state will otherwise disappear too quickly
            setTimeout(() => {

                // The request is complete, prevent blocking new requests
                this.xhr = undefined
                if (submitButtonView) {
                    submitButtonView.enable()
                }
                Backbone.View.layout.openStatus(
                    statusMessage,
                    'error'
                );
            }, 500)

        } else {

            // The request is complete, prevent blocking new requests
            this.xhr = undefined

            // save username (after successfully logging in for next login attempt)
            if (Util.hasSupportForLocalstorage() && this.get('username')) {
                localStorage.setItem('previousUsername', this.get('username'));
            }

            Backbone.Model.user.set('sessionLost', false);

            // If the frontend already has a user id and the user logged in as a different user,
            // we need to fully reload the frontend
            if (Backbone.Model.user.id && response.user_id && Backbone.Model.user.id !== response.user_id) {
                window.app.needsFrontendUpdate = true
            }

            // Trigger on the Backbone object since the backbone model isn't
            // loaded yet in the constructor of the login screen. Therefore the
            // listenTo won't do anything when listening to this model
            Backbone.trigger('login:successful', response);
        }
    },

    changeToUser(userId) {

        this.disconnectSocket();

        // change backend user, close potential status message, load new user
        $.get('/users/ajax_change_to_user/' + userId + '.json', () => {
            Backbone.View.layout.closeStatus()
            this.loadUserInfo()
        })
    },

    loadUserInfo(callback) {

        $.get(
            '/users/get.json',

            (response) => {

                if (response.status !== 'success') {
                    this.onErrorLoadingUserInfo();
                    return;
                }

                // This section within the if-statement will handle the accessor logistics.
                // It will do two things:
                //  1. It will create and populate the accessor model on the global scope
                //  2. It will start navigation if there is an accessor, but no user
                if ('Accessor' in response) {

                    // 1. Create and populate the accessor model
                    Backbone.Model.accessor = new Backbone.Model(response['Accessor']);

                    // 2. Check if there wasn't any User data provided. If not, we want to
                    //    stop further execution because we don't want to trigger any login
                    //    callbacks. We'll use an early return for this. However since we
                    //    stop this function from executing further, we also won't start the
                    //    history. So we'll do that here again by hand.
                    if (!('User' in response)) {

                        // Check if the history is not yet started
                        if (!Backbone.History.started) {

                            // Start the history
                            Backbone.history.start({

                                // Check if the browser has support for push state
                                pushState: window.app.pushStateSupport,

                                // Set root to '/'
                                root: '/'
                            });

                            // Else the routing is already started
                        } else {

                            // Check if the window has a goToLocation set
                            if (window.goToLocation) {

                                // Go to the goToLocation
                                Backbone.history.navigate(window.goToLocation, {
                                    trigger: true
                                });

                                delete window.goToLocation

                                // Else there isn't a goToLocation
                            } else {

                                Backbone.history.navigate('accessor/connections', {
                                    trigger: true
                                });

                            }
                        }
                        return;
                    }
                } else {
                    // If no accessor object was found anymore within the get call
                    // destroy the accessor model to asume the user isn't an
                    // accessor anymore.
                    delete Backbone.Model.accessor;
                }

                this.onLoadUserInfo(response);

                // Check if there is a callback set and that it is a function
                if (typeof callback === 'function') {

                    // Execute callback
                    callback();

                // Else, just do the normal actions
                } else {

                    // Execute the functions that should be executed when user logs in
                    Backbone.View.layout.onUserIsLoggedIn();

                    // Check if the history is not yet started
                    if (!Backbone.History.started) {

                        // Start the history
                        Backbone.history.start({

                            // Check if the browser has support for push state
                            pushState: window.app.pushStateSupport,

                            // Set root to '/'
                            root: '/'
                        });

                    // Else the routing is already started
                    } else {

                        // If there is a goToLocation set, use that, else we'll fallback to the
                        // users/home route.

                        // If the frontend needs an update, do a hard reload
                        if (window.app.needsFrontendUpdate) {
                            window.location = window.goToLocation || '/users/home';

                        // Else there isn't a goToLocation
                        } else {
                            Backbone.history.navigate(window.goToLocation || '/users/home', {
                                trigger: true
                            });
                        }

                        delete window.goToLocation
                    }
                }
            }
        ).fail(
            this.onErrorLoadingUserInfo
        );
    },

    /**
     * Setup the socket connection for the current user
     *
     */
    setupSocketConnection() {

        // Temporarily check for the supervisor role, since we don't want to set up a
        // socket connection for a supervisor. However we should change the listen
        // method to only allow specific event-types based on ACL roles. So in the
        // future we can enable/disable socket feature based on ACL roles.
        if (ACL.checkRole(ACL.roles.SUPERVISOR)) {
            return;
        }

        if (!window.Pusher) {
            window.Pusher = Pusher
        }

        // If we are already subscribed, there is no need to connect again
        if (parseInt(this.channel?.subscription?.members?.myID) === this.id) {
            return
        }

        let wsHost
        let key
        if (DOMAIN.isDevelopment) {
            wsHost = 'ws.learnbeat.local'
            key = 'learnbeat'
        } else {
            if (DOMAIN.isBeta || DOMAIN.isLearnBeta) {
                key = 'learnbeat-beta'
            } else {
                key = 'learnbeat-live'
            }
            wsHost = 'ws.learnbeat.nl'
        }

        this.echo = new Echo({
            broadcaster: 'pusher',
            key,
            cluster: '',
            wsHost,
            wsPort: (DOMAIN.isDevelopment) ? 80 : 6001,
            forceTLS: !(DOMAIN.isDevelopment),
            encrypted: true,
            disableStats: true,
            enabledTransports: (DOMAIN.isDevelopment)
                ? ['ws']
                : ['ws', 'wss'],
            // Interceptors adds a X-Socket-Id header to all requests initiated from jQuery. We do not have a use
            // for this. Also some external domains reject requests if it contains this header. So we can disable it.
            withoutInterceptors: true,
        })

        // For teachers, update some info when socket connects
        if (this.get('is_teacher')) {
            this.echo.connector.pusher.connection.bind('connected', () => {

                // Find out which of the students are online
                $.get({
                    url: '/users/get_online_students',
                    success: (response) => {

                        // Reset all students to offline, then set all students from the response to online
                        Backbone.Collection.students.each((student) => {
                            student.set('online-state', 'offline')
                        })
                        for (const studentId of response.online) {
                            Backbone.Collection.students.get(studentId)?.set('online-state', 'online')
                        }
                    },
                    error: (response) => {
                        response.failSilently = true
                    }
                })

                // Find out which exams are currently active
                $.get({
                    url: '/users/get_active_exams.json',
                    success: (response) => {
                        this.setExamsActive(response.activeExams)
                        this.showExamsActive()
                    },
                    error: (response) => {
                        response.failSilently = true
                    }
                })
            })
        }

        this.channel = this.echo.join('user.' + this.id).listenToAll((event, data) => {
            this.onSocketEvent(data)
        })
    },

    /**
     * Disconnect the socket connection (f.e. when a user logs out)
     */
    disconnectSocket() {

        this.echo?.disconnect()

    },

    /**
     * Callback for receiving a socket event
     * All possible incoming socket-events should be listed in this method.
     * This method is responsible for further delegation of the event.
     * See example event for delegation to other views.
     *
     * @param  {Object} event Socket event
     */
    onSocketEvent(event) {

        // all events need data.type
        if (!event || !event.type) {
            window.sentry.withScope(scope => {
                scope.setExtra('event', event)
                window.sentry.captureException('Could not process socket event data')
            })
            return
        }

        switch (event.type) {
            case 'updated-online-states': {
                for (const [studentId, state] of Object.entries(event.data)) {
                    const studentModel = Backbone.Collection.students.get(studentId)
                    if (state === 'online') {
                        studentModel?.set('online-state', 'online')
                    } else {
                        studentModel?.set('online-state', 'offline')
                    }
                }
                break
            }
            case 'go-to-url':

                // Check if user is not locked to a url (f.e. exam or SAA).
                // If user is allowed to go to other url's, trigger the event
                if (!this.has('lockToURL')) {
                    Backbone.history.navigate(event.data, {trigger: true})
                }
                break
            case 'show-status-message':
                Backbone.View.layout.openStatus(
                    event.data,
                    'success'
                )
                break

            // SU flow events
            case 'join-request-accepted-by-teacher':

                // Set boolean, which will make sure data is reloaded
                // next time student navigates to home page
                this.set('acceptedToGroup', true)
                this.trigger(event.type)
                break

            // feedback events
            case 'new-feedback-message': {
                Backbone.View.menubar?.setNewNotes(event.data)
                break
            }

            // Number of active exams for teacher has changed
            case 'update-active-exams':
                this.setExamsActive(event.data.activeExams)
                this.showExamsActive()
                break

            // Exam for student was started
            case 'open-exam-by-teacher':
            case 'open-exam-through-planning':

                // Trigger function to set student in exam
                this.setInExam(event.data)
                break;

            // Exam for student was stopped
            case 'close-exam-by-teacher':
            case 'close-exam-through-planning':
                this.trigger('close-exam-by-teacher', event.data)
                break

            // New exam report has been created by student
            case 'new-exam-report':
                this.incrementUnreadCount(event.type, event.data.activity_id)
                this.trigger(event.type, event.data.activity_id)
                break

            // The teacher has disabled reviewing the exam for the student
            case 'exam-answers-not-visible':

                if (
                    Backbone.Model.user.currentOpenActivity &&
                    Backbone.Model.user.currentOpenActivity.id === event.data.activity_id
                ) {
                    this.removeStudentFromExamReview(event.data.activity_id)
                }

                break

            case 'answers-visible-in-activity':
                // Notify student if answers become visible for the currently open activity,
                // if the answers of the activity aren't already visible.
                if (
                    this.currentOpenActivity &&
                    this.currentOpenActivity.id === event.data.activity_id &&
                    !this.currentOpenActivity.get('show_answers')
                ) {
                    Backbone.View.layout.openStatus(
                        window.i18n.gettext('The answers are now visible. Reload the page to see them.'),
                        'info'
                    )
                }
                break

            case 'finished-processing':
                this.trigger(event.type, event.data);
                break

            // This user was added to a new group, refresh the data and show a message
            case 'added-to-new-group':
                this.loadUserInfo(() => {
                    let message;
                    if (this.get('is_student')) {
                        message = window.i18n.sprintf(
                            window.i18n.gettext('You have been added to the group %s by your teacher.'),
                            Backbone.Collection.groups.get(event.data).get('name')
                        );
                    } else if (this.get('is_teacher')) {
                        message = window.i18n.sprintf(
                            window.i18n.gettext('You have been added to the group %s by a colleague.'),
                            Backbone.Collection.groups.get(event.data).get('name')
                        );
                    }
                    Backbone.View.layout.openStatus(message, 'info')
                })
                break

            // Update the teacher progress screen of student grading with realtime data
            case 'update-student-grading-progress':
                this.trigger(event.type, event.data)
                break

            // Update the amount of active grading sessions for students
            case 'stop-grading-session': {
                const currentlyOpenGradingSessions = this.get('openGradingSessions') || []
                this.set(
                    'openGradingSessions',
                    currentlyOpenGradingSessions.filter(
                        activityId => parseInt(activityId) !== parseInt(event.data.activity_id)
                    )
                )

                this.trigger(event.type, event.data)
                break
            }

            // Update the amount of active grading sessions for students and navigate students
            case 'start-grading-session': {

                // if student is in exam
                // do not start student grading session
                if (this.get('lockToURL')) {
                    break
                }

                const currentlyOpenGradingSessions = this.get('openGradingSessions') || []
                this.set(
                    'openGradingSessions',
                    [...currentlyOpenGradingSessions, parseInt(event.data.activity_id)]
                )

                // send student to grading session view if student is
                // not there yet
                const gradingPath = /^\/activities\/grading/

                if (!gradingPath.test(Backbone.history.location.pathname)) {
                    Backbone.history.navigate(`/activities/grading/${event.data.activity_id}`, {trigger: true })
                } else {
                    this.trigger(event.type, event.data)
                }
                break
            }

            //
            case 'grading-done': {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('One of your tests has been graded.'),
                    'success'
                )
                break
            }

            // The exam logout event is whispered by the frontend of students when an exam starts
            case 'exam-logout':
                if (this.get('is_student')) {

                    // We need to check if the logout event was sent from this browser or from another browser.
                    // If it came from another browser, this browser should log out.

                    // A code may already be present in local storage (e.g. set by another tab)
                    if (!this.randomUserCode && Util.hasSupportForLocalstorage()) {
                        this.randomUserCode = localStorage.getItem('randomUserCode')
                    }

                    if (event.data.randomUserCode !== this.randomUserCode) {
                        this.logOut();
                    }
                }
                break

            // Failed import from QTI, Scorm, Wikiwijs or IMSCP
            case 'import-failed':
                Backbone.View.layout.openStatus(
                    window.i18n.sprintf(
                        window.i18n.gettext('Importing of the %s activity has failed.'),
                        event.data
                    ),

                )
                break

            case 'import-success':
                Backbone.View.layout.openStatus(
                    window.i18n.sprintf(
                        window.i18n.gettext('%s activity successfully imported.'),
                        event.data
                    ), 'success'
                )
                break

            case 'generating-questions-success':
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('Questions successfully generated. Click to view.'),
                    'success',
                    {
                        noHide: true,
                        elementCallback() {
                            Backbone.history.navigate(
                                `task_groups/author/${event.data.activity_id}/${event.data.task_group_id}`,
                                {trigger: true}
                            )
                            Backbone.View.layout.closeStatus()
                        }
                    }
                )
                break

            case 'generating-questions-failed':
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('Something went wrong generating questions.'),
                )
                break

            case 'logout':
                this.logOut()
                break

            default:
                window.sentry.withScope(scope => {
                    scope.setExtra('event', event)
                    window.sentry.captureException('Socket event sent without handler')
                })
                break
        }
    },

    onLoadUserInfo(response) {

        if (response.status !== 'success') {
            this.onErrorLoadingUserInfo();
            return;
        }

        this.set(response.User);

        this.settings = new SettingsCollection(response.Settings);

        if (response.ConsumerIds !== null) {
            this.set('ConsumerIds', response.ConsumerIds);
        } else {
            this.set('ConsumerIds', []);
        }

        if (response.School !== undefined) {
            this.set({
                school: response.School.name,
                registerStudentsWithoutEmail: response.School.register_students_without_email === 1,
                hasPaidProducts: response.School.has_paid_products,
            });
        }

        if (response.Schools !== undefined) {
            this.set({
                schools: response.Schools
            })
        }

        // Lock the user to a specified activity if the user.
        // Examples: standalone and exam activities
        if (response.goDirectlyToActivity) {
            this.set({lockToURL: `activities/show/${response.goDirectlyToActivity}`});
        }

        /* Sentry extra config */
        window.sentry.configureScope((scope) => {
            scope.setTag('school_id', this.get('school_id').toString());
            scope.setTag('app_version_id', window.app_version.app_version_id);
            scope.setTag('mobile_build', ISMOBILE);
            scope.setTag('user_type',
                this.get('is_teacher') ?
                    'Teacher' :
                    this.get('is_student') ? 'Student' : 'None'
            );

            scope.setUser({
                id: this.get('id').toString(),
                username: this.get('username'),
                'Account type': (
                    (this.get('is_student')) ? 'Student' :
                        (this.get('is_teacher')) ? 'Teacher' : 'Unknown'
                ),
                'School name': this.get('school'),
                'School ID': this.get('school_id').toString(),
                last_users_get_call: new dayjs().format('YYYY-MM-DD HH:mm:ss')
            });
        });

        // Matomo CustomDimensions
        window.statsTracker.setUserId(this.get('id'));

        // The following dimension numbers relate to the folllowing action dimensions in Matomo
        // (6) - Type of user
        window.statsTracker.setCustomDimension(
            '6',
            (
                (this.get('is_student')) ? 'Student' :
                    (this.get('is_teacher')) ? 'Teacher' : 'Unknown'
            )
        );

        // (7) - User id
        window.statsTracker.setCustomDimension('7', this.id);

        // (8) - SchoolId
        window.statsTracker.setCustomDimension('8', this.get('school_id'));

        // (9) - Is demo user
        window.statsTracker.setCustomDimension('9', this.get('is_demo') ? 'Demo user' : 'Non demo user');

        // (10) - (sub)domain
        window.statsTracker.setCustomDimension('10', window.location.host)

        // create global collections
        Backbone.Collection.chapters = new ChaptersCollection();
        Backbone.Collection.sections = new SectionsCollection();
        Backbone.Collection.activities = new ActivitiesCollection()
        Backbone.Collection.students = new StudentsCollection();
        Backbone.Collection.teachers = new TeachersCollection();
        Backbone.Collection.theoryBooks = new Backbone.Collection()
        Backbone.Collection.shared_content = new SharedContentCollection();
        Backbone.Collection.question_types = new Backbone.Collection(response.QuestionTypes);
        Backbone.Collection.frameworks = new Backbone.Collection(response.Frameworks);
        Backbone.Collection.course_branding = new Backbone.Collection(response.CourseBranding);
        Backbone.Collection.courses = new CoursesCollection(response.Courses);
        Backbone.Collection.skills = new Backbone.Collection(response.Skills);
        Backbone.Collection.generalLevels = new Backbone.Collection(response.GeneralLevels);
        Backbone.Collection.levels = new Backbone.Collection(response.Levels);

        // the groups collection fills the collections above on the fly using the data in user_object.Groups
        Backbone.Collection.groups = new GroupsCollection(response.Groups);

        Backbone.Collection.localStorage = new LocalStorageCollection();

        // Set default of no exams active. This is updated once socket connects.
        if (this.get('is_teacher')) {
            this.setExamsActive()
        }

        // Setup a new socket connection
        this.setupSocketConnection();

        // Set default values for non-content based filters.
        this.set('results-filters-date-range', {startDate: null, endDate: null});

        // load annotations
        $.get({
            url: '/users/get_annotations.json',
            success: (response) => {
                this.onLoadAnnotations(response)
            },
            error: (response) => {
                response.failSilently = true
            }
        });

        // Collect locally stored responses directly after login.
        this.gatherLocalStoredResponses()
        // save local stored responses directly after login
        this.saveLocalStoredData();

        if (response.open_grading_sessions) {
            this.set('openGradingSessions', response.open_grading_sessions);
        }
    },

    // Update the status of the user. This can:
    // - set students in an exam
    // - remove students from an exam
    // - show students a message with open grading sessions
    // - trigger a sync for locally stored data
    updateStatus(response) {

        if (this.get('is_student')) {
            const currentActivity = Backbone.Model.user.currentOpenActivity

            // If the user is inside an exam with the answers visible (exam review),
            // check if the user is still alowed to review the exam.
            if (
                currentActivity &&
                ['exam', 'generated_exam', 'diagnostic_exam'].includes(currentActivity.get('type')) &&
                currentActivity.get('show_answers')
            ) {
                $.get(`/activities/check_if_answers_visible/${currentActivity.id}.json`, (visibility) => {
                    if (visibility === false) {
                        this.removeStudentFromExamReview(currentActivity.id)
                    }
                })
            }

            if (response.open_grading_sessions) {
                this.set('openGradingSessions', response.open_grading_sessions);
            }

            if (

                (
                    // Check if user should be in an exam but is not in an activity
                    response.exam !== null &&
                    !currentActivity
                ) ||
                (
                    // Check if user should be in an exam but is an other activity
                    response.exam !== null &&
                    currentActivity &&
                    currentActivity.id !== response.exam
                )
            ) {

                // Manually trigger function that send student into the exam
                this.setInExam(response.exam);

            } else if (

                // If the user is already in an exam but according
                // to the backend the user should not be in one
                //
                // NOTE: 'diagnostic_exam' has been removed here until
                // the /users/check_status.json call is extended
                // see : https://podio.com/dedactnl/platform/apps/learnbeat/items/9738
                //
                // TODO: re-add 'diagnostic_exam when backend call is extended
                currentActivity &&
                ['exam', 'generated_exam'].includes(currentActivity.get('type')) &&
                response.exam === null
            ) {

                // Trigger close exam event that the student is listening to
                // but didn't receive due to network inactivity
                this.trigger('close-exam-by-teacher', currentActivity.id);
            }
        }

        // Sync unsaved data locally stored data.
        this.saveLocalStoredData()

    },

    /**
     * setInExam
     *
     * Sets user in given exam activity
     *
     * @param  {Number} activityId - id of the exam activity
     */
    setInExam(activityId) {
        // Set lockToURL to URL of exam activity so users can't back out of
        // the exam by navigating to another URL within Learnbeat.
        this.set({lockToURL: `activities/show/${activityId}`});
        // Navigate to said URL right away.
        Backbone.history.navigate(this.get('lockToURL'), {trigger: true});
    },

    onLoadAnnotations(response) {
        _.each(response, (annotations, groupId) => {
            const groupModel = Backbone.Collection.groups.get(groupId)

            if (!groupModel) {
                return
            }

            const layers = groupModel.get('layers')
            let validAnnotations = annotations.Annotation

            if (layers === 2) {
                validAnnotations = validAnnotations.filter(annotation =>annotation.section_id)
            } else if (layers === 1) {
                validAnnotations = validAnnotations.filter(annotation => annotation.activity_id)
            }

            groupModel.annotations.reset(validAnnotations)

        })
    },

    // Store which exams are active
    setExamsActive(activityIds = []) {

        // Remove activity id's that are not present in the global collection to prevent errors
        activityIds = activityIds.filter(activityId => Backbone.Collection.activities.get(activityId))
        this.set('activeExams', activityIds)
    },

    // Show a status message indicating the teacher how many exams are active
    showExamsActive() {

        // If the teacher chose to ignore this message, do not re-open it
        if (this.get('hasClosedExamStatusMessage') || ISMOBILE) {
            return
        }

        // If there are no exams active, close the exam status message if present
        if (this.get('activeExams').length === 0) {
            if (Backbone.View.layout.getStatusMessage()?.extraOptions.isExamStatusMessage) {
                Backbone.View.layout.closeStatus()
            }
            return
        }

        const message = window.i18n.sprintf(
            window.i18n.ngettext(
                'There is %s exam active.',
                'There are %s exams active.',
                this.get('activeExams').length
            ),
            this.get('activeExams').length
        )

        // When the status message is already open, only update the text
        if (Backbone.View.layout.getStatusMessage()?.extraOptions.isExamStatusMessage) {
            Backbone.View.layout.getStatusMessage().setMessage(message)
            return
        }

        // Show the status message and listen to clicks
        Backbone.View.layout.openStatus(
            message,
            'info',
            {
                noHide: true,
                mustReappear: true,
                elementCallback: () => { this.onClickExamsActive() },
                buttonCallback: (event) => { this.onCloseExamsActive(event) },
                isExamStatusMessage: true,
            }
        )
    },

    onCloseExamsActive(e) {
        e.stopPropagation()
        this.set('hasClosedExamStatusMessage', true)
        Backbone.View.layout.closeStatus()
    },

    // When a teacher clicks on the exams active status message
    // navigate to the exam or show a modal with exams to choose from
    onClickExamsActive() {

        // Set user preference to ensure the user arrives at the exam progress view
        // with the tab labeled 'progress' already openend.
        Backbone.Model.user.addSetting('activeActivityProgressViewMode', 'progress');

        // Directly navigate to the exam if there is only one
        if (this.get('activeExams').length === 1) {
            Backbone.history.navigate(
                `activities/progress/${this.get('activeExams')[0]}`,
                {trigger: true}
            )
            return
        }

        // Open modal with active exams to choose from
        const activityModels = [...this.get('activeExams')].map(id => Backbone.Collection.activities.findWhere({ id }))
        Backbone.View.Components.modal.open(ActivityChooser, {
            title: window.i18n.gettext('Active exams'),
            activityModels,
            goToUrl: '/activities/progress'
        })

    },

    onErrorLoadingUserInfo() {
        if (!Backbone.History.started) {
            Backbone.history.start({
                pushState: window.app.pushStateSupport,
                root: '/'
            });
        }
    },

    attempts: 1,

    /**
     * syncResponsesPeriodic
     *
     * This function attempts a sync after 5 seconds multiplied by
     * the amount of attempts by the user.
     */
    syncResponsesPeriodic() {

        // Set this condition to true to make sure the timeout function is not
        // set on every user input
        this.isSyncingResponsesPeriodic = true;

        setTimeout(() => {

            // Check if not already dismissed
            if (this !== undefined) {

                this.isSyncingResponsesPeriodic = false;

                this.attempts++;
                this.saveLocalStoredData();
            }

        },
        // Retry takes longer when more attempts fail, but at least once every 30 seconds
        Math.min(5000 * this.attempts, 30 * 1000)
        )

    },

    responsesBuffer: new ResponsesCollection(),

    /**
     * saveLocalStoredData
     *
     * Attempt to sync locally stored responses and data from the global
     * local storage collection.
     */
    saveLocalStoredData() {
        this.responsesBuffer.syncResponseToServer()
        Backbone.Collection.localStorage.attemptSyncAll();
    },

    /**
     * gatherLocalStoredResponses
     * Checks localstorage for un-synced responses for the currently logged-in student and puts them into the
     * responsesBuffer collection, which will handle syncing them to the server.
     *
     * Only called when the user logs in.
     */
    gatherLocalStoredResponses() {
        // only check for local stored responses when user is a student
        // if browser supports localstorage, check for responses
        if (this.get('is_student') && Util.hasSupportForLocalstorage()) {

            // go over all keys stored in localstorage to see whether they are responses
            for (const key of Object.keys(window.localStorage)) {

                if (key.substring(0, 9) === 'response-') {

                    // create response object from localstorage entry
                    const responseObject = JSON.parse(window.localStorage.getItem(key))

                    // Only use response models made by the current user.
                    if (responseObject.user_id !== this.id) {
                        continue
                    }

                    // if the answer was not already added to this collection, add it
                    this.responsesBuffer.push(responseObject)

                }

            }

        }
    },

    /**
     * logOutOtherSessionsBySameStudent
     *
     * Sends socket event to any simultaneous login sessions by the same student.
     * This logs the present user out all other clients except the one that
     * sent the 'examLogout' socket event. We use localstorage because multiple tabs
     * can be open in one browser and they share the same session.
     */
    logOutOtherSessionsBySameStudent() {

        if (!this.get('is_student')) {
            return
        }

        // A code may already be present in local storage (e.g. set by another tab)
        if (!this.randomUserCode && Util.hasSupportForLocalstorage()) {
            this.randomUserCode = localStorage.getItem('randomUserCode')
        }

        if (!this.randomUserCode) {
            this.randomUserCode = _.random(10000, 99999).toString()
        }

        // Store the code for potential other tabs
        if (Util.hasSupportForLocalstorage()) {
            localStorage.setItem('randomUserCode', this.randomUserCode)
        }

        // Whisper in the presence channel of this user
        this.channel?.whisper('exam-logout', {
            type: 'exam-logout',
            data: {
                randomUserCode: this.randomUserCode
            }
        })
    },

    /**
     * incrementUnreadCount
     *
     * Increment count of unread items of a specified type and subType by 1.
     *
     * @param  {string|number} type         type key. For example: 'new-exam-report'
     * @param  {string|number} subType      second level key. For example: '2586463'
     */
    incrementUnreadCount(type, subType) {
        this.unreadCount[type][subType] = this.getUnreadCount(type, subType) + 1;
    },

    /**
     * getUnreadCount
     *
     * Get unread items of a specified type and subType.
     *
     * @param  {string|number} type         type key. For example: 'new-exam-report'
     * @param  {string|number} subType      second level key. For example: '2586463'
     * @return {number}                     count of unread items of type.subType
     *                                      during the current user session.
     */
    getUnreadCount(type, subType) {
        return this.unreadCount[type][subType] || 0;
    },

    /**
     * resetUnreadCount
     *
     * Reset unread items of a specified type and subType by deleting the number.
     *
     * @param  {string|number} type         type key. For example: 'new-exam-report'
     * @param  {string|number} subType      second level key. For example: '2586463'
     */
    resetUnreadCount(type, subType) {
        delete this.unreadCount[type][subType];
    },

    removeStudentFromExamReview(activityId) {
        // Make sure activity can be found, then navigate to the parent section
        // Take users/home as a fallback
        const activities = Backbone.Collection.activities
        const locationPath = activities.get(activityId) ?
            '/sections/show/' + activities.get(activityId).get('section_id') :
            '/users/home'

        Backbone.history.navigate(locationPath, {trigger: true})
        Backbone.Model.user.currentOpenActivity = null

        Backbone.View.layout.openAlert(
            window.i18n.gettext('Exam review stopped'),
            window.i18n.gettext('The teacher has stopped the review of your exam.'),
            () => {}
        )
    },

    /**
     * openGradingSessionsStatusMessage
     *
     * This method will open a status message for when the student
     * still has any grading sessions open. It'll attach a listener
     * to the routing to hide the message when the grading mode is
     * open.
     *
     * @param {array} activities array containing activities with open sessions
     */
    openGradingSessionsStatusMessage(activities = []) {

        // Since this method will be called for every non-grading routing,
        // we want to stop listening for routing events to prevent duplicate
        // listeners. If we shouldn't stop listening we'll attach the listener
        // again at the bottom of this method. You can see it as a smarter
        // listenToOnce
        this.stopListening(window.app.router, 'route');

        // If the teacher chose to ignore this message, do not re-open it
        if (Backbone.Model.user.get('hasClosedGradingSessionMessage') || ISMOBILE) {
            return
        }

        // If there is another statusmessage open, and we already have the grading sessions open
        // as reappearing message, do not open status message. Else we're suppressing error messages
        if (Backbone.View.layout.getStatusMessage()
            && !Backbone.View.layout.getStatusMessage()?.extraOptions.isGradingSessionStatus
            && Backbone.View.layout.reappearStatusMessage?.extraOptions.isGradingSessionStatus
        ) {
            return
        }

        // If there are no exams active, close the exam status message if present
        if (activities.length === 0) {
            if (Backbone.View.layout.getStatusMessage()?.extraOptions.isGradingSessionStatus) {
                Backbone.View.layout.closeStatus()
            }
            return
        }

        // Show the status message and listen to clicks
        Backbone.View.layout.openStatus(
            window.i18n.sprintf(
                window.i18n.ngettext(
                    'There is %s grading session open.',
                    'There are %s grading sessions open.',
                    activities.length
                ),
                activities.length
            ),
            'info',
            {
                noHide: true,
                mustReappear: true,
                elementCallback: () => this.onClickGradingSessionStatus(activities),
                buttonCallback: this.onCloseGradingSessionsStatus,
                isGradingSessionStatus: true
            }
        )

        this.listenTo(window.app.router, 'route', () => this.listenForGradingUrl(activities));
    },

    /**
     * listenForGradingUrl
     *
     * This method will be called when the user navigates to a new page. It
     * will check if the url is on the grading page or not. If it is, it'll
     * hide the status message.
     *
     * @param {array} activities containing activities with open sessions
     */
    listenForGradingUrl(activities) {
        const { activePath } = window.app.controller;

        if (activePath[1] === 'grading'
            && Backbone.View.layout.getStatusMessage()?.extraOptions.isGradingSessionStatus
        ) {
            Backbone.View.layout.closeStatus();
        } else {
            this.openGradingSessionsStatusMessage(activities);
        }
    },

    onCloseGradingSessionsStatus(e) {
        e.stopPropagation()
        Backbone.Model.user.set('hasClosedGradingSessionMessage', 1)
        Backbone.View.layout.closeStatus()
    },

    onClickGradingSessionStatus(activities) {
        // Directly navigate to the exam if there is only one
        if (activities.length === 1 && Backbone.Collection.activities.get(activities[0])) {
            Backbone.history.navigate(
                `activities/grading/${activities[0]}`,
                {trigger: true}
            )
            return
        }

        // Open modal with active exams to choose from
        const activityModels = activities.map(
            id => Backbone.Collection.activities.get(id)
        )
        Backbone.View.Components.modal.open(ActivityChooser, {
            title: window.i18n.gettext('Open student grading sessions'),
            activityModels,
            goToUrl: '/activities/grading'
        })
    },

    /**
     * Async method that can fetch learning material collections again if the current one is outdated when the
     * frontend detects there is something missing. See also the 'attempt' function defined in main.js.
     *
     * @returns {Promise}
     * Promise that resolves which calls the callback and returns its resulting value, if it returns anything.
     */
    async updateCollections() {
        // Ensure only one request is being done at any time to avoid DoS-ing our server.
        if (this.isFetchingCollections === undefined) {
            this.fetchCollectionsInProgress = this.fetchCollections()
        }
        return await this.fetchCollectionsInProgress
    },

    /**
     * Async method that fetches learning material collections of whenever the current one is no longer up to date.
     *
     * @returns {Promise}
     * Promises which resolves if the content is fetched successfully.
     */
    async fetchCollections() {

        return await $.get({
            // Fetch from users/get_collections, which is like users/get, except it only fetches groups, chapters,
            // sections and activities.
            url: '/users/get_collections.json',
            success: (response) => {
                Backbone.Collection.chapters = new ChaptersCollection()
                Backbone.Collection.sections = new SectionsCollection()
                Backbone.Collection.activities = new ActivitiesCollection()

                // This triggers a refill of the above listed Collections
                Backbone.Collection.groups = new GroupsCollection(response.Groups)
            },
            // Set and remove flags during the fetching progress so only one request can take place at any time.
            beforeSend: () => {
                this.isFetchingCollections = true
            },
            complete: () => {
                delete this.isFetchingCollections
            }
        })

    }

}, {
    type: 'user'
});
