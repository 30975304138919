var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasProgress") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":23,"column":15}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"tile__navigation\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"canViewProgress") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a href=\"/sections/progress/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"js-progress tile__navigation__btn\">\n                        "
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/shape.js")).call(alias3,"progress",{"name":"shape","hash":{},"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":46}}})) != null ? stack1 : "")
    + "\n                        <span>"
    + alias2(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias3,"Progress",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":10,"column":30},"end":{"line":10,"column":47}}}))
    + "</span>\n                        <strong>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"progress") : depth0), depth0))
    + "</strong>\n                    </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"tile__navigation__btn tile__navigation__btn--locked\">\n                        <div class=\"js-progress-bar tile__navigation__progress-bar\"></div>\n                        <strong>"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"progress") : depth0), depth0))
    + "</strong><span>"
    + alias1(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(alias2,"made",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":16,"column":59},"end":{"line":16,"column":72}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"answersVisible") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":19,"column":31}}})) != null ? stack1 : "")
    + "                    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <strong>"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"score") : depth0), depth0))
    + "</strong><span>"
    + alias1(__default(require("../../../../../util/handlebarsHelpers/__.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"score",{"name":"__","hash":{},"data":data,"loc":{"start":{"line":18,"column":56},"end":{"line":18,"column":70}}}))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"tile\">\n    <a href=\"/sections/show/"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"tile__content js-content-label\"></a>\n\n"
    + ((stack1 = __default(require("../../../../../util/handlebarsHelpers/isNotMobile.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"isNotMobile","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":24,"column":20}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});