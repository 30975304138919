import Styles from './Home.scss';

import Template from './Home.hbs';
import StudentCards from 'views/pages/users/home/studentCards/StudentCards';
import TeacherCards from 'views/pages/users/home/teacherCards/TeacherCards';
import Greeting from 'views/components/greeting/Greeting';
import StatusCard from 'views/components/statusCard/StatusCard'
import FixEmail from 'views/components/modals/fixEmail/FixEmail';
import Button from 'views/components/button/Button'
import HeroButton from 'views/components/heroButton/HeroButton'
import Spinner from 'views/components/spinner/Spinner';
import EditFavoriteModal from './editFavoriteModal/EditFavoriteModal.svelte';

export default class Home extends BaseView {

    get events() {
        return {
            'click .js-toggle-environment': 'onToggleEnvironment'
        }
    }

    show() {
        // On home, we want the full menu to be present because users are primarily navigating
        Backbone.View.menubar.setPreferredSize('large')
    }

    initialize() {

        _.bindAll(this,
            'onDestroy',
            'openToolTipForUsersHome'
        );

        // This will be set to true when the teacher has accepted a student to a group
        if (Backbone.Model.user.get('acceptedToGroup')) {

            // The student needs to refresh the home page
            // to reinitialize his/her user info with the new group added.
            this.onStudentAcceptedToGroup();
        }

        // Create menu bar if it not already exists.
        if (!Backbone.View.menubar) {
            Backbone.View.layout.createMenubar();
        }

        let greetingSettings = {
            callback: () => {
                Backbone.history.navigate('/users/settings', { trigger: true })
            },
        }

        // For teachers with multiple schools, show the active school name in the greeting
        if (Backbone.Model.user.get('is_teacher') && !!Backbone.Model.user.get('schools')) {
            greetingSettings = {
                ...greetingSettings,
                flipLabels: true,
                message: Backbone.Model.user.get('school')
            }
        }

        Backbone.View.header.clearButtons()
        Backbone.View.header.setTitle(new Greeting(greetingSettings))
        Backbone.View.header.setCrumblepath()

        this.hasFavoriteGroups = !!Backbone.Collection.groups.findWhere({ is_favorite: true })

        this.setElement(Template({
            Styles,
        }));

        if (Backbone.Model.user.get('is_student')) {
            if (ISMOBILE) {
                this.addChildView(new Button({
                    label: window.i18n.gettext('Join a course'),
                    icon: 'plus-circle',
                    callback: this.onClickJoinGroup,
                }), '.js-join-group')
            } else {
                Backbone.View.header.addButton(new HeroButton({
                    firstLine: window.i18n.gettext('Join a course'),
                    icon: 'plus-circle',
                    callback: this.onClickJoinGroup
                }))
            }
        }

        if (Backbone.Model.user.get('is_teacher') && !ISMOBILE) {

            // Teachers with more than 6 groups can choose favorite groups
            if (Backbone.Collection.groups.length > 6 || this.hasFavoriteGroups) {
                Backbone.View.header.addButton(
                    new HeroButton({
                        firstLine: window.i18n.gettext('Edit favorites'),
                        icon: 'star-rounded',
                        callback: () => {
                            this.addSvelteChildView(this.el, EditFavoriteModal)
                        }
                    })
                )
            }

            Backbone.View.header.addButton(new HeroButton({
                firstLine: window.i18n.gettext('Manage groups'),
                icon: 'settings',
                callback: this.onClickManageGroups
            }))
        }

        this.parseSearchParams();

        if (
            Backbone.Model.user.get('email_invalid')
        ) {

            // Open the modal requesting the user to fix their email
            Backbone.View.Components.modal.open(FixEmail, {
                title: window.i18n.gettext('Check your email address'),
                email: Backbone.Model.user.get('email')
            });
        }

        this.model = new Backbone.Model();
        this.model.url = '/users/data_for_home.json';

        // Show a spinner while loading
        this.spinner = this.addChildView(new Spinner(), '.js-spinner')

        this.model.fetch({
            success: () => { this.onLoadModel() },
            error: () => { this.onLoadModelError() }
        });
    }

    // Go to join page when clicking 'join course' button
    onClickJoinGroup() {
        Backbone.history.navigate('users/join', {trigger: true});
    }

    // Go to settings page when clicking 'manage groups' button
    onClickManageGroups() {
        Backbone.history.navigate('users/settings', {trigger: true});
    }

    /**
     * openToolTipForUsersHome
     *
     * This opens a tour tooltip that points to the first group.
     *
     */
    openToolTipForUsersHome() {

        Backbone.View.layout.addTourTooltip({
            message: window.i18n.gettext('Click on the name of the group'),
            target: this.$('.js-card-title [component="content-label__name"]')
        });
    }

    onLoadModel() {

        this.spinner?.destroy()

        Backbone.View.menubar?.setNewNotes(this.model.get('number_of_new_notes'));

        // For students without (pending) groups, navigate to the join view
        if (Backbone.Model.user.get('is_student') &&
            Backbone.Collection.groups.length === 0 &&
            this.model.get('pending_groups').length === 0
        ) {
            Backbone.history.navigate('users/join', {trigger: true});
            return;
        }

        // Render home screen for teachers
        if (Backbone.Model.user.get('is_teacher')) {
            this.cards = this.addChildView(new TeacherCards({
                model: this.model
            }), '.js-cards-container');

            if (Backbone.Model.user.get('is_demo')) {
                this.openToolTipForUsersHome();
            }

            // Render pending students notification if needed
            if (this.model.get('pending_students') > 0) {

                const pendingStudentMessage = window.i18n.sprintf(
                    window.i18n.ngettext(
                        '%d student wants access to a group. Go to <a href="/users/settings">settings</a>.',
                        '%d students want access to a group. Go to <a href="/users/settings">settings</a>.',
                        this.model.get('pending_students')
                    ),
                    this.model.get('pending_students')
                )
                this.renderHomeAlert(pendingStudentMessage);
            }

            // If the backend tells us there are students without credentials
            if (this.model.get('students_without_credentials') > 0) {

                const noCredentialsMessage = window.i18n.sprintf(
                    window.i18n.ngettext(
                        '%d student did not receive login credentials. Go to <a href="/users/settings">settings</a>.',
                        '%d students did not receive login credentials. Go to <a href="/users/settings">settings</a>.',
                        this.model.get('students_without_credentials')
                    ),
                    this.model.get('students_without_credentials')
                )
                this.renderHomeAlert(noCredentialsMessage);
            }
        }

        // Render home screen for students
        if (Backbone.Model.user.get('is_student')) {

            this.cards = this.addChildView(new StudentCards({
                model: this.model
            }), '.js-cards-container');

            // If there are pending groups, reload when the teacher accepts the student
            if (this.model.get('pending_groups')) {
                this.listenTo(
                    Backbone.Model.user,
                    'join-request-accepted-by-teacher',
                    this.onStudentAcceptedToGroup
                );
            }
        }
    }

    // The model could not load. Ask the user to check their internet connection and show a button to try again
    onLoadModelError() {

        this.spinner?.destroy()

        Backbone.View.Components.pageSpinner.show(() => {

            // Retry loading the URL if the user is onLine. If not, show warning message.
            if (navigator.onLine) {
                Backbone.history.loadUrl()
            } else {
                Backbone.View.layout.openStatus(
                    window.i18n.gettext('You are offline. Check your internet connection.')
                )
            }
        })
    }

    hide(callback) {
        callback(this);
    }

    /**
     * onRefreshPage
     *
     * When this function is triggered, it will refresh the page.
     * This will happen when the student has a pending join request accepted by
     * it teacher.
     */
    onStudentAcceptedToGroup() {

        // Remove acceptedToGroup boolean because its handled
        Backbone.Model.user.unset('acceptedToGroup');

        location.reload();

    }

    /**
     * renderHomeAlert
     *
     * This function renders a home alert on the screen.
     * F.e. when there are students that want to join a group or are missing credentials.
     *
     * @param  {string} message - String containing the message in the alert
     */
    renderHomeAlert(message) {
        this.addChildView(new StatusCard({
            icon: 'alert',
            statusColor: 'orange',
            cardContent: message
        }), '.js-alert')
    }

    /**
     * onToggleEnvironment
     *
     * Triggered when user clicks the toggle-environment button.
     * Based on which environment the user is, the user will be sent to
     * mobile or desktop environment of the app.
     *
     */
    onToggleEnvironment() {

        let direction

        // If the user is in the mobile environment, redirect to desktop environment.
        // Else, redirect to mobile environment.
        if (ISMOBILE) {
            document.location.search = '?force_mobile=0';

            direction = 'mobile-to-desktop'
        } else {
            document.location.search = '?force_mobile=1';
            direction = 'desktop-to-mobile'
        }

        // log (info level) to sentry that user switched environment
        window.sentry.withScope(scope => {
            scope.setLevel('info')
            scope.setTag('direction', direction)
            scope.setTag('screen', `${window.screen.width}x${window.screen.height}:${window.devicePixelRatio}`)
            window.sentry.captureMessage('User switched mobile/desktop environment')
        })
    }

    /**
     * parseSearchParams
     *
     * Parse the search params in the url
     *
     */
    parseSearchParams() {
        const params = new URLSearchParams(location.search)

        // NOTE when teachers are coming out of the Thieme SSO flow, we will create a student account first.
        // This because we don't know the school of the teacher. After that we manually change it to a teacher account.
        if (params.has('thieme_teacher')) {
            Backbone.View.layout.openAlert(
                window.i18n.gettext('Validating your account'),
                window.i18n.gettext('Thank you for registering. We will now validate your teacher account. Until then you have access to Learnbeat as a student. You will be notified by email.'),
                () => {}
            );
        }
    }

    onDestroy() {
        Backbone.View.menubar?.setPreferredSize()
    }
}
