import Styles from './Linear.scss';

import Template from './Linear.hbs';
import TaskGroup from 'views/components/taskGroups/TaskGroup'
import StatusCard from 'views/components/statusCard/StatusCard';
import Summary from 'views/pages/activities/show/types/linear/summary/Summary'
import SidebarAboutTaskGroup from 'views/pages/activities/show/sidebars/aboutTaskGroup/AboutTaskGroup'
import SidebarActivityNavigation from 'views/pages/activities/show/sidebars/activityNavigation/ActivityNavigation'
import AnnotationSidebar from 'views/pages/activities/show/sidebars/annotations/Annotations'
import NavigationBar from 'views/pages/activities/show/navigationBar/NavigationBar'
import HeroButton from 'views/components/heroButton/HeroButton'
import Util from 'util/util';
import PaginationNavigation from 'views/components/paginationNavigation/PaginationNavigation';
import ToggleSwitch from 'views/components/toggleSwitch/ToggleSwitch.svelte'
import HeroDropdown from 'views/components/heroButton/heroDropdown/HeroDropdown'
import BackgroundImage from '../../backgroundImage/BackgroundImage';
import PresentationMode from 'views/pages/activities/show/types/linear/PresentationMode/PresentationMode';

export default BaseView.extend({

    events: {
        'click .js-taskgroup-index-container': 'onClickTaskGroupIndex'
    },

    show() {
        Backbone.View?.menubar?.setPreferredSize('small')
    },

    initialize(options) {

        if (this.model.get('gradingInProgress') && !ISMOBILE) {

            Backbone.history.navigate(
                `/activities/grading/${this.model.id}`,
                { trigger: true }
            )

            return

        }

        _.bindAll(
            this,
            'addNavigationBar',
            'onNavigationKeyUp',
            'showAnnotationNumber',
            'onClickMakeAnnotation',
            'openSomtodayWindow',
            'onTimeLogVisibilityChange',
            'setTimeLogUserActive',
            'checkTimeLogUserActive',
            'onClickAboutTaskGroup',
            'onClickPrintOnlySources',
            'onClickPrintWithAnswers',
            'onClickPrintWithoutAnswers',
            'showSidebarActivityNavigation',
        );

        // Make activity show accessible within methods
        this.activityShow = options.activityShow;

        this.setElement(Template({
            Styles,
            canAddAnnotations: ACL.isAllowed(ACL.resources.ANNOTATIONS, ACL.actions.ADD)
        }));

        // Hide bottom pagination controls, to prevent it showing up at Summary view
        this.$('.js-pagination-bar').hide();

        $('body').addClass('is-work-on');

        Backbone.View.header.clearButtons()
        Backbone.View.header.setTitle()

        this.addShowScoresButton()

        // Create navigation bar
        this.addNavigationBar();

        if (!ISMOBILE) {

            // Retrieve course branding that current activity is part of. If course branding has a logo,
            // append this to the header on the summary pages.
            // const chapterModel = this.model.getChapterModel()
            // if (chapterModel && chapterModel.get('course_branding_id') && Backbone.Collection.course_branding) {
            //     const courseBrandingModel =
            //         Backbone.Collection.course_branding.get(chapterModel.get('course_branding_id'))
            //     if (courseBrandingModel && courseBrandingModel.get('logo_white')) {
            //         this.$('.js-methode-logo').append(courseBrandingModel.get('logo_white'))
            //     }
            // }

            // Add a header button to show the activity index. For students, it also shows progress.
            if (this.model.task_groups.length > 1) {
                this.progressButton = Backbone.View.header.addButton(
                    new HeroButton({
                        firstLine: (Backbone.Model.user.get('is_student') && this.model.tasks.length > 0) ?
                            window.i18n.gettext('Progress') :
                            window.i18n.gettext('Index'),
                        icon: 'orderedlist',
                        callback: () => {
                            this.showSidebarActivityNavigation()
                        }
                    })
                )
            }

            const dropdownItems = []

            if (Backbone.Model.user.get('is_teacher')) {
                dropdownItems.push({
                    label: window.i18n.gettext('Add to study planner'),
                    icon: 'planner',
                    callback: this.openAddToPlannerModal.bind(this)
                })
            }

            // This button is only for teachers with a linked Somtoday account
            if (Backbone.Model.user.get('is_teacher') && (_.contains(Backbone.Model.user.get('ConsumerIds'), 'Entree'))) {
                dropdownItems.push({
                    label: window.i18n.gettext('Place in Somtoday'),
                    icon: 'somtoday-color',
                    callback: this.openSomtodayWindow,
                })
            }

            dropdownItems.push({
                label: window.i18n.gettext('Make an annotation'),
                icon: 'annotations',
                callback: this.onClickMakeAnnotation,
                identifier: 'js-make-annotation-button'
            })

            if (Backbone.Model.user.get('is_student')) {
                dropdownItems.push({
                    label: window.i18n.gettext('Print'),
                    icon: 'print',
                    callback: this.onClickPrintWithoutAnswers
                })
            }

            if (Backbone.Model.user.get('is_teacher') && !Backbone.Model.user.get('is_demo')) {
                dropdownItems.push({
                    label: window.i18n.gettext('Print'),
                    icon: 'print',
                    subItems: [{
                        label: window.i18n.gettext('Print only text'),
                        callback: this.onClickPrintOnlySources,
                    }, {
                        label: window.i18n.gettext('Print without answers'),
                        callback: this.onClickPrintWithoutAnswers,
                    }, {
                        label: window.i18n.gettext('Print with answers'),
                        callback: this.onClickPrintWithAnswers
                    }]
                })
            }

            dropdownItems.push({
                label: window.i18n.gettext('About content'),
                icon: 'info-circle',
                callback: this.onClickAboutTaskGroup,
                identifier: 'js-about-task-group-button'
            })

            this.dropdownButton = Backbone.View.header.addButton(
                new HeroDropdown({
                    firstLine: window.i18n.gettext('Options'),
                    icon: 'arrow-drop-down-circle',
                    dropdownItems,
                })
            )

            if (Backbone.Model.user.get('is_teacher')) {
                this.presentationModeButton = Backbone.View.header.addButton(
                    new HeroButton({
                        callback: () => {
                            this.enterPresentationMode()
                        },
                        icon: 'presentation',
                        firstLine: window.i18n.gettext('Present'),
                    }), true
                )
                this.presentationModeButton.$el.hide()
            }

            // Listen for additions/deletions in the annotations collection for displaying the amount
            // of annotations for the current activity inside the annotations button.
            this.listenTo(this.model.getGroupModel().annotations, 'update', this.showAnnotationNumber);
            this.showAnnotationNumber();

            // Create keyup listener for navigation task groups with left and right arrow keys.
            $(document).on('keyup', this.onNavigationKeyUp);

            // Loop through navigation items and add right state
            this.addStateToNavigationItems();

        }

        // Navigate to task group specified by the URL parameter.
        if (options.currentWorkOnPageId === -1) {
            this.navigationBar.goToNavigationItem(_.last(this.navigationBar.getNavigatableViewList()));
        } else {
            var taskGroupIndex = this.model.task_groups.findIndex({id: options.currentWorkOnPageId});
            this.navigateToIndex(taskGroupIndex + 1);
        }

        /**
         * Scroll to task element!
         */
        _.defer(this.scrollToTask);

        // add pagination bar
        this.addPaginationNavigation();

        /**
         * Listen to new responses and changed responses to update the score and score
         * indicators (e.g. status color in the navigation bar) if show_scores is on
         */
        if (Backbone.Model.user.get('is_student') && this.model.get('show_answers') ) {

            this.listenTo(this.model.responses, 'change:score', responseModel => {
                this.updateScoreAndNavigationBar(responseModel.get('task_id'))
            })

        }

        // For students, add listeners to monitor whether they are active or not
        // This is used to measure their time worked
        if (Backbone.Model.user.get('is_student')) {

            // Monitor whether the page is visible
            document.addEventListener('visibilitychange', this.onTimeLogVisibilityChange)

            // Monitor whether students are interacting with the page
            // We use mousedown instead of click, because click may not reach the document level
            // Same idea for keydown instead of input
            this.userLastActive = Date.now()
            document.addEventListener('mousedown', this.setTimeLogUserActive)
            document.addEventListener('keydown', this.setTimeLogUserActive)
            document.addEventListener('scroll', this.setTimeLogUserActive)
            this.userLastActiveCheck = setInterval(this.checkTimeLogUserActive, 10 * 1000)
            Backbone.View.layout.on('editorLoaded', (tinymceId) => {
                const wysiwygEditor = window.tinymce.get(tinymceId)
                if (wysiwygEditor) {
                    wysiwygEditor.on('input', this.setTimeLogUserActive)
                    wysiwygEditor.on('mousedown', this.setTimeLogUserActive)
                }
            })
        }

        this.backgroundImage = this.addChildView(
            new BackgroundImage({
                imageUrl: this.model.getBackgroundImage()
            }),
            Backbone.View.layout.$el
        );
        this.backgroundImage.$el.hide()

    },

    enterPresentationMode() {
        this.addChildView(
            new PresentationMode({
                model: this.model,
                work_on: this,
                initialTaskGroup: this.activeItem.model,
            }),
            '.js-presentation-mode'
        )
        window.statsTracker.trackEvent(
            'activities/show',
            'Open present mode',
            _.size(this.model.tasks) ?
                `${this.model.tasks.reduce((m, taskModel) => {
                    if (this.model.responses.some({task_id: taskModel.id})) {
                        m++
                    }
                    return m
                }, 0) / this.model.tasks.length * 100}%` :
                'no tasks'
        )
    },

    /**
     * @param {number} taskId task id
     */
    updateScoreAndNavigationBar(taskId) {
        if (!this.model.get('showScores')) {
            return
        }

        // update navigation bar
        this.addStateToNavigationItems()

        // mark answer/score as viewed
        this.setTasksAreViewedByStudent()

        if (!this.activeItem.taskViews) {
            return
        }

        const taskView = this.activeItem.taskViews.find(view => view.model.id === taskId)

        // in case user navigated to the previous/next task group
        if (taskView) {
            taskView.openGradeScore()
        }

    },

    // Callback for clicking "show scores" button
    onClickShowScoresButton(newState) {

        this.model.set('showScores', newState)

        this.addStateToNavigationItems()

        this.toggleShowScores(this.model.get('showScores'))

        if (this.model.get('showScores')) {

            this.setTasksAreViewedByStudent()

            window.statsTracker.trackEvent(
                'activities/show',
                'show scores enabled'
            )
        }
    },

    /**
     * @param {boolean} showScores show/hide all score
     */
    toggleShowScores(showScores) {
        this.activeItem.taskViews.forEach(view => {
            showScores ?
                view.openGradeScore() :
                view.hideGradeScore()
        })
    },

    addShowScoresButton() {

        if (
            Backbone.Model.user.get('is_teacher') ||
            !this.model.get('show_answers')
        ) {
            return
        }

        this.showScoresButton = Backbone.View.header.addButton(
            new HeroButton({
                firstLine: window.i18n.gettext('Show scores'),
                showBadge: true,
                callback: () => this.showScoresToggleSwitch.toggleState()
            })
        )

        this.showScoresToggleSwitch = this.addSvelteChildView(this.showScoresButton.$('.js-badge'), ToggleSwitch, {
            isChecked: false,
            callback: (newState) => this.onClickShowScoresButton(newState),
            darkMode: true
        })

        this.showScoresButton.$el.hide()
    },

    showShowScoresButton() {
        if (!this.showScoresButton) {
            return
        }

        this.showScoresButton.$el.show()
    },

    /**
     * addNavigationBar
     *
     * Create navigationBar for a linear activity.
     */
    addNavigationBar() {

        // Create navigationBar using task_groups of this activity.
        this.navigationBar = this.addChildView(
            new NavigationBar({
                type: 'linear',
                collection: this.model.task_groups,
                model: this.model
            }),
            Backbone.View.layout.$el
        );

        // Listen for navigating to a new item.
        this.listenTo(
            this.navigationBar,
            'newActiveItem',
            // Execute loading active item only when the 'newActiveItem' event hasn't triggered for 0ms to prevent
            // redirects from taking too much time and messing up the browsers history (LEAR-123).
            _.debounce(this.onLoadNewActiveItem)
        );

        // Initialize navigationBar.
        this.navigationBar.start();

    },

    /**
     * scrollToTask
     *
     * Scroll to selected task if url has hash with id
     */
    scrollToTask() {
        let taskId;

        if (window.location.hash.length > 0) {
            taskId = window.location.hash.split('#')[1];
        }

        if (!taskId) {
            return;
        }

        // give images time to load en reflow the page before scrolling to task.
        setTimeout(() => {
            const task = document.querySelector(`[data-task-id="${taskId}"]`);

            if (!task) {
                return;
            }
            const { top } = task.getBoundingClientRect();
            // offset top of the task element with 10 pixels to not cut off the circle with index number.
            window.scrollTo(0, top + window.pageYOffset - 10);

        }, 1000);
    },
    /**
     * onNavigationKeyUp
     *
     * Listen for keyup for left and right arrow keys for navigating
     * between task groups.
     *
     * @param  {jQuery.event} e     jQuery keyup event
     */
    onNavigationKeyUp(e) {

        // Do not respond when focus input element has focus, fullscreen is active or a modifier key is pressed.
        if (Util.shouldPreventDocumentLevelKeyEvent(e)) {
            return
        }

        // ← previous task group, → next task group, 'w' go to author (only for content team)

        if (e.keyCode === 37 || e.key === 'ArrowLeft') {
            this.navigationBar.previousItem();
        } else if (e.keyCode === 39 || e.key === 'ArrowRight') {
            this.navigationBar.nextItem();
        } else if (Backbone.Model.user.getSetting('IsAvailableForContentTeam') && e.key === 'w') {
            // make sure it's a task group
            if (!(this.activeItem instanceof TaskGroup)) {
                return
            }
            Backbone.history.navigate(
                `/task_groups/author/${ this.model.id}/${ this.activeItem.model.id }`,
                { trigger: true }
            )

        }
    },

    /**
     * onClickPaginationBtn
     *
     * Listen for click events on the pagination controls at the bottom
     * of the page for navigating between task groups.
     *
     * @param  {Event} e     Click event
     */
    onClickPaginationBtn(e) {
        const direction = e.currentTarget.dataset.moveDirection
        if (direction === 'previous') {
            this.navigationBar.previousItem();
        } else if (direction === 'next') {
            this.navigationBar.nextItem();
        }
    },

    /**
     * navigateToIndex
     *
     * Goto page by navigation item index.
     *
     * @param  {number} index   integer index to navigate to
     */
    navigateToIndex(index) {
        this.navigationBar.goToNavigationItem(this.navigationBar.findItemByIndex(index));
    },

    /**
     * onLoadNewActiveItem
     *
     * This method will be called when there is a new active item. It will
     * switch between taskgroups sliding out the previous and sliding in the
     * next taskgroup
     *
     * @param  {Backbone.View} itemView         The clicked or moved to item
     * @param  {Integer} direction              The direction to which we are moving
     * @param  {Backbone.View} previousItem     The previous item
     */
    onLoadNewActiveItem(itemView, direction, previousItem) {

        if (ISMOBILE) {
            this.setTaskGroupIndex();
        } else {

            // Loop through navigation items and add right state
            this.addStateToNavigationItems();
        }

        // Get the activity viewport container
        var taskGroupContainer = this.$('.js-taskgroup');

        // Close sidebar if needed
        Backbone.View.sidebar.closeSidebar();

        // Get the animation distance for the x-axis
        var animateXTo = (
            (
                // First check if the direction is bigger then 0. Directions explaned:
                // positive direction (f.e. 1)
                //      means the clicked/navigated item is higher than
                //      the current active item
                //
                // negative direction (f.e. -1)
                //      means the clicked/navigated item is lower then
                //      the current active item
                (direction > 0 ? -1 : 1)
            ) * (

                // Get the window's width to be sure we animate the activity out of the
                // window add 100% of this width to it to make animation more fluid
                ($(window).width() * 2)
            )
        );

        // Hide horizontal scrollbar while doing the transition animation
        this.$el.css('overflow-x', 'hidden');

        // Start the animation on the activity viewport
        TweenMax.to(this.$('.js-activity-viewport'), {

            // Animate the x to the determined x distance
            x: animateXTo,

            duration: 0.15,
            ease: 'expo.inOut',

            // When animation is complete
            onComplete: () => {

                // Check if there is an active taskGroup
                if (this.activeItem) {

                    // remove as childview
                    this.unregisterChildView(this.activeItem);

                    // Destroy
                    this.activeItem.destroy();
                }

                // Send previous time log (if present) and start time log for new task group
                this.model.stopTimeLog()
                this.model.startTimeLog(itemView.model)

                // Check if the type is an item, then it's one from the collection which
                // means in this case that it's a taskgroup. So parse a new item taskgroup
                if (itemView.itemType === 'item') {

                    // Parse the new item as taskgroup
                    this.parseNewItemTaskgroup(itemView, previousItem);

                    // Check if the type is an icon. When this is the case we don't know what
                    // to do as action yet. But we can ask the icon what action should be
                    // executed.
                } else if (itemView.itemType === 'icon') {
                    // Parse the summary view
                    this.parseSummary(itemView.getAction());

                }

                // Scroll back to top of page after first half of the page transition is completed.
                $(window).scrollTop(0);

                // Add the taskgroup to the taskGroupContainer
                taskGroupContainer.html(this.activeItem.$el);

                // Check if there is a show method
                if (this.activeItem.show) {

                    // Call the show method
                    this.activeItem.show();
                }

                // Start a new animation to slide the new taskgroup in
                TweenMax.fromTo(this.$('.js-activity-viewport'), {

                    // Start at a reversed position of x
                    x: -animateXTo,

                    duration: 0.15,
                    ease: 'expo.inOut'

                }, {

                    onComplete: () => {

                        // Remove transform attribute since it messes stuff up
                        this.$el.css('overflow-x', '');
                        this.$('.js-activity-viewport').css('transform', '');

                        // Check if there is a onInView method
                        if (this.activeItem.onInView) {

                            // Call the onInView method
                            this.activeItem.onInView();
                        }
                    },

                    // Go to a zero position
                    x: 0
                });
            }
        });

    },

    /**
     * addStateToNavigationItems
     *
     * Loops through navigation items and adds a state.
     *
     */
    addStateToNavigationItems() {

        // Set score of all navigation items.
        this.navigationBar.getItemViewList().forEach(this.activityShow.addStateToItem)
    },

    /**
     * setTaskGroupIndex
     *
     * Update the index of the task group compared to all taskgroups in the activity in the DOM
     *
     */
    setTaskGroupIndex() {

        var index = this.navigationBar.activeItem.index;

        if (
            // Don't change the index if it has become larger than the
            // total length of task groups in the activity or zero.
            // At this point we are rendering the summary screen
            index <= this.navigationBar.collection.length &&
            index > 0
        ) {
            this.$('.js-taskgroup-index').html(
                index + '/' + this.navigationBar.collection.length
            );
        }
    },

    /**
     * parseNewItemTaskgroup
     *
     * This method will parse a new taskgroup and store it within the global
     * active item variable.
     *
     * @param  {Backbone.View} newItem  Taskgroup item from the navigation bar
     */
    parseNewItemTaskgroup(newItem) {

        Backbone.View.header.setCrumblepath(
            newItem.model.getAllCrumblepathModels(),
            'show'
        )

        Backbone.View.header.setBackgroundImage(this.model)

        // Show a message when a deadline has been set and it has expired
        if (this.model.deadline.hasExpired()) {
            if (!this.deadlineMessage) {
                let cardContent = window.i18n.gettext('The deadline has expired. You cannot give answers.')

                if (Backbone.Model.user.get('role') === 'teacher') {
                    const now = new dayjs.utc()
                    let futureExceptionsCount = 0
                    for (const exception of this.model.deadline.get('exceptions')) {
                        if (new dayjs.utc(exception.deadline).isAfter(now)) {
                            futureExceptionsCount++
                        }
                    }

                    cardContent = window.i18n.gettext('The deadline has expired. Your students cannot give answers.')
                    if (futureExceptionsCount > 0) {
                        cardContent += ' ' + window.i18n.sprintf(
                            window.i18n.ngettext(
                                '%s student has an exception.',
                                '%s students have an exception.',
                                futureExceptionsCount
                            ),
                            futureExceptionsCount
                        )
                    }
                }

                this.deadlineMessage = this.addChildView(
                    new StatusCard({
                        icon: 'info-circle',
                        statusColor: 'blue',
                        cardContent
                    }),
                    '.js-deadline-message'
                )
            }

            // Only show the message when the taskgroup contains tasks
            if (newItem.model.tasks.length > 0) {
                this.deadlineMessage.el.style.display = ''
            } else {
                this.deadlineMessage.el.style.display = 'none'
            }
        }

        // Hide course branding (methode) logo to make room for dropdown.
        // this.$('.js-methode-logo').hide();
        this.dropdownButton?.$el.show();
        this.dropdownButton?.$('.js-about-task-group-button').show()
        this.presentationModeButton?.$el.show()

        // Show the header button for progress per task group
        this.progressButton?.$el.show()

        // Show bottom pagination controls.
        this.$('.js-pagination-bar').show();

        // Create a new taskGroup
        this.activeItem = new TaskGroup({
            work_on: this,
            model: newItem.model
        });

        // Register taskgroup as childview
        this.registerChildView(this.activeItem);

        // Set the navigation url to pass the taskgroup id. This is usefull for debuggin
        Backbone.history.navigate(

            `/activities/show/${this.model.id}/${newItem.model.id}`,
            {trigger: false}

        );

        this.showShowScoresButton()

        /**
         * Toggle all scores if student has enabled "show scores".
         * For every task in current task group set "has_seen_model"
         */
        if (this.model.get('showScores')) {
            this.toggleShowScores(true)
            this.setTasksAreViewedByStudent()
        }

        this.backgroundImage.$el.hide()

    },

    /**
     * Set every rated task of active task group as seen
     * (has_seen_model_answer -> true) if  that's not the
     * case yet
     */
    setTasksAreViewedByStudent() {

        if (!this.activeItem.taskViews) {
            return
        }

        for (const taskView of this.activeItem.taskViews) {
            if (taskView.response.get('score') === -1) {
                continue
            }

            taskView.response.hasSeenModelAnswer()

        }

    },

    parseSummary(summaryType) {

        // Hide bottom pagination controls when in Summary view
        this.$('.js-pagination-bar').hide();

        if (!(this.model.has('standalone') && Backbone.Model.user.get('is_student'))) {

            Backbone.View.header.setCrumblepath(
                this.model.getAllCrumblepathModels(),
                'show'
            )
            Backbone.View.header.removeBackgroundImage()
        }

        // Hide dropdown to make room for the course branding (methode) logo.
        // this.$('.js-methode-logo').show();

        // Hide or show the header button for progress per task group and for options
        if (summaryType === 'summary-start') {
            this.progressButton?.$el.show()
            this.dropdownButton?.$el.show()
        } else {
            this.progressButton?.$el.hide()
            this.dropdownButton?.$el.hide()
        }

        // Hide 'about task group' on summary
        this.dropdownButton?.$('.js-about-task-group-button').hide()

        this.presentationModeButton?.$el.hide()

        // Hide license expired message on summary page
        if (this.licenseMessage) {
            this.licenseMessage.$el.hide()
        }

        // Hide deadline expired message on summary page
        if (this.deadlineMessage) {
            this.deadlineMessage.el.style.display = 'none'
        }

        // Create custom options depending if the summary comes at the start or end of the activity.
        var summaryOptions = {
            work_on: this,
            model: this.model
        };
        if (summaryType === 'summary-start') {
            Backbone.history.navigate('/activities/show/' + this.model.id, {trigger: false});
            summaryOptions.subjectsTitle = window.i18n.gettext('Which subjects will be tested?');
            summaryOptions.showFrameworks = true;
            summaryOptions.showTaskCount = true;
            summaryOptions.showStartBtn = true;
        } else if (summaryType === 'summary-end') {
            Backbone.history.navigate('/activities/show/' + this.model.id + '/-1', {trigger: false});
            summaryOptions.subjectsTitle = window.i18n.gettext('Which subjects have been tested?');
            summaryOptions.showFinishBtn = true;
        }
        this.activeItem = new Summary(summaryOptions);
        this.registerChildView(this.activeItem);

        // hide show scores button
        if (this.showScoresButton) {
            this.showScoresButton.$el.hide()
        }

        this.backgroundImage.$el.show()

    },

    /**
     * showAnnotationNumber
     *
     * Show number of annotations for current activity in the annotationBtn hero button badge.
     */
    showAnnotationNumber() {

        if (!ACL.isAllowed(ACL.resources.ANNOTATIONS, ACL.actions.ADD)) {
            return
        }

        const activityAnnotations = this.model.getGroupModel().annotations?.where({activity_id: this.model.id})
        this.dropdownButton?.$('.js-make-annotation-button .js-dropdown-item-label').text(
            window.i18n.gettext('Make an annotation') + ' (' + _.size(activityAnnotations) + ')'
        )
    },

    /**
     * onClickMakeAnnotation
     *
     * Open annotation sidebar using the context of the current activity or task group.
     */
    onClickMakeAnnotation() {

        var sidebarContent = new AnnotationSidebar([{
            activityId: this.model.id,
            taskGroupId: this.activeItem instanceof TaskGroup ? this.activeItem.model.id : 0,
            sectionId: this.model.get('section_id'),
            chapterId: this.model.getChapterModel().id,
            schoolSchoolclassCourseId: this.model.getGroupModel().id,
            taskGroupSequence: this.activeItem instanceof TaskGroup ? this.activeItem.model.get('index') - 1 : 0,
        }]);

        var title = Backbone.Model.user.get('is_teacher') ?
            window.i18n.gettext('Make an annotation for the whole class') :
            window.i18n.gettext('Make an annotation');

        var width = 600;

        this.registerChildView(sidebarContent);

        Backbone.View.sidebar.showSidebar(sidebarContent, title, width);

    },

    /**
     * onClickSelectPrintVersion
     *
     * Expand or collapse print options in dropdown menu.
     */
    onClickSelectPrintVersion() {
        var printSelectElement = this.$('.js-print-expanded');
        if (printSelectElement.is(':visible')) {
            printSelectElement.hide();
        } else {
            printSelectElement.show();
        }
    },

    /**
     * onClickPrintWithoutAnswers
     *
     * Download PDF of activity with only the unique sources.
     */
    onClickPrintOnlySources() {
        window.open(
            '/activities/print/' + this.model.id + '/2',
            '_blank'
        );
        window.statsTracker.trackEvent(
            'activities/show',
            'print',
            'print only text'
        )
    },

    /**
     * onClickPrintWithAnswers
     *
     * Download PDF of activity with correct answers. Only for teachers.
     */
    onClickPrintWithAnswers() {
        window.open(
            '/activities/print/' + this.model.id + '/1',
            '_blank'
        );
        window.statsTracker.trackEvent(
            'activities/show',
            'print',
            'print with answers'
        )
    },

    /**
     * onClickPrintWithoutAnswers
     *
     * Download PDF of activity without the correct answers.
     */
    onClickPrintWithoutAnswers() {
        window.open(
            '/activities/print/' + this.model.id + '/0',
            '_blank'
        );
        window.statsTracker.trackEvent(
            'activities/show',
            'print',
            'print without answers'
        )
    },

    /**
     * onClickAboutTaskGroup
     *
     * Open sidebar with additional information about the current activity.
     */
    onClickAboutTaskGroup() {
        Backbone.View.sidebar.showSidebar(
            new SidebarAboutTaskGroup({model: this.activeItem.model}),
            window.i18n.gettext('About this task group'),
            500
        );
    },

    /**
     * onClickTaskGroupIndex
     *
     * Handler function triggered when user clicks on a taskgroup index
     */
    onClickTaskGroupIndex() {
        this.showSidebarActivityNavigation();
    },

    /**
     * showSidebarActivityNavigation
     *
     * This function will open a sidebar that lets the user navigate inside the activity
     */
    showSidebarActivityNavigation() {
        Backbone.View.sidebar.showSidebar(
            new SidebarActivityNavigation({
                model: this.model,
                work_on: this
            }),
            (Backbone.Model.user.get('is_student') && this.model.tasks.length > 0) ?
                window.i18n.gettext('Progress') :
                window.i18n.gettext('Index'),
            500
        );
    },

    /** onDestroy
     *
     * When this view is destroyed, unbind the navigation keys and remove the
     * is-work-on styling from the body.
     */
    beforeDestroy() {

        // Send time of previous time log (if present)
        this.model.stopTimeLog()

        this.removeEvents();

        $('body').removeClass('is-work-on is-work-on-exam is-work-on-exam-seb-launch');

        Backbone.View.menubar?.setPreferredSize()

        Backbone.View.header?.removeBackgroundImage()
    },

    /**
     * removeEvents
     *
     * Unbind the navigation keys, exam report and time log event listeners.
     */
    removeEvents() {
        $(document).off('keyup', this.onNavigationKeyUp)

        if (Backbone.Model.user.get('is_student')) {
            document.removeEventListener('paste', this.onPaste)
            document.removeEventListener('visibilitychange', this.onVisibilityChange)
            document.removeEventListener('visibilitychange', this.onTimeLogVisibilityChange)
            document.removeEventListener('mousedown', this.setTimeLogUserActive)
            document.removeEventListener('keydown', this.setTimeLogUserActive)
            document.removeEventListener('scroll', this.setTimeLogUserActive)
            clearInterval(this.userLastActiveCheck)

            // Enable the context menu and copy action again after reviewing an exam
            document.removeEventListener('contextmenu', this.onContextMenu)
            document.removeEventListener('copy', this.onCopy)
        }
    },

    addPaginationNavigation() {
        this.addChildView(
            new PaginationNavigation({
                previousUrlOrCallback: this.navigationBar.previousItem,
                previousLabel: window.i18n.gettext('Previous'),

                nextUrlOrCallback: this.navigationBar.nextItem,
                nextLabel:
                    ACL.isAllowed(ACL.resources.RESPONSES, ACL.actions.ADD)
                        ? window.i18n.gettext('Save and continue')
                        : window.i18n.gettext('Next'),
                isActivity: true,
                previousTheme: 'secondary',
                nextTheme: 'secondary',
            }),
            '.js-pagination-bar'
        );
    },

    /**
     * Open modal where the teacher can add the current activity to the study planner directly at a week and item
     * of their choice.
     *
     */
    openAddToPlannerModal() {
        import(
            /* webpackChunkName: "planner-groups" */
            'views/pages/activities/show/modals/addToPlanner/AddToPlanner'
        ).then(AddToPlannerModal => {
            Backbone.View.Components.modal.open(AddToPlannerModal.default, {
                title: window.i18n.gettext('Add to study planner'),
                model: this.model
            })
        })
    },

    /**
     * Open window to link current activity to Somtoday's study planner.
     * This function contains the minimal amount of the script
     * somtoday provides to link material. The original script also
     * provides styling, which we don't need
     *
     * See:
     * https://share.connect.somtoday.nl/ for the description and see
     * https://share.connect.somtoday.nl/assets/share.js for the javascript
     */
    openSomtodayWindow() {

        const somtodayBaseUrl = `https://${PRODUCTION ? 'docent' : 'docent.acceptatie'}.somtoday.nl/share/link?q=`
        const activityUrl = `${window.location.protocol}//${window.location.host}/activities/show/${this.model.id}`

        // create url safe base64 string of the acivity url
        const encodedActivityUrl = encodeURIComponent(btoa(activityUrl))

        window.open(`${somtodayBaseUrl}${encodedActivityUrl}`, 'somtoday-window', 'width=500,height=648')

        // track the click of the user
        window.statsTracker.trackEvent(
            window.app.controller.activePath.join('/'),
            'placeInSomtoday'
        )
    },

    // When the page becomes visible/invisible, update the time log
    onTimeLogVisibilityChange() {

        // The page became visible, start a new time log for the current task group (if not present)
        if (document.visibilityState === 'visible' && this.activeItem) {
            this.model.startTimeLog(this.activeItem.model)
        }

        // The page became invisible, terminate the current time log if present
        if (document.visibilityState === 'hidden') {
            this.model.stopTimeLog()
        }
    },

    // Store when the user was last active for the time log
    setTimeLogUserActive() {
        this.userLastActive = Date.now()
    },

    // Start/stop the time log based on user activity
    checkTimeLogUserActive() {

        // The user is considered active when there was activity in the last 60 seconds
        const userIsActive = (Date.now() - this.userLastActive < 60 * 1000)

        // If the user is not active, stop the time log (if present)
        if (!userIsActive) {
            this.model.stopTimeLog()
        }

        // If the user is active and the page is visible,
        // start a time log for the current task group (if not present)
        if (userIsActive && document.visibilityState === 'visible' && this.activeItem) {
            this.model.startTimeLog(this.activeItem.model)
        }

    },

})
