import Styles from './Toolbar.scss';

import Template from './Toolbar.hbs';
import BoldButton from 'views/components/wysiwyg/parts/buttons/bold/Bold';
import ColorsButton from 'views/components/wysiwyg/parts/buttons/colors/Colors';
import ItalicButton from 'views/components/wysiwyg/parts/buttons/italic/Italic';
import UnderlineButton from 'views/components/wysiwyg/parts/buttons/underline/Underline';
import HeadingButton from 'views/components/wysiwyg/parts/buttons/heading/Heading';
import IndentButton from 'views/components/wysiwyg/parts/buttons/indent/Indent';
import OutdentButton from 'views/components/wysiwyg/parts/buttons/outdent/Outdent';
import UnorderedlistButton from 'views/components/wysiwyg/parts/buttons/unorderedlist/Unorderedlist';
import OrderedlistButton from 'views/components/wysiwyg/parts/buttons/orderedlist/Orderedlist';
import QuoteButton from 'views/components/wysiwyg/parts/buttons/quote/Quote';
import AsideButton from 'views/components/wysiwyg/parts/buttons/aside/Aside';
import LinkButton from 'views/components/wysiwyg/parts/buttons/link/Link';
import ImageButton from 'views/components/wysiwyg/parts/buttons/image/Image';
import TableButton from 'views/components/wysiwyg/parts/buttons/table/Table';
import ColumnButton from 'views/components/wysiwyg/parts/buttons/column/Column';
import SymbolsButton from 'views/components/wysiwyg/parts/buttons/symbols/Symbols';
import ExpressionButton from 'views/components/wysiwyg/parts/buttons/expression/Expression';
import SubscriptButton from 'views/components/wysiwyg/parts/buttons/subscript/Subscript';
import SuperscriptButton from 'views/components/wysiwyg/parts/buttons/superscript/Superscript';
import SourceButton from 'views/components/wysiwyg/parts/buttons/source/Source';
import SimplifyButton from 'views/components/wysiwyg/parts/buttons/simplify/Simplify';

export default class WysiwygToolbar extends BaseView {

    // Toolbar button presets for combinations of buttons that are used more than once.
    // See documentation in Confluence for a table of which toolbar buttons can be used in which context:
    // https://dedact.atlassian.net/l/c/wqa65uqW

    // Default toolbar button set.
    static get default() {
        return [
            'bold',
            'italic',
            'underline',
            'heading',
            'unorderedlist',
            'orderedlist',
            'outdent',
            'indent',
            'quote',
            'aside',
            'link',
            'image',
            'table',
            'column',
            'symbols',
            'expression',
            'subscript',
            'superscript',
            'source'
        ]
    }

    // Toolbar button set for task group introduction.
    static get taskGroupsIntroduction() {
        return [
            'bold',
            'italic',
            'underline',
            'unorderedlist',
            'orderedlist',
            'outdent',
            'indent',
            'link',
            'table',
            'symbols',
            'expression',
            'subscript',
            'superscript',
            'source'
        ]
    }

    // Toolbar button set for source 6 (Text with line numbers).
    static get taskGroupsSource6Edit() {
        return [
            'bold',
            'italic',
            'underline',
            'heading',
            'unorderedlist',
            'orderedlist',
            'image',
            'aside',
            'symbols',
            'subscript',
            'superscript',
            'source'
        ]
    }

    // Toolbar button set for source 12 (Text).
    static get taskGroupsSource12Edit() {
        return [
            'bold',
            'italic',
            'underline',
            'heading',
            'unorderedlist',
            'orderedlist',
            'outdent',
            'indent',
            'quote',
            'aside',
            'link',
            'image',
            'table',
            'column',
            'symbols',
            'expression',
            'subscript',
            'superscript',
            'source',
            'simplify'
        ]
    }

    // Toolbar button set for template 33 (Text selection).
    static get taskGroupsTemplate33Edit() {
        return [
            'bold',
            'italic',
            'underline',
            'heading',
            // TODO fix bad behavior lists in Template33.
            // 'unorderedlist',
            // 'orderedlist',
            'image',
            'symbols',
            'subscript',
            'superscript'
        ]
    }

    // Toolbar button set for template 34 (Text order).
    static get taskGroupsTemplate34Edit() {
        return [
            'bold',
            'italic',
            'underline',
            'heading',
            'unorderedlist',
            'orderedlist',
            'outdent',
            'indent',
            'image',
            'symbols',
            'subscript',
            'superscript'
        ]
    }

    // Toolbar button set for new item in planner.
    static get addToPlanner() {
        return [
            'bold',
            'italic',
            'underline',
            'unorderedlist',
            'orderedlist'
        ]
    }

    // Toolbar button set for task introduction.
    static get taskIntroduction() {
        return [
            'italic',
            'underline',
            'unorderedlist',
            'orderedlist',
            'outdent',
            'indent',
            'link',
            'table',
            'symbols',
            'expression',
            'subscript',
            'superscript',
            'source'
        ]
    }

    // Toolbar button set for task answer explanation.
    static get taskExplanation() {
        return [
            'bold',
            'italic',
            'underline',
            'unorderedlist',
            'orderedlist',
            'outdent',
            'indent',
            'link',
            'image',
            'table',
            'symbols',
            'expression',
            'subscript',
            'superscript',
            'source'
        ]
    }

    // Toolbar button set for annotation editor.
    static get annotations() {
        return [
            'bold',
            'italic',
            'underline',
            'heading',
            'unorderedlist',
            'orderedlist',
            'outdent',
            'indent',
            'link',
            'image',
            'table',
            'symbols',
            'expression',
            'subscript',
            'superscript'
        ]
    }

    // Toolbar button set for template 2 (open question) with formatting allowed.
    static get taskGroupsTemplate2Formatted() {
        return [
            'bold',
            'italic',
            'underline',
            'unorderedlist',
            'orderedlist',
            'outdent',
            'indent',
            'table'
        ]
    }

    // Toolbar button set for template 2 (open question) with equations allowed.
    static get taskGroupsTemplate2Equations() {
        return [
            'expression',
            'subscript',
            'superscript',
            'symbols'
        ]
    }

    // It is possible to have both the formatted and equations options for the template 2 (open question).
    static get taskGroupsTemplate2Al() {
        return [
            'bold',
            'italic',
            'underline',
            'unorderedlist',
            'orderedlist',
            'outdent',
            'indent',
            'table',
            'expression',
            'subscript',
            'superscript',
            'symbols'
        ]
    }

    // Toolbar button set for notes.
    static get sendNote() {
        return [
            'bold',
            'italic',
            'underline',
            'unorderedlist',
            'orderedlist',
            'link',
            'symbols',
            'subscript',
            'superscript'
        ]
    }

    // Toolbar button set for study planner.
    static get studyPlanner() {
        return [
            'bold',
            'italic',
            'underline',
            'unorderedlist',
            'orderedlist',
            'link'
        ]
    }

    // Toolbar button set for the component description in a competencies activity
    static get competenciesDescription() {
        return [
            'bold',
            'italic',
            'unorderedlist',
            'orderedlist',
            'outdent',
            'indent'
        ]
    }

    get events() {
        return {
            'click .js-more-button': 'onClickOpenMoreButtons'
        }
    }

    buttonOptions = {
        bold: BoldButton,
        colors: ColorsButton,
        italic: ItalicButton,
        underline: UnderlineButton,
        heading: HeadingButton,
        unorderedlist: UnorderedlistButton,
        orderedlist: OrderedlistButton,
        outdent: OutdentButton,
        indent: IndentButton,
        quote: QuoteButton,
        aside: AsideButton,
        link: LinkButton,
        image: ImageButton,
        table: TableButton,
        column: ColumnButton,
        symbols: SymbolsButton,
        expression: ExpressionButton,
        subscript: SubscriptButton,
        superscript: SuperscriptButton,
        source: SourceButton,
        simplify: SimplifyButton
    }

    initialize({
        wysiwyg
    }) {

        this.buttons = {}

        this.bindAll([
            'addButton',
            'getButtons',
            'loadButtons',
            'checkIfToolButtonsFits'
        ])

        this.wysiwyg = wysiwyg

        this.setElement(Template({
            Styles
        }));

        this.showMoreButton = this.$('.js-more-button')
        this.buttonsHolder = this.$('.js-buttons-holder')
    }

    onClickOpenMoreButtons() {
        // display extra row of toolbar buttons
        this.$el.toggleClass(Styles['toolbar--expanded'])
    }

    setDisabled(disabled) {
        this.isDisabled = (disabled !== undefined) ? !!disabled : !this.isDisabled;
        this.$el.toggleClass(Styles['toolbar--disabled'], this.isDisabled);
    }

    /**
     * registerButton
     *
     * Function to register a new button to the toolbar. This can be used when
     * the toolbar is added to a view that needs a button that shouldn't be
     * available for views with this toolbar that includes all registered buttons
     *
     * @param  {string} name            String to name the specific button
     * @param  {Backbone.View} view     Initializable view
     */
    registerButton(name, view) {
        // Register the button to the button options
        this.buttonOptions[name] = view;
    }

    loadButtons(specificButtons = []) {
        const buttonsToAdd = specificButtons.length ? specificButtons : WysiwygToolbar.default

        buttonsToAdd.forEach((name) => {
            this.addButton(this.buttonOptions[name], name)
        })

        // After the buttons are added, check if the toolbar fits on resize
        if ('ResizeObserver' in window) {
            const observer = new ResizeObserver(() => {
                window.requestAnimationFrame(() => {
                    this.checkIfToolButtonsFits()
                })
            })
            observer.observe(this.el)
        } else {
            this.checkIfToolButtonsFits()
        }

    }

    // Add a new button to the toolbar, this wil add it to the DOM and to
    // the holder.
    addButton(ButtonView, name) {
        if (this.buttons.hasOwnProperty(name)) {
            return
        }

        this.buttons[name] = this.addChildView(
            new ButtonView({
                wysiwyg: this.wysiwyg
            }), this.buttonsHolder
        )
    }

    getButtons() {
        // Return the holder with all the buttons
        return this.buttons;
    }

    checkIfToolButtonsFits() {
        const buttonArray = Object.values(this.buttons)

        if (buttonArray.length === 0) {
            return false
        }

        // Obtain outer width of the button to show more buttons and a single toolbar button, and also the inner
        // width of the whole toolbar.
        const firstButton = this.$('.js-btn')
        if (firstButton.length === 0) {
            return false
        }
        const buttonWidth = firstButton.outerWidth(true)
        const moreButtonWidth = this.showMoreButton.outerWidth(true)
        const toolbarStyle = getComputedStyle(this.el)
        const toolbarWidth = this.el.clientWidth
            - parseFloat(toolbarStyle.paddingLeft) - parseFloat(toolbarStyle.paddingRight)

        // First check if the all the buttons would if all buttons would fit on the toolbar. If so, the show more
        // toggle button needs not to be shown. Otherwise calculate how many buttons would fit in the toolbar at
        // its current width minus the show more button.
        const numberOfButtonsThatFit = Math.max(
            0,
            buttonArray.length > toolbarWidth / buttonWidth ?
                Math.floor((toolbarWidth - moreButtonWidth) / buttonWidth) :
                buttonArray.length
        )

        // If all buttons fit within the current width of the toolbar, hide the show more button.
        this.showMoreButton.toggle(numberOfButtonsThatFit < buttonArray.length)

        // Append all buttons to the visibile part of the menu bar by detaching them from where ever element they
        // are attached to now.
        buttonArray.forEach((buttonView) => {
            this.buttonsHolder.append(
                buttonView.$el.detach()
            )
        })

        // Starting from the index equal to the amount of buttons that fit on the first part of the toolbar, append
        // those buttons to the hidden buttons holder and detach them from where ever element they attached to.
        for (let index = numberOfButtonsThatFit; index < buttonArray.length; index++) {
            this.$('.js-hidden-buttons-holder').append(
                buttonArray[index].$el.detach()
            )
        }

    }

}
