import SharedController from 'controller/SharedController';

// Login views
import Login from 'views/pages/users/login/Login';

// General user views
import UserHome from 'views/pages/users/home/Home';
import UserNotes from 'views/pages/users/notes/Notes';

// Show (content) views
import GroupShow from 'views/pages/groups/show/Show';
import ChapterShow from 'views/pages/chapters/show/Show';
import SectionShow from 'views/pages/sections/show/Show';
import ActivityShow from 'views/pages/activities/show/Show';

export default class Controller extends SharedController {

    constructor() {

        super();

        // Create a holder for the active path
        this.activePath = [];
        this.activeParams = []

        this.urlMap = {
            users: {
                login: Login,
                home: UserHome,
                settings: () => {
                    return import(
                        /* webpackChunkName: "settings-users" */
                        'views/pages/users/settings/Settings'
                    );
                },
                notes: UserNotes,
                join: () => {
                    return import(
                        /* webpackChunkName: "signup-register" */
                        'views/components/register/steps/student/addJoinCode/addJoinCode'
                    )
                },
                register_join_code: () => {
                    return import(
                        /* webpackChunkName: "signup-register" */
                        'views/components/register/steps/student/addJoinCode/addJoinCode'
                    )
                },
                register_validation_mail_sent: () => {
                    return import(
                        /* webpackChunkName: "signup-register" */
                        'views/components/register/steps/generic/validationMailSent/ValidationMailSent'
                    )
                },
                welcome: () => {
                    return import(
                        /* webpackChunkName: "signup-register" */
                        'views/components/register/steps/generic/welcome/Welcome'
                    )
                },
                welcome_teacher: () => {
                    return import(
                        /* webpackChunkName: "signup-register" */
                        'views/components/register/steps/generic/welcome/cards/newTeacher/WelcomeTeacher'
                    )
                },
                register_name: () => {
                    return import(
                        /* webpackChunkName: "signup-register" */
                        'views/components/register/steps/generic/registerName/RegisterName'
                    )
                },
                reset_password: () => {
                    return import(
                        /* webpackChunkName: "signup-register" */
                        'views/components/register/steps/generic/resetPassword/ResetPassword'
                    )
                }
            },
            sign_up: {
                pick_course: () => {
                    return import(
                        /* webpackChunkName: "signup-pick-course" */
                        'views/pages/signUp/pickCourse/PickCourse'
                    )
                },
                setup: () => {
                    return import(
                        /* webpackChunkName: "signup-register" */
                        'views/pages/signUp/setup/Setup'
                    )
                },
                demo_setup: () => {
                    return import(
                        /* webpackChunkName: "signup-register" */
                        'views/components/register/steps/teacher/demoSetup/DemoSetup'
                    )
                }
            },
            groups: {
                author: () => {
                    return import(
                        /* webpackChunkName: "author-groups" */
                        'views/pages/groups/author/Author'
                    );
                },
                show: GroupShow,
                invite: () => {
                    return import(
                        /* webpackChunkName: "settings-invite-students" */
                        'views/pages/groups/inviteStudents/InviteStudents'
                    );
                },
                settings: () => {
                    return import(
                        /* webpackChunkName: "settings-groups" */
                        'views/pages/groups/settings/Settings'
                    );
                },
                progress: () => {
                    return import(
                        /* webpackChunkName: "progress-groups" */
                        'views/pages/groups/progress/Progress'
                    )
                },
                results: () => {
                    return import(
                        /* webpackChunkName: "results-groups" */
                        'views/pages/groups/results/Results'
                    )
                },
                annotate: () => {
                    return import(
                        /* webpackChunkName: "annotate" */
                        'views/pages/groups/annotate/Annotate'
                    )
                },
                pick_course: () => {
                    return import(
                        /* webpackChunkName: "signup-pick-course" */
                        'views/pages/groups/pickCourse/PickCourse'
                    )
                },
                create: () => {
                    return import(
                        /* webpackChunkName: "signup-create-group" */
                        'views/pages/groups/create/Create'
                    )
                },
                created: () => {
                    return import(
                        /* webpackChunkName: "signup-create-group" */
                        'views/pages/groups/created/Created'
                    )
                },
                planner: () => {
                    return import(
                        /* webpackChunkName: "planner-groups" */
                        'views/pages/groups/planner/Planner'
                    )
                },
                createaccounts: () => {
                    return import(
                        /* webpackChunkName: "signup-no-email" */
                        'views/pages/groups/createStudents/CreateStudents'
                    )
                },
                search: () => {
                    return import(
                        /* webpackChunkName: "search-material" */
                        'views/pages/groups/search/Search'
                    )
                }
            },
            chapters: {
                author: () => {
                    return import(
                        /* webpackChunkName: "author-chapters" */
                        'views/pages/chapters/author/Author'
                    );
                },
                show: ChapterShow,
                progress: () => {
                    return import(
                        /* webpackChunkName: "progress-groups" */
                        'views/pages/groups/progress/Progress'
                    )
                },
                annotate: () => {
                    return import(
                        /* webpackChunkName: "annotate" */
                        'views/pages/chapters/annotate/Annotate'
                    )
                }
            },
            sections: {
                author: () => {
                    return import(
                        /* webpackChunkName: "author-sections" */
                        'views/pages/sections/author/Author'
                    );
                },
                show: SectionShow,
                progress: () => {
                    return import(
                        /* webpackChunkName: "progress-groups" */
                        'views/pages/groups/progress/Progress'
                    )
                },
                annotate: () => {
                    return import(
                        /* webpackChunkName: "annotate" */
                        'views/pages/sections/annotate/Annotate'
                    )
                }
            },
            activities: {
                author: () => {
                    return import(
                        /* webpackChunkName: "author-activities" */
                        'views/pages/activities/author/Author'
                    );
                },
                show: ActivityShow,
                progress: () => {
                    return import(
                        /* webpackChunkName: "progress-activities" */
                        'views/pages/activities/progress/Progress'
                    )
                },
                annotate: () => {
                    return import(
                        /* webpackChunkName: "annotate" */
                        'views/pages/activities/annotate/Annotate'
                    )
                },
                grading: () => {
                    return import(
                        /* webpackChunkName: "grading-activities" */
                        'views/pages/activities/studentGrading/StudentGrading'
                    )
                }
            },
            task_groups: {
                author: () => {
                    return import(
                        /* webpackChunkName: "author-task-groups" */
                        'views/pages/task_groups/author/Author'
                    );
                },
                show: ActivityShow,
                progress: () => {
                    return import(
                        /* webpackChunkName: "progress-task-groups" */
                        'views/pages/task_groups/progress/ShowAllAnswers'
                    )
                },
            },
            library: {
                home: () => {
                    return import(
                        /* webpackChunkName: "library-home" */
                        'views/pages/library/home/Home'
                    );
                },
                view: () => {
                    return import(
                        /* webpackChunkName: "library-view" */
                        'views/pages/library/view/View'
                    );
                },
                add: () => {
                    return import(
                        /* webpackChunkName: "library-add" */
                        'views/pages/library/add/Add'
                    );
                },
                author: () => {
                    return import(
                        /* webpackChunkName: "author-task-groups-library" */
                        'views/pages/library/author/Author'
                    );
                },
            },

            portfolio: {
                students: () => {
                    return import(
                        /* webpackChunkName: "portfolio-projects" */
                        'views/pages/portfolio/Students'
                    );
                },
                group: () => {
                    return import(
                        /* webpackChunkName: "portfolio-group" */
                        'views/pages/portfolio/group/Group'
                    )
                },
                project: () => {
                    return import(
                        /* webpackChunkName: "portfolio-project" */
                        'views/pages/portfolio/project/Project'
                    );
                },
                projects: () => {
                    return import(
                        /* webpackChunkName: "portfolio-projects" */
                        'views/pages/portfolio/projects/Projects'
                    );
                },
            },

            students: {
                progress: () => {
                    return import(
                        /* webpackChunkName: "progress-students" */
                        'views/pages/students/progress/StudentsProgress'
                    )
                },
                results: () => {
                    return import(
                        /* webpackChunkName: "results-groups" */
                        'views/pages/students/results/Results'
                    )
                }
            },

            answers: {
                progress: () => {
                    return import(
                        /* webpackChunkName: "progress-answers" */
                        'views/pages/answers/progress/AnswerBrowser'
                    )
                }
            },

            products: {
                payment_status: () => {
                    return import(
                        /* webpackChunkName: "signup-register" */
                        'views/pages/products/payments/PaymentStatus'
                    )
                },
                anonymous_payment_status: () => {
                    return import(
                        /* webpackChunkName: "signup-register" */
                        'views/pages/products/payments/PaymentStatus'
                    )
                }
            },

            licenses: {
                renewal: () => {
                    return import(
                        /* webpackChunkName: "license-renewal" */
                        'views/pages/licenses/LicenseRenewal'
                    )
                }
            },

            words: {
                home: () => {
                    return import(
                        /* webpackChunkName: "words" */
                        'views/pages/words/Home'
                    )
                },
                list: () => {
                    return import(
                        /* webpackChunkName: "words" */
                        'views/pages/words/List'
                    )
                },
                course: () => {
                    return import(
                        /* webpackChunkName: "words" */
                        'views/pages/words/Course'
                    )
                },
                sessions: () => {
                    return import(
                        /* webpackChunkName: "words" */
                        'views/pages/words/Session'
                    )
                },
                results: () => {
                    return import(
                        /* webpackChunkName: "words" */
                        'views/pages/words/results/Results'
                    )
                },
            },

            accessor: {
                login: () => {
                    return import(
                        /* webpackChunkName: "accessor" */
                        'views/pages/accessor/login/Login'
                    )
                },
                connections: () => {
                    return import(
                        /* webpackChunkName: "accessor" */
                        'views/pages/accessor/connections/Connections'
                    )
                }
            }

        };

        // List of views that are strictly accessible without a userID
        this.allowedWithoutUserID = {
            users: [
                'login',
                'register_join_code',
                'welcome',
                'welcome_teacher',
                'register_name',
                'register_validation_mail_sent',
                'reset_password'
            ],
            products: [
                'anonymous_payment_status'
            ],
            accessor: [
                'login'
            ]
        };

    }

}
