import Styles from './MessageItem.scss';

import Template from './MessageItem.hbs';
import Avatar from 'views/components/avatar/Avatar.svelte';
import GroupNoteSidebarView from 'views/components/notes/sidebar/GroupNote';
import Util from 'util/util';
import TimeUtil from 'util/TimeUtil';

export default BaseView.extend({

    events: {
        click: 'onClickItem'
    },

    /**
     * @param {Object} options View options
     * @param {string} options.avatar URL of avatar
     * @param {string} options.created creation date string
     * @param {string} options.replyIcon either "messageFrom" or "messageTo"
     * @param {boolean|number} options.isNew if note is unread
     * @param {string} options.message Content of given feedback
     * @param {string} options.recipientName Name of recipient
     *
     * @param {string} [options.link] Link to feedback at task where feedback was given
     * @param {string} [options.projectName] Portfolio project name
     */
    initialize({
        avatar,
        created,
        replyIcon,
        isGroupMessage,
        isNew,
        link,
        message,
        projectName,
        recipientName,
        fromFeedback = false,
        parentView,
    }) {

        this.link = link
        this.isGroupMessage = isGroupMessage
        this.parentView = parentView

        this.setElement(Template({
            Styles,
            name: recipientName,
            projectName,
            date: TimeUtil.getAbsoluteDate(created),
            isNew,
            message: Util.stripTags(message),
            replyIcon,
            fromFeedback,
            isIncoming: replyIcon === 'messageFrom',
            fromTo: replyIcon === 'messageFrom' ? window.i18n.gettext('From') : window.i18n.gettext('To')
        }));

        this.addSvelteChildView('.js-avatar', Avatar, {
            avatar,
        })
    },

    onClickItem() {

        /**
         * do not navigate to feedback at task when a Group note is clicked.
         * Show the group note in the sidebar instead
         */
        if (this.isGroupMessage) {

            const groupNoteView = new GroupNoteSidebarView({
                model: this.model,
                parentView: this.parentView,
            })

            Backbone.View.sidebar.showSidebar(groupNoteView, window.i18n.gettext('Feedback'), 630);

            return
        }

        Backbone.history.navigate(this.link, {
            trigger: true
        });
    }

});
