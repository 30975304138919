import Styles from './Task.scss';

import Template from './Task.hbs';
import ResponseModel from 'models/ResponseModel';
import Template1View from 'views/components/taskGroups/tasks/template1/Template1';
import Template2View from 'views/components/taskGroups/tasks/template2/Template2';
import Template6View from 'views/components/taskGroups/tasks/template6/Template6';
import Template11View from 'views/components/taskGroups/tasks/template11/Template11';
import Template16View from 'views/components/taskGroups/tasks/template16/Template16';
import Template21View from 'views/components/taskGroups/tasks/template21/Template21';
import Template22View from 'views/components/taskGroups/tasks/template22/Template22';
import Template23View from 'views/components/taskGroups/tasks/template23/Template23';
import Template25View from 'views/components/taskGroups/tasks/template25/Template25';
import Template26View from 'views/components/taskGroups/tasks/template26/Template26';
import Template28View from 'views/components/taskGroups/tasks/template28/Template28';
import Template29View from 'views/components/taskGroups/tasks/template29/Template29';
import Template30View from 'views/components/taskGroups/tasks/template30/Template30';
import Template31View from 'views/components/taskGroups/tasks/template31/Template31';
import Template32View from 'views/components/taskGroups/tasks/template32/Template32';
import Template33View from 'views/components/taskGroups/tasks/template33/Template33';
import Template34View from 'views/components/taskGroups/tasks/template34/Template34';
import Template35View from 'views/components/taskGroups/tasks/template35/Template35';
import Template36View from 'views/components/taskGroups/tasks/template36/Template36';
import Template37View from 'views/components/taskGroups/tasks/template37/Template37';
import Template38View from 'views/components/taskGroups/tasks/template38/Template38';
import Template39View from 'views/components/taskGroups/tasks/template39/Template39';
import InvalidTemplate from 'views/components/taskGroups/tasks/invalidTemplate/InvalidTemplate'
import CheckAnswerView from 'views/components/answers/Answer';
import AnswerExplanationView from 'views/components/taskGroups/tasks/answerExplanation/AnswerExplanation';
import StudentGradePicker from 'views/components/taskGroups/tasks/studentGradePicker/StudentGradePicker';
import StudentGrade from 'views/components/taskGroups/tasks/studentGrade/StudentGrade';
import Button from 'views/components/button/Button'
import ExamPoints from 'views/components/taskGroups/tasks/examPoints/ExamPoints';
import DefaultImageElement from 'views/components/taskGroups/sources/source12/templates/elements/image.hbs';
import NoteView from 'views/components/notes/noteWorkOn/NoteWorkOn';
import RenderSpecialElements from 'util/RenderSpecialElements';
import Util from 'util/util';
import TaskAnalytics from './analytics/Analytics'
import Score from 'util/Score';

export default BaseView.extend({

    events: {
        'click .js-show-answer': 'onClickShowAnswer',
        'click .js-check-answer': 'openCheckAnswer',
        'click .js-open-analytics': 'openAnalytics',
    },

    ungradableActivities: ['exam', 'generated_exam'],

    specialElementsConvertList: {
        // Convertion for HTML element: 'img'
        img: {
            // Default options
            global: {
                // Set template for the img tag
                template: DefaultImageElement
            }
        }
    },

    Template1View,
    Template2View,
    Template6View,
    Template11View,
    Template16View,
    Template21View,
    Template22View,
    Template23View,
    Template25View,
    Template26View,
    Template28View,
    Template29View,
    Template30View,
    Template31View,
    Template32View,
    Template33View,
    Template34View,
    Template35View,
    Template36View,
    Template37View,
    Template38View,
    Template39View,

    /**
     * initialize
     *
     * this.model {TaskModel}                       Model containing information on the task element.
     *
     * @param {BaseView} work_on                    Parent view of the parent TaskGroup view of this Task view.
     * @param {ActivityModel} activityModel         Activity model
     * @param {boolean|undefined} onlyShowAnswer    If true, do not render task content, only student answer view.
     * @param {number|undefined} singleAnswerUserId If set, only show answer of a single student instead of group.
     * @param {boolean|undefined} hasLinkToTask     If true, clicking on the task index navigates to the same task
     *                                              in the context of its task group in the 'show' view.
     * @param {boolean|undefined} isPreview         If true, parent activity is in preview mode, meaning it's
     *                                              read-only and answers cannot be entered or modified.
     * @param {boolean} isInPresentMode             If true, user is in presentation mode.
     */
    initialize({
        work_on,
        activityModel,
        isInPresentMode,
        onlyShowAnswer,
        singleAnswerUserId,
        hasLinkToTask,
        isPreview,
        studentGradingTaskLabel,
        isStudentGrading = false,
        studentGradingSessionId,
        isExportingExamAnswers,
        canBeEdited = false,
    }) {

        this.isInPresentMode = isInPresentMode
        this.activityModel = activityModel;
        this.activityType = activityModel?.get('type')
        this.singleAnswerUserId = singleAnswerUserId
        this.isPreview = isPreview
        this.isStudentGrading = isStudentGrading
        if (this.isStudentGrading) {
            this.studentGradingSessionId = studentGradingSessionId
        }
        this.studentGradingTaskLabel = studentGradingTaskLabel
        this.isExportingExamAnswers = isExportingExamAnswers

        _.bindAll(
            this,
            'displayNotes',
            'hideGradeScore',
            'openGradeScore',
            'addStudentGrade',
            'showAnswer',
            'onChangeGradeScore',
            'responseSaveStateChanged',
            'getTranslatedStringForGradedBy',
            'onClickNavigateToTask',
            'onClickTaskContent',
            'renderCorrectAnswerInCheckAnswerView',
            'onTypeTaskContent'
        );

        this.onlyShowAnswer = onlyShowAnswer;

        // TODO: handle invalid json_task_info per template

        const indexLetter = this.model.get('taskIndex') !== null ? Util.numToLetter(this.model.get('taskIndex')) : null

        this.work_on = work_on;

        // Set showAnswers to a boolean wether the user is grading this task
        // and if the answers are visible
        this.show_answers = !this.isStudentGrading
            && this.work_on?.model?.get('show_answers');

        // If activity is an exam and the answers are visible, display modal answer without a
        // way to hide it (the button for doing so, '.js-show-answer', is removed from the DOM).
        // Also remove the response save status indicator from the DOM since students aren't
        // supposed to edit their answers when reviewing their responses after the exam is over.
        this.showExamAnswers = false;

        // link response given by student to task
        if (ACL.checkRoles([ACL.roles.STUDENT, ACL.roles.SUPERVISOR])) {

            // in activity
            if (this.activityType === 'adaptive_student' || this.activityType === 'adaptive') {
                // in exercise
                // never show answers in exercise mode
                this.show_answers = false;

                this.response = new ResponseModel({
                    task_id: this.model.get('id'),
                    user_id: Backbone.Model.user.get('id'),
                    activity_id: this.work_on.model.get('id'),
                    practice_session: this.work_on.practiceSession
                });

                // Still add the response for coloring purposes
                this.work_on.model.responses.add(this.response);
            } else if (!this.isStudentGrading) {

                this.showExamAnswers = this.show_answers && this.work_on.model.isExam()

                this.response = this.work_on.model.responses.findWhere({
                    task_id: this.model.get('id')
                });
                if (!this.response) {
                    this.response = new ResponseModel({
                        task_id: this.model.get('id'),
                        user_id: Backbone.Model.user.get('id'),
                        activity_id: this.work_on.model.get('id')
                    });
                    this.work_on.model.responses.add(this.response);
                }
                this.response.set({
                    is_in_exam: ['exam', 'generated_exam', 'diagnostic_exam'].includes(this.activityType)
                });
            }
        } else if (this.isExportingExamAnswers) {

            // For when rendering task as read-only student answer archive.

            this.response = this.work_on.model.responses.findWhere({
                user_id: singleAnswerUserId,
                task_id: this.model.id
            })
            if (!this.response) {
                this.response = new ResponseModel({
                    task_id: this.model.get('id'),
                    user_id: Backbone.Model.user.get('id'),
                    activity_id: this.work_on.model.get('id')
                });
                this.work_on.model.responses.add(this.response);
            }

        } else {
            // teacher
            // create dummy response model for teacher (will not be saved to DB)
            this.response = new ResponseModel({
                task_id: this.model.get('id'),
                user_id: Backbone.Model.user.get('id'),
            });
        }

        if (!this.isStudentGrading) {
            this.listenTo(
                this.response,
                'change:score',
                this.onChangeGradeScore
            );

            // set flag if answers is given by student
            if (typeof(this.response.get('json_answer')) !== 'undefined') {
                if (typeof(this.response.get('json_answer'))[0] !== 'undefined') {
                    this.is_answer_given = true;
                } else {
                    this.is_answer_given = false;
                }
            } else {
                this.is_answer_given = false;
            }

            // Check if there is an explanation
            this.hasExplanation = (_.size(this.model.get('explanation')) > 0);

            // Set the has model answer boolean
            this.hasModelAnswer = this.model.get('has_model_answer');

            // When grading mode is none
            if (this.model.get('grading_mode') === 'none') {

                // Treat this task as if it has no model answer
                this.hasModelAnswer = false;
            }
        }

        this.setElement(Template({
            Styles,
            indexLetter: this.isStudentGrading ? this.studentGradingTaskLabel : indexLetter,
            isPreview,
            isInPresentMode,
            introduction: Util.renderContentSafely(this.model.get('introduction')),
            task_id: this.model.id,
            show_answers: this.show_answers,
            is_answer_given: this.is_answer_given,
            hasModelAnswer: this.hasModelAnswer,
            hasExplanation: this.hasExplanation,
            no_answer: this.no_answer,
            activityType: this.activityType,
            showOnlyAnswer: this.onlyShowAnswer,
            hasLinkToTask,
            isStandalone: this.work_on && this.work_on.model.get('standalone'),
            canAddResponses: ACL.isAllowed(ACL.resources.RESPONSES, ACL.actions.ADD),
            hasTaskAnalytics: canBeEdited,
        }));

        if (Backbone.Model.user.getSetting('hasExtraLogging')) {
            this.el.querySelector('.js-content').addEventListener('mousedown', () => {
                this.onClickTaskContent()
            }, {
                once: true
            })
            this.el.querySelector('.js-content').addEventListener('input', () => {
                this.onTypeTaskContent()
            }, {
                once: true
            })
        }

        if (hasLinkToTask) {
            this.$('.js-index-letter').on('click', this.onClickNavigateToTask)
        }

        // Show feedback to students in linear activity or in exam activity with answers enabled
        if (ACL.checkRole(ACL.roles.STUDENT)
            && (
                'linear' === this.activityType
                || ['exam', 'generated_exam', 'diagnostic_exam'].includes(this.activityType)
                && this.show_answers
                && !this.isStudentGrading
            )
        ) {
            this.displayNotes();
        }

        RenderSpecialElements({}, this, this.$('.js-introduction'));

        // If the work_on is not a progress view (student_model exists), the activity is an exam
        // and custom grading is not disabled for this exam, add a ExamPoints view for viewing
        // and editing the attainable and graded points.
        if (
            this.work_on &&
            !this.work_on.student_model &&
            !this.work_on.model.getIsExamCustomGradesDisabled() &&
            this.work_on.model.isExam()
        ) {
            this.renderExamPointsView()
        }

        // Render the task view itself.
        this.renderTask()

        // Show the answer to the question in author and when viewing the answers as student
        // reviewing the answers of an exam, but only if onlyShowAnswer (meaning the model and/or student answer,
        // not the answer in the task itself) is not true.
        if (!this.onlyShowAnswer && (
            APPLICATION === 'author' ||
                this.showExamAnswers
        )) {
            this.showAnswer();
        }

        // show correct icon and text for save state
        if (!ACL.isAllowed(ACL.resources.RESPONSES, ACL.actions.ADD) || this.showExamAnswers) {
            this.$('.js-response-saved-status').remove();

            // When in student exam answers mode, don't show show answers button.
            if (this.showExamAnswers) {
                this.$('.js-show-answer').remove();
            }
        } else if (!this.isStudentGrading) {
            this.listenTo(this.response, 'change:saved_state', this.responseSaveStateChanged);
            this.responseSaveStateChanged(this.response)
        }

        // Check if task type is presentation in that case we might have to render a send button
        // when the student is following a teacher
        if (this.activityType === 'presentation' && ACL.checkRole(ACL.roles.STUDENT)) {

            // NOTE if you add a task to this array,
            // make sure to add a callback function named "sendPresentationAnswer"
            var tasksWithSendButton = [2, 29, 31];

            // Bind this variable to view, so Presentation.js can access it
            this.hasSendButton = _.contains(tasksWithSendButton, this.model.get('template_id'));

            if (this.hasSendButton) {
                this.renderPresentationButton(true)
            }

            if (this.is_answer_given) {
                // Trigger event that tells the presentation view, the student has already given the answer.
                this.work_on.trigger('student-saved-presentation-answer', {
                    silent: true
                })
            }
        }

        if (

            // When the user is a teacher and has clicked on a feedback note,
            // Show the student answers and feedback that are related to the task id of the feedback note.
            this.model.get('showStudentAnswers') &&
            Backbone.Model.user.get('is_teacher')
        ) {
            this.openCheckAnswer();
        }

    },

    /**
     * getTranslatedStringForGradedBy
     *
     * This method will return a translated string
     * of who created the grade the student has gotten
     * for this task.
     *
     * @return {string}     Translated string
     */
    getTranslatedStringForGradedBy() {

        switch (this.response.get('scored_by')) {
            case 'student':
                return window.i18n.gettext(
                    'Graded by yourself'
                );

            case 'teacher':
                return window.i18n.gettext(
                    'Graded by teacher'
                );

            case 'server':
                return window.i18n.gettext(
                    'Automatically graded'
                );

            case 'corrected':
                return window.i18n.gettext(
                    'You have corrected your answer'
                );
        }
    },

    renderExamPointsView() {

        if (this.examPoints) {
            this.unregisterAndDestroyChildView(this.examPoints)
        }

        this.examPoints = this.addChildView(
            new ExamPoints({
                model: this.model,
                activityModel: this.work_on.model,
                isPreview: this.isPreview,
                response: this.response,
                isExportingExamAnswers: this.isExportingExamAnswers
            }),
            '.js-task-exam-points'
        );
    },

    /**
     * onChangeGradeScore
     *
     * This method will be called when the response score has been changed.
     * It will update the score box according to the value.
     *
     */
    onChangeGradeScore() {

        // Remove all score classes
        this.$('.js-grade-score').removeClass(
            Styles['grade-score__score--red'] + ' ' +
            Styles['grade-score__score--orange'] + ' ' +
            Styles['grade-score__score--green']
        );

        // When score is -1 (unset)
        if (this.response.get('score') === -1) {

            // Hide the gradescore, because there isn't a score
            this.hideGradeScore();

            return;
        }

        // in case of diagnostic exam, re-render ExamPoints to
        // display new grade
        if (this.activityType === 'diagnostic_exam') {
            this.renderExamPointsView()
        }

        // If the score is not scored by student, but there was a score change, the
        // new score is probably given by the backend for a corrected answer (when
        // an answer is corrected, it gets a score of 100). So remove all the views
        // that has something to do with student grading.
        if (this.response.get('scored_by') !== 'student') {
            this.destroyChildViewsOfInstance(StudentGradePicker);
            this.destroyChildViewsOfInstance(StudentGrade);
        }

        // Add class of the score to the grade score container
        const scoreColor = Score.getColor(this.response.get('score'))
        if (scoreColor) {
            this.$('.js-grade-score').addClass(Styles['grade-score__score--' + scoreColor])
        }

        // Update the value of the score box
        this.$('.js-grade-score').text(
            Math.round(this.response.get('score') * 100) + '%'
        );

        // Update the graded by attribute for grade-holder (will be shown in tooltip)
        this.$('.js-grade-holder').attr(
            'data-graded-by',
            this.getTranslatedStringForGradedBy()
        );
    },

    /**
     * openGradeScore
     *
     * This method will open the grade score bar.
     *
     */
    openGradeScore() {

        // Weird backbone bug doesn't call change on score when self grading.
        // To fix this, we call the onChangeGradeScore method by ourself
        this.onChangeGradeScore();

        // Only open if user is student and score is not -1
        if (ACL.checkRoles([ACL.roles.STUDENT, ACL.roles.SUPERVISOR])
            && this.response.get('score') !== -1
        ) {

            TweenMax.fromTo(
                this.$('.js-grade-score-holder'), {
                    display: 'none',
                    opacity: 0.15,
                    x: -25
                }, {
                    duration: 0.15,
                    display: 'flex',
                    opacity: 1,
                    x: 0,
                    ease: 'back.out(3)'
                }
            );
        }
    },

    hideGradeScore() {
        TweenMax.to(
            this.$('.js-grade-score-holder'), {
                duration: 0.25,
                opacity: 0,
                x: -25,
                ease: 'back.in(3)',
                onComplete() {
                    TweenMax.set(this.targets(), {
                        display: 'none'
                    })
                }
            }
        );
    },

    renderTask() {

        try {
            let TemplateConstructor = this['Template' + this.model.get('template_id') + 'View']

            // When only having to show the model answer and/or student answer, skip rendering the main part of the
            // task since so user does not have to wait for something it is going to need and see.
            if (this.onlyShowAnswer) {

                // Use ducktyping to find out which syntax to use. This can be removed when all templates are classes.
                const templateIsClass = !TemplateConstructor.hasOwnProperty('extend')

                if (templateIsClass) {
                    TemplateConstructor = class OverrideTC extends TemplateConstructor {
                        renderTask() {}
                    }
                } else {
                    TemplateConstructor = TemplateConstructor.extend({
                        renderTask() { }
                    })
                }
            }

            this.templateView = new TemplateConstructor({
                model: this.model,
                task_view: this,
                isPresentation: this.activityType === 'presentation',
                isInPresentMode: this.isInPresentMode,
            });

        } catch (e) {
            if (!this.model.hasValidTemplateId) {
                // give error a more readable name if it's from an invalid template id
                e.name = 'Task template not found'
            }

            // Useful for local debugging, will be stripped from production built
            // because it is overwritten by Sentry
            console.error(e);

            window.sentry.withScope(scope => {
                scope.setExtra('template_id', this.model.get('template_id'))
                scope.setExtra('task_id', this.model.get('id'))
                window.sentry.captureException(e)
            })

            // render a mock Template view which shows a warning
            this.templateView = new InvalidTemplate()

            this.registerChildView(this.templateView)
            this.templateView.appendTo(this.$('.js-content'))

            return

        }

        this.registerChildView(this.templateView);

        // for teacher view of only answers of one student
        if (this.onlyShowAnswer) {

            if (this.isStudentGrading) {
                this.renderAnswerForStudentGrading()
            } else {

                this.openCheckAnswer();
                this.$('.js-task-answer').hide();
                this.$('.js-check-answer').hide();
            }

        } else {
            this.templateView.appendTo(this.$('.js-content'));
            this.templateView.show();
        }

        // Check if answers are visible and the task still need student grading.
        if (this.show_answers && this.activityType !== 'diagnostic_exam' && this.needsStudentGrading()) {

            // Call the show answer method
            this.showAnswer(true);
        }

        // Lock the tasks if there is no license, or if the deadline has passed
        // Only if only show answer is disabled, shince otherwise there will be nothing to lock
        if (
            !this.onlyShowAnswer && (
                this.work_on?.model.hasLicenseExpired() || this.work_on?.model.deadline?.hasExpired()
            )
        ) {
            _.defer(this.templateView.lockAnswer)
        }
    },

    /**
     * needsStudentGrading
     *
     * This method will check multiple conditions to determine if the task need
     * student grading. This method will be called on initialization to check
     * if there was an ungraded answer when the student refreshes. And this method
     * will be called when the user clicks on the view answer. It will update the
     * response model's need-scoring attribute an return a boolean.
     *
     * @param  {boolean} hasSeenModelAnswer     This attribute can be used to force
     *                                          the has seen model answer to true or
     *                                          false. Usefull for when patching is
     *                                          to slow.
     *
     * @return {boolean}                        Boolean whether the task needs grading
     */
    needsStudentGrading(hasSeenModelAnswer) {

        if (!ACL.isAllowed(ACL.resources.RESPONSES, ACL.actions.ADD)) {
            return false;
        }

        // Create boolean whether the task need student grading
        var needsStudentGrading = (

            // And is not the author
            APPLICATION !== 'author' &&
            (
                // Check if the model answer has been seen in response model
                this.response.get('has_seen_model_answer') === true ||

                // Or when has seen model answer is force trough argument
                hasSeenModelAnswer
            ) &&

            // Check if the original response is in sync with the lastest response
            this.response.get('sync') !== false &&

            // Check if there isn't a score set yet
            this.response.get('score') === -1 &&

            // Check if there is a valid json answer given
            this.response.has('json_answer') &&

            // Check if grading mode is student
            this.model.get('grading_mode') === 'student' &&

            // Check if this activity belongs to the ungradable ones
            !_.contains(this.ungradableActivities, this.work_on.model.get('type'))
        );

        // if diagnostic exam, do an extra check: check if student gradig is allowed in metadata
        if (this.activityType === 'diagnostic_exam') {
            const studentGradingAllowed = parseInt(this.work_on.model.get('metadata').get('student_grading_allowed'))
            if (isNaN(studentGradingAllowed) || studentGradingAllowed === 0) {
                needsStudentGrading = false
            }

        }

        // Update the model to be up to date
        this.response.set('need-scoring', (needsStudentGrading) ? 1 : 0);

        // Add event listener to the response scoring
        this.listenToOnce(
            this.response,
            'change:need-scoring',

            // Trigger on this view, so other views can listen to it
            _.partial(this.trigger, 'is-scored')
        );

        // Add event listener to the response scoring
        this.listenToOnce(
            this.response,
            'change:need-scoring',

            // Open the grade score element
            this.openGradeScore
        );

        // Expose the needsStudentGrading boolean
        return needsStudentGrading;

    },

    openCheckAnswer() {

        // Check if the getCorrectAnswer is a function
        if (typeof this.templateView.getCorrectAnswer !== 'function') {

            window.sentry.withScope(scope => {
                scope.setExtra('templateId', this.model.get('template_id'));

                window.sentry.captureMessage('getCorrectAnswer is not a function');
            });

            // show feedback to developer
            console.log('getCorrectAnswer needs to be implemented');

            // Stop further execution
            return
        }

        // When the js-work-on-answer has not the js-open class
        if (!this.$('.js-work-on-answer').hasClass('js-open')) {

            // Get the correct answer from the view
            const correctAnswer = this.templateView.getCorrectAnswer()

            // If true, treat the correct answer answer a proper view or a promise which will return a view.
            // Else assume correct answer is a string.
            const correctAnswerIsView = (correctAnswer instanceof BaseView) || (correctAnswer instanceof Promise)

            // Create an options object
            const options = {

                // The correct answer to this task to be displayed above the listing of student answers.
                correctAnswer:
                    // Check if correctAnswer is not a 'view', if not use correctAnswer as string,
                    // else pass empty string
                    correctAnswerIsView ? '' : correctAnswer
                ,

                // Set the task view, such that CheckAnswerView can communicate with Task view.
                taskView: this,

                // If true, only display a the student answer of a specific student instead of all students answers
                // of the group.
                onlyOneAnswer: this.onlyShowAnswer,
                singleAnswerUserId: this.singleAnswerUserId,
                isInPresentMode: this.isInPresentMode,
            }

            // Check if getAnswerCallback is a function
            if (typeof this.templateView.getAnswerCallback === 'function') {

                // Add the answerCallback to the options
                options.answerCallback = this.templateView.getAnswerCallback;
            }

            if (!this.isStudentGrading) {
                // Create a new CheckAnswerView, store it in a this variable so it's accessible
                // across the whole task
                this.checkAnswerView = new CheckAnswerView(options)
            }

            // If correct answer is a view
            if (correctAnswerIsView) {
                this.renderCorrectAnswerInCheckAnswerView(correctAnswer)
            }

            // Register the checkAnswerView as childView
            this.registerChildView(this.checkAnswerView);

            if (this.isInPresentMode) {
                return this.checkAnswerView
            }

            // Set the HTML of the js-work-on-answer container to the checkAnswerView's element
            this.$('.js-work-on-answer').html(this.checkAnswerView.$el);

            // Add the js-open class
            this.$('.js-work-on-answer').addClass('js-open');

            // Make js-work-on-answer visible
            this.$('.js-work-on-answer').show();

            if (!this.onlyShowAnswer) {
                TweenMax.from(this.$('.js-work-on-answer'), {
                    duration: 0.4,
                    height: 0,
                    ease: 'power2.inOut',
                    onComplete: () => {
                        this.$('.js-work-on-answer').css({
                            overflow: '',
                            height: ''
                        });
                    }
                });
            }

            this.$('.js-check-answer').addClass('btn--active')

        } else {

            TweenMax.to(this.$('.js-work-on-answer'), {
                duration: 0.4,
                overflow: 'hidden',
                height: 0,
                ease: 'power2.inOut',
                onComplete: () => {
                    this.showCheckAnswer();
                    this.unregisterAndDestroyChildView(this.checkAnswerView);
                }
            });

            this.$('.js-check-answer').removeClass('btn--active')
        }
    },

    renderCorrectAnswerInCheckAnswerView(correctAnswerView) {
        if (correctAnswerView instanceof Promise) {
            correctAnswerView.then((resolvedView) => {
                this.renderCorrectAnswerInCheckAnswerView(resolvedView)
            })
        } else {
            // Register correct answer as childview of checkAnswerView
            this.checkAnswerView.registerChildView(correctAnswerView)

            // Add the correct answer to the right holder within this.checkAnswerView
            this.checkAnswerView.$('.js-correct-answer-holder').html(correctAnswerView.$el);

            // Check if correctAnswer has a show function
            if (correctAnswerView.show instanceof Function) {

                // Call the show function
                correctAnswerView.show()
            }
        }
    },

    showCheckAnswer() {
        this.$('.js-work-on-answer').removeClass('js-open');
        this.$('.js-work-on-answer').hide();
        this.$('.js-work-on-answer').css('height', '');
    },

    /**
     * openScoreFirstMessage
     *
     * This method will tell the user to grade their answer
     * first. It is used when the user tries to close answers
     * without grading or can be used in template views to
     * keep this message DRY
     *
     */
    openScoreFirstMessage() {

        // Open message to tell the user it should score its answer first
        Backbone.View.layout.openStatus(
            window.i18n.gettext('You need to score your answer first'),
            'warning'
        );
    },

    /**
     * onClickShowAnswer
     *
     * When view model answer button is clicked by the user.
     */
    onClickShowAnswer() {
        this.showAnswer(false);
    },

    /**
     * showAnswer
     *
     * This method will show or hide the answer when called.
     *
     * @param  {boolean} silent     Boolean if the click model answer should not be triggered
     * @return {boolean}            Sometime we return false to stop further execution
     */
    showAnswer(silent) {

        if (this.work_on?.model.hasLicenseExpired()) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('You cannot view the answers, because your license for this learning material has expired.'),
                'info'
            );
            return;
        }

        if (

            // For linear activities we want to prevent students to check answers
            // first (if answers are visible), before filling in the answer.
            // This is a problem because it will show an original answer with a 100% score eventhough
            // the user has already checked their (empty) response.
            // When an expired deadline is present, we do need to allow access to the model answer
            // because the student cannot give an answer
            ACL.isAllowed(ACL.resources.RESPONSES, ACL.actions.ADD)
            && this.activityType === 'linear'
            && !this.response.has('json_answer')
            && !this.work_on?.model.deadline.hasExpired()

        ) {
            Backbone.View.layout.openStatus(
                window.i18n.gettext('Answer the question first before checking the correct answer.'),
                'info'
            );
            return;
        }

        // This is added because else in the author interface, adding a template 2 will call a
        // save on the response model that doesn't exists in the author interface
        //
        // Fix for issues: LB6245 & LB6227; When model answer is clicked only for opening the
        // answer so the student can grade it's answer we don't want to call the onShowModelAnswer
        // event. This event is typically used for syncing the model answer before hasSeenModelAnswer.
        // But there is already an answer so we don't need to force sync again.
        if (APPLICATION === 'webapp' && !silent) {

            // Fix for issue LB6032 trigger an event that model answer has been clicked
            // so we can sync the answer before triggering the hasSeenModelAnswer event
            this.templateView.trigger('onShowModelAnswer');
        }

        if (this.showingAnswer) {

            // Check if this task still needs scoring
            if (this.response.get('need-scoring')) {

                // Open the score first message
                this.openScoreFirstMessage();

                // Stop further execution
                return false;
            }

            // Allow students to edit their answer again (except when the deadline has expired)
            if (!this.work_on?.model.deadline.hasExpired()) {
                this.templateView.unlockAnswer();
            }

            // Hide the model answer
            this.templateView.hideAnswer();
            this.templateView.$el.removeClass('showAnswers');

            // Hide the answer explanation if present
            if (this.answerExplanationView) {
                this.answerExplanationView.hide();
            }

            // Remove student grading views if present
            this.destroyChildViewsOfInstance(StudentGradePicker);
            this.destroyChildViewsOfInstance(StudentGrade);

            // Hide the score of the student
            this.hideGradeScore();

            this.$('.js-show-answer').removeClass('btn--active')

        } else {

            // Show the model answer if present
            // For author mode, show the model answer in a neutral state
            if (this.hasModelAnswer) {
                if (APPLICATION === 'author') {
                    this.templateView.showAuthorAnswer()
                    this.templateView.el.setAttribute('inert', '')
                } else {
                    this.templateView.showAnswer()
                }
                this.templateView.$el.addClass('showAnswers');
            }

            // Call the lockanswer method, so that students cannot edit their answer
            //
            // Lock answer is called too quickly for tasks with defered components,
            // like for example: open question with the wysiwyg editor. By calling
            // lock answer with a defer we will give the time to those components
            // to load completely. This fixes issue:
            //
            // https://podio.com/dedactnl/platform/apps/learnbeat/items/5356

            if (APPLICATION !== 'author') {
                _.defer(this.templateView.lockAnswer);
            }

            // Check if this task needs student grading. Tell the check that the student
            // saw the model answer
            if (this.needsStudentGrading(true)) {

                // Add the student grading buttons
                this.addChildView(
                    new StudentGradePicker({
                        templateView: this.templateView,
                        model: this.response,
                        activityModel: this.work_on.model,
                    }),
                    '.js-content',
                    'after'
                );

                // Listen to the need-scoring attribute, when it changes
                // add the student grade block
                this.listenToOnce(
                    this.response,
                    'change:need-scoring',
                    this.addStudentGrade
                );

            // If student grading is not needed, check if we need to show a previously
            // given student grade
            } else if (
                this.response.get('score') !== -1 &&
                this.response.get('scored_by') === 'student'
            ) {
                this.addStudentGrade();
            }

            // Check if this task has an explanation and is not shown
            // within the author interface and the task is not in preview mode.
            if (
                this.hasExplanation &&
                APPLICATION !== 'author' &&
                !this.isPreview
            ) {

                this.renderAnswerExplanationView()
            }

            if (ACL.checkRole(ACL.roles.STUDENT)) {

                // Show the score the student got for this task
                this.openGradeScore();

                // Fix for issue LB6032 where the "has seen model answer" event was send before
                // the response was synced. This caused the issue that the response was seen
                // as a correction. In order to let the response sync first and then send then
                // 'has seen model answer" flag, we delay the hasSeenModelAnswer method
                _.delay(this.response.hasSeenModelAnswer, 1000);
            }

            this.$('.js-show-answer').addClass('btn--active')

        }

        // Toggle flag indicating if answer is currently being shown.
        this.showingAnswer = !this.showingAnswer
    },

    /**
     * addStudentGrade
     *
     * This method will add a message to inform the user which score he has
     * given himself on a graded task
     *
     */
    addStudentGrade() {

        // If response is already graded, show chosen score
        this.addChildView(
            new StudentGrade({
                model: this.response
            }),

            // Use the js-content selector
            '.js-content',

            // Use the after method
            'after'
        );
    },

    saveResponse(json_answer) {

        if (!ACL.isAllowed(ACL.resources.RESPONSES, ACL.actions.ADD)) {
            return;
        }

        // If something has been changed by the student and the
        // student is not in the process of reviewing their answers
        // to an exam activity.
        if (!this.showExamAnswers &&
            !_.isEqual(this.response.get('json_answer'), json_answer)
        ) {

            if (ACL.checkRole(ACL.roles.STUDENT)) {
                this.response.save({
                    json_answer
                });
            } else {
                console.log('not saving: not a student');
            }

            // TODO remove need for this.
            // this trigger is used to fill source 11
            Backbone.Model.user.trigger('response_updated');

        } else {
            console.log('not saving: no change or in view exam mode', json_answer);
        }

        if (

            // If the activity is a presentation
            this.activityType === 'presentation' &&

            // And the template is not an open question
            this.templateView.model.get('template_id') !== 2
        ) {

            // Trigger event that tells the presentation view, the student has saved an answer
            this.work_on.trigger('student-saved-presentation-answer');
        }

    },

    responseSaveStateChanged(responseModel) {

        this.$('.js-response-saved-status').hide()

        switch (responseModel.get('saved_state')) {
            case 'server':
                this.$('.js-response-saved-status[data-status="saved"]').show()
                break;

            case 'local':
                this.$('.js-response-saved-status[data-status="syncing"]').show()
                break;

            default:
                this.$('.js-response-saved-status[data-status="unsaved"]').show()
                break;
        }

        if (this.showingAnswer) {
            _.defer(this.openGradeScore);
        }
    },

    /**
     * renderSendButton
     *
     * This function renders or rerenders a send button.
     * This can be used in presentation mode in templates where the user
     * needs to do more than one action, such as typing an answer.
     * Answer will only be saved (and send to teacher) when user clicks the send button.
     *
     * @param {boolean} renderSendButton render the "send" button if true. else the "change" button
     */
    renderPresentationButton(renderSendButton = false) {

        if (!this.presentationChangeButton) {
            this.presentationChangeButton = new Button({
                label: window.i18n.gettext('Edit'),
                inline: true,
                size: 'medium',
                iconRight: 'arrow-right',
                callback: () => this.onClickEditAnswer(),
                theme: 'outlined',
            })
        }

        if (!this.presentationSendButton) {
            this.presentationSendButton = new Button({
                label: window.i18n.gettext('Send'),
                inline: true,
                size: 'medium',
                iconRight: 'arrow-right',
                callback: () => this.onClickSendAnswer(),
            })
        }

        $('.js-send-presentation-answer').empty()

        this.sendButton = this.addChildView(
            renderSendButton ?
                this.presentationSendButton :
                this.presentationChangeButton,
            '.js-send-presentation-answer',
        )
    },

    /**
     * hideSendButton
     *
     * Hides send button used in presentations
     *
     */
    hideSendButton() {
        this.$('.js-send-presentation-answer').hide();
    },

    /**
     * showSendButton
     *
     * Shows send button used in presentations
     *
     */
    showSendButton() {
        this.$('.js-send-presentation-answer').css('display', 'flex');
    },

    /**
     * onClickSendAnswer
     *
     * When students clicks on the send button it will send the presentation answer.
     *
     */
    onClickSendAnswer() {
        // NOTE: All templates that use a send button should have a function named "sendPresentationAnswer"
        // which also triggers the save function in Task.js.
        this.templateView.sendPresentationAnswer();
        this.renderPresentationButton(false)
    },

    /**
     * onClickEditAnswer
     *
     * When user clicks on the edit button, it will unlock the answer
     * and transform the edit button to a send button.
     *
     */
    onClickEditAnswer() {

        this.templateView.unlockAnswer();
        this.renderPresentationButton(true)
    },

    /**
     * displayNotes - displays feedback given to this student on this task.

     */
    displayNotes() {

        var searchObject = {
            task_id: this.model.get('id')
        };

        // Check if the work on exists and that it contains a student model. If it contains a student
        // model it probably is the view used in the progress view and it should be single user
        // specific. Therefore if there is a student model we filter out only the notes for this
        // user using the users id.
        // TODO student_model should be set in Task options
        if (this.work_on && this.work_on.student_model) {
            // Add the student models id as recipient_user_id in the searchObject
            searchObject.recipient_user_id = this.work_on.student_model.get('id');
        }

        var noteModels = this.work_on.model.notes.where(searchObject);

        if (noteModels) {
            _.each(noteModels, function(noteModel) {

                this.addChildView(new NoteView({
                    model: noteModel,
                    taskView: this.task_view,
                    parentView: this
                }), '.js-notes');

            }, this);
        }
    },

    onClickNavigateToTask() {
        Backbone.history.navigate(
            '/activities/show/' + this.work_on.model.id + '/' + this.model.get('task_group_id') + '#' + this.model.id, {
                trigger: true
            }
        );
    },

    // For debugging answers, log it when a user clicks on a task
    onClickTaskContent() {
        window.sentry.withScope(scope => {
            scope.setExtra('task id', this.model.get('id'))
            scope.setExtra('template id', this.model.get('template_id'))
            window.sentry.captureMessage('exta logging: student clicked on task')
        })
    },

    // For debugging answers, log it when a user types in a task
    onTypeTaskContent() {
        window.sentry.withScope(scope => {
            scope.setExtra('task id', this.model.get('id'))
            scope.setExtra('template id', this.model.get('template_id'))
            window.sentry.captureMessage('exta logging: student typed in task')
        })
    },

    /**
     * in Student Grading mode, the template view of the task is not rendered,
     * only the model answer. Get the HTML of the model answer, and render
     * the model answer outside of the template view
     */
    renderAnswerForStudentGrading() {
        // override some global legacy styling
        this.el.querySelector('article').dataset.isStudentGrading = ''

        const answerContainer = document.querySelector('.js-answer-container')

        // add model answer html to the Student Grading View
        answerContainer?.insertAdjacentElement(
            'afterbegin',
            this.templateView.getModelAnswerView(this.model.get('task_info_json').answer, true).el
        )

        // TODO: delete this if sentry messages for "Student Grading not rendered for Task view" disappear
        if (!answerContainer) {
            window.sentry.withScope(scope => {
                scope.setTag('studentGradingSessionId', this.studentGradingSessionId)
                window.sentry.captureMessage('Student Grading not rendered for Task view')
            })
        }

    },

    renderAnswerExplanationView() {

        this.answerExplanationView = this.addChildView(
            new AnswerExplanationView({
                explanation: this.model.get('explanation'),
                title: window.i18n.gettext('Explanation of the answer'),
            }),

            '.js-answer-explanation'
        )

    },

    openAnalytics() {
        if (this.taskAnalytics) {
            this.taskAnalytics.$el.toggle()
        } else {
            this.taskAnalytics = this.addChildView(new TaskAnalytics({
                taskModel: this.model
            }), '.js-task-analytics')
        }
        this.el.querySelector('.js-open-analytics').classList.toggle(
            'btn--active',
            this.taskAnalytics.el.style.display !== 'none'
        )
    },

    /**
     * When student is graded by fellow student, append that grading underneath the task
     *
     * @param {string|Backbone.View} view html or view of grading
     */
    appendCollaborativeGrading(view) {
        if (typeof view === 'string') {
            this.el.querySelector('article').insertAdjacentHTML('beforeend', view)
        }
    }

});
